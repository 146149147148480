import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { verifyOtpUser } from "../components/Actions/verifyOtpActions";

import { signupActions } from "../components/Redux Slices/signupSlice";
import { forgotPassword } from "../components/Actions/ForgotPassword";
import { Image } from "antd";
import Edit from "../assets/Edit.svg";
import { useTranslation } from "react-i18next";
import { verifyOTP } from "../components/Actions/VerifyOTP";
import { Result } from "antd/lib";
import { SendOTP } from "../components/Actions/SendOTP";

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0)",
    zIndex: 999,
  },
  container: {
    width: "550px",
    padding: "20px",
    borderRadius: "30px",
    margin: "0 auto",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
  title: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "4px",
    marginTop: "10px",
  },
  description: {
    textAlign: "center",
    fontSize: "1rem",
    color: "#5C5C5C",
    marginBottom: "20px",
  },
  otpInput: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
    marginTop: "40px",
  },
  otpInputField: {
    width: "40px",
    height: "40px",
    fontSize: "1.5rem",
    border: "1px solid #ccc",
    borderRadius: "7px",
    textAlign: "center",
    margin: "0 5px",
    outline: "none",
    color: "black",
  },
  resendText: {
    textAlign: "center",
    color: "#5C5C5C",
    fontSize: "0.875rem",
    marginBottom: "20px",
  },
  resendButton: {
    fontWeight: "bold",
    color: "#1b4597",

    cursor: "pointer",
  },
  verifyButton: {
    width: "60%",
    padding: "10px",
    backgroundColor: "#1b4497",
    color: "white",
    borderRadius: "8px",
    cursor: "pointer",
    margin: "0 auto",
    display: "block",
    marginTop: "50px",
    marginBottom: "20px",
    fontWeight: "600",
  },
};

const ForgotOTPModal = ({
  isOpen,
  onClose,
  phone,
  onDataSubmit,
  numberChange,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(30);
  const [otpValue, setOtpValue] = useState("");
  const [showIncorrectOTPMessage, setShowIncorrectOTPMessage] = useState(false);

  const handleResendOtp = async () => {
    const payload = {
      mobile: phone,
      country_code: "+20",
      // otp: "5409",
      // user_id: 8,
      // password: "abc123",
      // old_password: "abc123",
      // page: "send_otp",
    };

    try {
      const response = await dispatch(SendOTP(payload));
      if (response) {
        setTimer(30);
        setOtpValue("");
      }
    } catch (error) {
      console.error("API request error:", error);
    }
  };

  useEffect(() => {
    let timerId;

    if (timer > 0) {
      timerId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [timer]);

  useEffect(() => {
    let timerId;

    if (timer > 0) {
      timerId = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [timer]);

  const formik = useFormik({
    initialValues: { mobile: phone, otp: "" },
    onSubmit: async (values) => {
      const payload = {
        mobile: values.mobile,
        country_code: "+20",
        otp: otpValue,
        // user_id: 8,
        // password: "abc123",
        // old_password: "abc123",
        // page: "verify_otp", //send_otp, verify_otp, password
      };
      const response = dispatch(verifyOTP(payload));
      response.then((result) => {
        console.log("VERIFY OTP RES--->", result);
        
        if (result.payload?.result?.status === "OTP Verify successfully") {
          onDataSubmit();
        } else {
          setShowIncorrectOTPMessage(true);
        }
      });
    },
  });

  // const handleAcceptTerms = () => {
  //   onClose();
  //   // router.push("/register");
  // };

  return (
    <>
      {isOpen && (
        <>
          <div style={styles.overlay} onClick={onClose}></div>
          <div style={styles.container}>
            <div>
              <form onSubmit={formik.handleSubmit}>
                <p className="text-black" style={styles.title}>{t("verify_phone_number")}</p>
                <div className="flex flex-row justify-center">
                  <p style={styles.description}>
                    {t("otp_sent_msg")} 0{phone}
                  </p>
                  <img
                    src={Edit}
                    style={{
                      cursor: "pointer",
                      // transform: "rotateY(180deg)",
                      marginTop: ".15rem",
                      height: "0.9rem",
                      width: "0.9rem",
                    }}
                    alt="Edit"
                    className="ml-2 mt--2 "
                    onClick={() => {
                      numberChange();
                    }}
                  />{" "}
                </div>

                <div style={styles.otpInput}>
                  <OtpInput
                    value={otpValue}
                    onChange={(otp) => {
                      setOtpValue(otp.replace(/\D/g, ""));
                      setShowIncorrectOTPMessage(false);
                    }}
                    numInputs={4}
                    placeholder="    "
                    separator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                    isInputSecure={true}
                    inputStyle={{
                      ...styles.otpInputField,
                      color: "black",
                      fontSize: "medium",
                    }}
                  />
                </div>
                {showIncorrectOTPMessage && (
                  <p className="flex font-md justify-center text-red">
                    {t("incorrectotp")}
                  </p>
                )}
                <div>
                  <p style={styles.resendText}>
                    {t("not_received")}{" "}
                    {timer > 0 ? (
                      <span className="text-[#1b4597] font-bold">
                        {timer} {t("seconds")}
                      </span>
                    ) : (
                      <span
                        style={styles.resendButton}
                        onClick={handleResendOtp}
                      >
                        {t("resend")}
                      </span>
                    )}
                  </p>
                </div>
                <button
                  type="submit"
                  style={styles.verifyButton}
                  onClick={formik.handleSubmit}
                  disabled={otpValue?.length < 4 ? true : false}
                >
                  {t("verify")}
                </button>
              </form>
            </div>
            {/* {showTermsModal && (
              <TermsModal isOpen={showTermsModal} onClose={handleAcceptTerms} />
            )} */}
          </div>
        </>
      )}
    </>
  );
};

export default ForgotOTPModal;
