import React, { useState, useEffect } from "react";
import {Breadcrumb, Image}  from "antd";
import one from "../../assets/Inventory/Stockout.svg";
import two from "../../assets/Inventory/Low_on_Inventory.svg";
import three from "../../assets/Inventory/High_on_Inventory.svg";
import { getInventoryallproducts, getInventorydashboard } from "../../API  Functions/InventoryFunctions";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { dashDataReducer } from "../../Redux/Reducers";
import { setDashData } from "../../Redux/Action/DashData";
import { routes } from "../../Routes";

const InventoryBar = ({ setPrevData, onInventoryBoxClick}) => {
  const dispatch = useDispatch()
  const [kpiData, setkpiData] = useState([]);
  const {t,i18n} =useTranslation();
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);
  const company_id = useSelector((state) => state?.login?.user?.result?.company_id);

  const getInventoryKPI = () => {
    getInventorydashboard(user_id,company_id).then((res) => {
      setkpiData(res?.result[0]?.response);
      setPrevData(res?.result[0]?.response)
      dispatch(setDashData(res?.result[0]?.response));
      // console.log("INVENTORY DASHBOARD DATA", res?.result[0]?.response);
      
    });
  };

  useEffect(() => {
    if (company_id) getInventoryKPI();
  }, [company_id]);

  const values = {
    1: {
      img: one,
      heading: t('stockout'),
      productsleft: `${kpiData?.stock_out??0} ${t('products')}`,
      bgcolor: "#fbdada",
      textcolor: "#EB3C3C",
      // link:"/inventory/Outofstock"
    },
    2: {
      img: two,
      heading: t('low_on_inventory'),
      productsleft: `${kpiData?.low_stock??0} ${t('products')}`,
      bgcolor: "#ffe5cd",
      textcolor: "#EB853C",
      // link:"/inventory/Lowoninventory"
    },
    3: {
      img: three,
      heading: t('high_on_inventory'),
      productsleft: `${kpiData?.high_stock??0} ${t('products')}`,
      bgcolor: "rgb(209,241,252)",
      textcolor: "#059003",
      // link:"/inventory/Highoninventory"
    },

  };

  return (
    <>
  
    <div className="flex flex-wrap mt-20">
      {Object.values(values).map((val, index) => (
        
        <div
          key={index}
          className="w-1/3 sm:w-1/3 md:w-1/3 xl:w-1/3 px-3 mb-2 cursor-pointer"
          onClick={() => onInventoryBoxClick(val.heading)}
        >
          <div
            style={{ backgroundColor: val.bgcolor }}
            className="w-full rounded-lg flex items-center p-2 shadow-sm relative"
          >
            <Link to={val.link} className="w-full rounded-lg flex items-center  relative">
            <div className="me-4">
              <img src={val.img} alt={val.heading} width={48} height={48}  />
            </div>
            <div>
              <p className="font-normal text-md">{val.heading}</p>
              <p
                style={{ color: val.textcolor }}
                className="font-semibold text-xl"
              >
                {val.productsleft}
              </p>
            </div>
            </Link>
          </div>
        </div>
        
      ))}
    </div>
    </>
  );
};

export default InventoryBar;
