export const getdatahtml = (data, is_lahloob, t, i18n, userData) => {
  console.log("ORDER DATA---->", data);

  const isRTL = i18n.language === "ar";
  let htmlProductLines = "";
  let totalAmount = 0;
  const products =
    data?.existing_items?.length > 0 ? data?.existing_items : data?.new_items;

  // Calculate total value
  let totalValue =
    products
      .filter((item) => item?.pick_qty > 0)
      .reduce(
        (totalQty, item) => totalQty + item?.price_unit * item?.pick_qty,
        0
      ) +
    (data?.order_details?.shipping_charges || 0) +
    (data?.order_details?.mgi_platform_fee || 0) +
    (data?.order_details?.mgi_surcharge || 0) +
    (data?.order_details?.mgi_handling_fee || 0) -
    (data?.order_details?.mgi_discount || 0) +
    (data?.order_details?.offer ? data?.order_details?.offervalue : 0) -
    (data?.order_details?.cashback_used || 0) -
    (data?.order_details?.coupon && data?.order_details?.coupon !== ""
      ? data?.order_details?.coupon_value
      : 0);

  // Generate product lines
  if (data?.existing_items) {
    let serialNumber = 1;
    products.forEach((item, index) => {
      const formattedQuantity = item?.pick_qty;

      if (parseFloat(formattedQuantity) !== 0) {
        const itemTotal = item?.price_unit * item?.pick_qty;
        totalAmount += itemTotal;
        htmlProductLines += `
          <tr key=${index}>
            ${
              isRTL
                ? `
              <td style="text-align: center;">${itemTotal.toFixed(2)}</td>
              <td style="text-align: center;">${item?.price_unit}</td>
              <td style="text-align: center;">${formattedQuantity}</td>
              <td style="text-align: right;">${item?.product_name}</td>
              <td style="text-align: center; vertical-align: text-top;">${serialNumber}</td>
            `
                : `
              <td style="text-align: center; vertical-align: text-top;">${serialNumber}</td>
              <td style="text-align: left;">${item?.product_name}</td>
              <td style="text-align: center;">${formattedQuantity}</td>
              <td style="text-align: center;">${item?.price_unit}</td>
              <td style="text-align: end;">${itemTotal.toFixed(2)}</td>
            `
            }
          </tr>
          ${
            item?.product_barcode
              ? `
            <tr>
              <td colspan="5" style="text-align: center; padding-top: 10px;">
                <img src="https://barcode.tec-it.com/barcode.ashx?data=${item?.product_barcode}" alt="Receipt Barcode" width="70%" height="10" style="margin-top: 10px; height:100px;" />
              </td>
            </tr>
          `
              : ""
          }
        `;
        serialNumber++;
      }
    });
  }

  // Additional fee tabs
  const deliveryChargeTab = `
    <tr>
      ${
        isRTL
          ? `
        <td style="text-align: center;">${data?.order_details?.shipping_charges?.toFixed(
          2
        )}</td>
        <td style="text-align: center;"></td>
        <td style="text-align: center;"></td>
        <td style="text-align: right;">${t("Delivery Fee")}</td>
        <td style="text-align: center; vertical-align: text-top;"></td>
      `
          : `
        <td style="text-align: center; vertical-align: text-top;"></td>
        <td style="text-align: left;">${t("Delivery Fee")}</td>
        <td style="text-align: end;"></td>
        <td style="text-align: end;"></td>
        <td style="text-align: end;">${data?.order_details?.shipping_charges?.toFixed(
          2
        )}</td>
      `
      }
    </tr>
  `;

  let offerTab = `
    <tr>
      ${
        isRTL
          ? `
        <td style="text-align: end;">${data?.order_details?.offervalue?.toFixed(
          2
        )}</td>
        <td style="text-align: center;"></td>
        <td style="text-align: center;"></td>
        <td style="text-align: right;">${t("Ramadan Offer")}</td>
        <td style="text-align: center; vertical-align: text-top;"></td>
      `
          : `
        <td style="text-align: center; vertical-align: text-top;"></td>
        <td style="text-align: left;">${t("Ramadan Offer")}</td>
        <td style="text-align: end;"></td>
        <td style="text-align: end;"></td>
        <td style="text-align: end;">${data?.order_details?.offervalue?.toFixed(
          2
        )}</td>
      `
      }
    </tr>
    `;

  const mgi_platform_feeTab = `
    <tr>
      ${
        isRTL
          ? `
        <td style="text-align: center;">${data?.order_details?.mgi_platform_fee?.toFixed(
          2
        )}</td>
        <td style="text-align: center;"></td>
        <td style="text-align: center;"></td>
        <td style="text-align: right;">${t("Platform Fee")}</td>
        <td style="text-align: center; vertical-align: text-top;"></td>
      `
          : `
        <td style="text-align: center; vertical-align: text-top;"></td>
        <td style="text-align: left;">${t("Platform Fee")}</td>
        <td style="text-align: end;"></td>
        <td style="text-align: end;"></td>
        <td style="text-align: end;">${data?.order_details?.mgi_platform_fee?.toFixed(
          2
        )}</td>
      `
      }
    </tr>
  `;

  const mgi_surchargeTab = `
    <tr>
      ${
        isRTL
          ? `
        <td style="text-align: center;">${data?.order_details?.mgi_surcharge?.toFixed(
          2
        )}</td>
        <td style="text-align: center;"></td>
        <td style="text-align: center;"></td>
        <td style="text-align: right;">${t("Surcharge")}</td>
        <td style="text-align: center; vertical-align: text-top;"></td>
      `
          : `
        <td style="text-align: center; vertical-align: text-top;"></td>
        <td style="text-align: left;">${t("Surcharge")}</td>
        <td style="text-align: end;"></td>
        <td style="text-align: end;"></td>
        <td style="text-align: end;">${data?.order_details?.mgi_surcharge?.toFixed(
          2
        )}</td>
      `
      }
    </tr>
  `;

  const mgi_handling_feeTab = `
    <tr>
      ${
        isRTL
          ? `
        <td style="text-align: center;">${data?.order_details?.mgi_handling_fee?.toFixed(
          2
        )}</td>
        <td style="text-align: center;"></td>
        <td style="text-align: center;"></td>
        <td style="text-align: right;">${t("Handling Fee")}</td>
        <td style="text-align: center; vertical-align: text-top;"></td>
      `
          : `
        <td style="text-align: center; vertical-align: text-top;"></td>
        <td style="text-align: left;">${t("Handling Fee")}</td>
        <td style="text-align: end;"></td>
        <td style="text-align: end;"></td>
        <td style="text-align: end;">${data?.order_details?.mgi_handling_fee?.toFixed(
          2
        )}</td>
      `
      }
    </tr>
  `;

  const mgi_Discount = `
    <tr>
      ${
        isRTL
          ? `
        <td style="text-align: center;">${data?.order_details?.mgi_discount?.toFixed(
          2
        )}</td>
        <td style="text-align: center;"></td>
        <td style="text-align: center;"></td>
        <td style="text-align: right;">${t("Instant Discount")}</td>
        <td style="text-align: center; vertical-align: text-top;"></td>
      `
          : `
        <td style="text-align: center; vertical-align: text-top;"></td>
        <td style="text-align: left;">${t("Instant Discount")}</td>
        <td style="text-align: end;"></td>
        <td style="text-align: end;"></td>
        <td style="text-align: end;">${data?.order_details?.mgi_discount?.toFixed(
          2
        )}</td>
      `
      }
    </tr>
  `;

  const cashbackTab = `
    <tr>
      ${
        isRTL
          ? `
        <td style="text-align: center;">-${data?.order_details?.cashback_used?.toFixed(
          2
        )}</td>
        <td style="text-align: center;"></td>
        <td style="text-align: center;"></td>
        <td style="text-align: right;">${t("Cashback Used")}</td>
        <td style="text-align: center; vertical-align: text-top;"></td>
      `
          : `
        <td style="text-align: center; vertical-align: text-top;"></td>
        <td style="text-align: left;">${t("Cashback Used")}</td>
        <td style="text-align: end;"></td>
        <td style="text-align: end;"></td>
        <td style="text-align: end;">-${data?.order_details?.cashback_used?.toFixed(
          2
        )}</td>
      `
      }
    </tr>
  `;

  const couponTab = `
    <tr>
      ${
        isRTL
          ? `
        <td style="text-align: center;">-${data?.order_details?.coupon_value?.toFixed(
          2
        )}</td>
        <td style="text-align: center;"></td>
        <td style="text-align: center;"></td>
        <td style="text-align: right;">${t("Coupon Applied")}</td>
        <td style="text-align: center; vertical-align: text-top;"></td>
      `
          : `
        <td style="text-align: center; vertical-align: text-top;"></td>
        <td style="text-align: left;">${t("Coupon Applied")}</td>
        <td style="text-align: end;"></td>
        <td style="text-align: end;"></td>
        <td style="text-align: end;">-${data?.order_details?.coupon_value?.toFixed(
          2
        )}</td>
      `
      }
    </tr>
  `;

  const htmlContent = `
<!DOCTYPE html>
<html>
  <head>
    <style>
      body {
        font-family: Arial, sans-serif;
        width: 100%;
        color: #000000;
        font-size: 10px;
      }
      .pos-receipt {
        text-align: center;
        width: 100mm; /* Set a fixed width for the receipt */
        margin: 0 auto; /* Center the receipt */
      }
      .pos-receipt-contact div {
        margin-top: 5px;
        font-weight: semi-bold;
      }
      .pos-receipt-contact .cashier {
        border-top: 1px dashed black;
      }
      .pos-receipt-contact table {
        width: 100%;
        margin-bottom: 5px;
      }
      .pos-receipt-contact table td:first-child {
        width: 100%;
      }
      .receipt-orderdata {
        width: 100%;
        font-size: 10px;
        margin-bottom: 5px;
        margin-top: 5px;
      }
      .receipt-orderdata th,
      .receipt-orderdata td {
        padding: 0px;
      }
      .receipt-orderdata td {
        font-size: 10px;
      }
      .receipt-orderdata td {
        text-align: center;
      }
      .pos-receipt-right-align {
        float: right;
      }
      .pos-receipt .pos-receipt-right-align {
        font-size: 10px;
      }
      .orderDetail {
        display: flex;
        flex-direction: ${i18n.language === "ar" ? "row-reverse" : "row"};
        justify-content: space-between;
      }
      .orderDetail div, .cashierDetail {
        font-size: 10px;
        margin-top: 5px;
      }
      .total {
        border-top: 1px dashed black;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 10px;
        margin-top: 10px;
      }
      .total div, .cashierDetail {
        font-size: 9px;
      }
      .lines {
        margin-top: 5px;
        margin-bottom: 5px;
      }
      .order-delivery {
        margin: 10px 0px;
      }
      .receiptHead {
        text-align: center;
        font-weight: semi-bold;
        font-size: 14px;
        margin: 10px 0px;
        width: 100%;
      }
      .pos-receipt-bottomSection {
        border-top: 1px dashed black;
        margin-top: 10px;
        font-size: 14px;
      }
      .bottomSection img {
        width: 100px;
      }
      .after-footer {
        margin-top: 0px;
      }
    </style>
  </head>
  <body>
    <div class="pos-receipt-container">
      <div class="pos-receipt">
      
        <div style="text-align: center;">
    ${
      is_lahloob
        ? `<div style="display: inline-block; border-radius: 15px; margin-bottom: -20px;">
            <img src="https://assets.myginne.com/assets/store_app_UAT/lahloob-icon3.jpeg" alt="My SVG Image" width="150" style="border-radius: 15px;">
        </div>`
        : `<img style="max-height: 50px; max-width: 40px;" src="${data?.order_details?.retailer_image_url}" alt="Retailer Logo" width="100">`
    }
</div>
${
  !is_lahloob
    ? `<div class="pos-receipt-contact">
        <div class="lines"><strong>${data?.order_details?.retailer_name}</strong></div>
        <div class="lines"><strong> +20 ${data?.order_details?.retailer_phone}</strong></div>
        <div class="lines"><strong> ${userData?.email}</strong></div>
      </div>`
    : ""
}
        <div class="receiptHead">
          <strong>${"Order Receipt"}</strong>
        </div>
        <div style="text-align: ${
          i18n.language === "ar" ? "row-reverse" : "row"
        }; border-top: 1px dashed black; border-bottom: 1px dashed black; padding: 5px 0px;">
          <div class="orderDetail">
            <div class="lines"><strong>${"Order No:"}</strong></div>
            <div class="lines">${data?.order_details?.order_no}</div>
          </div>
          <div class="orderDetail">
            <div class="lines"><strong>${"Order Date:"}</strong></div>
            <div class="lines">${data?.order_details?.order_date}</div>
          </div>
          <div class="orderDetail">
            <div class="lines"><strong>${"Customer Name:"}</strong></div>
            <div class="lines">${data?.order_details?.partner_name}</div>
          </div>
          <div class="orderDetail">
            <div class="lines"><strong>${"Customer Phone:"}</strong></div>
            <div class="lines">${data?.order_details?.partner_mobile}</div>
          </div>
           <div class="orderDetail">
            <div class="lines"><strong>${"Customer Address:"}</strong></div>
            <div class="lines" style="text-Align: ${
              isRTL ? "left" : "right"
            }; width:50%;">
            ${
              (data?.deliver_add?.address_type &&
                data?.deliver_add?.address_type?.includes("Villa")) ||
              (data?.deliver_add?.address_type &&
                data?.deliver_add?.address_type?.includes("Home"))
                ? data?.deliver_add?.house_group &&
                  `${t("Villa No.")} ${data?.deliver_add?.house_group}, `
                : data?.deliver_add?.house_group &&
                  `${t("Group No.")} ${data?.deliver_add?.house_group}, `
            }
                ${
                  data?.deliver_add?.office_name &&
                  `${t("Office Name")} ${data?.deliver_add?.office_name}, `
                }
                ${
                  data?.deliver_add?.building_group &&
                  `${t("Building")} ${data?.deliver_add?.building_group}, `
                }
                ${
                  (data?.deliver_add?.address_type &&
                    data?.deliver_add?.address_type?.includes("Villa")) ||
                  (data?.deliver_add?.address_type &&
                    data?.deliver_add?.address_type?.includes("Home"))
                    ? data?.deliver_add?.apartment_no &&
                      `${t("Group No.")} ${data?.deliver_add?.apartment_no}, `
                    : data?.deliver_add?.apartment_no &&
                      `${t("Apartment No.")} ${
                        data?.deliver_add?.apartment_no
                      }, `
                }
                ${
                  data?.deliver_add?.floor &&
                  `${t("Floor")} ${data?.deliver_add?.floor}, `
                }
                ${data?.deliver_add?.street && `${data?.deliver_add?.street}, `}
                ${
                  data?.deliver_add?.street2 &&
                  `${data?.deliver_add?.street2}, `
                }
                ${
                  data?.deliver_add?.locality &&
                  `${data?.deliver_add?.locality}, `
                }
                ${data?.deliver_add?.area && `${data?.deliver_add?.area}, `}
                ${data?.deliver_add?.city},${" "}
                ${data?.deliver_add?.country} ${data?.deliver_add?.zip}
            </div>
          </div>
        </div>
        <div>
          <table class="receipt-orderdata">
            <colgroup>
              <col width="10%">
              <col width="40%">
              <col width="10%">
              <col width="20%">
              <col width="10%">
            </colgroup>
            <tr>
              <th>${"S.N"}.</th>
              <td style="text-align: center; font-weight: semi-bold; margin-top: 5px"><strong>${"Product Name"}</strong></td>
              <td style="text-align: center; font-weight: semi-bold; margin-top: 5px"><strong>${"Qty"}</strong></td>
              <td style="text-align: center; font-weight: semi-bold; margin-top: 5px"><strong>${"Unit Price"}</strong></td>
              <td style="text-align: end; font-weight: semi-bold; margin-top: 5px"><strong>${"Amount"}</strong></td>
            </tr>
            ${htmlProductLines}
            ${deliveryChargeTab}
            ${
              data?.order_details?.mgi_platform_fee != undefined &&
              data?.order_details?.mgi_platform_fee != 0
                ? mgi_platform_feeTab
                : ""
            }
            ${
              data?.order_details?.mgi_surcharge != undefined &&
              data?.order_details?.mgi_surcharge != 0
                ? mgi_surchargeTab
                : ""
            }
            ${
              data?.order_details?.mgi_handling_fee != undefined &&
              data?.order_details?.mgi_handling_fee != 0
                ? mgi_handling_feeTab
                : ""
            }
            ${
              data?.order_details?.mgi_discount != undefined &&
              data?.order_details?.mgi_discount != 0
                ? mgi_Discount
                : ""
            }
            ${data?.order_details?.offer ? offerTab : ""}
            ${
              data?.order_details?.cashback_used &&
              data?.order_details?.cashback_used > 0
                ? cashbackTab
                : ""
            }
            ${
              data?.order_details?.coupon && data?.order_details?.coupon !== ""
                ? couponTab
                : ""
            }
          </table>
        </div>
        <div class="total">
          <div><strong>${"TOTAL"}</strong></div>
          <div><strong>EGP ${totalValue.toFixed(2)}</strong></div>
        </div>
        <div class="pos-receipt-bottomSection">
        ${
          data?.order_details?.order_no
            ? `
          <tr>
            <td colspan="5" style="text-align: center; padding-top: 10px;">
              <img src="https://barcode.tec-it.com/barcode.ashx?data=${data?.order_details?.order_no}" alt="Receipt Barcode" width="70%" height="10" style="margin-top: 10px; height:50px;" />
            </td>
          </tr>
        `
            : ""
        }
          <br>
          
          ${!is_lahloob ? `
            ${"Your Technology Partner"}<br>
            <img style="max-height: 50px; max-width: 100px;" src="https://images.myginne.com/myginne.webp" alt="myGinne Logo">
        ` : `
            ${"Thanks"}<br>
           <div><strong> ${data?.order_details?.retailer_name}</strong></div>
        `}
        
        </div>
      </div>
    </div>
  </body>
</html>
  `;
  return htmlContent;
};

// export const getdatahtml = (data, is_lahloob, t, i18n) => {
//   console.log("ORDER DATA---->", data?.new_items?.length);

//   const isRTL = i18n.language === "ar";
//   let htmlProductLines = "";
//   let totalAmount = 0;
//   const products = data?.existing_items?.length > 0 ? data?.existing_items : data?.new_items

//   // console.log("product---->", products);
//   // Calculate total value
//   let totalValue =
//     products
//       .filter((item) => item?.pick_qty > 0)
//       .reduce(
//         (totalQty, item) => totalQty + item?.price_unit * item?.pick_qty,
//         0
//       ) +
//     (data?.order_details?.shipping_charges || 0) +
//     (data?.order_details?.mgi_platform_fee || 0) +
//     (data?.order_details?.mgi_surcharge || 0) +
//     (data?.order_details?.mgi_handling_fee || 0) -
//     (data?.order_details?.mgi_discount || 0) +
//     (data?.order_details?.offer ? data?.order_details?.offervalue : 0) -
//     (data?.order_details?.cashback_used || 0) -
//     (data?.order_details?.coupon && data?.order_details?.coupon !== '' ? data?.order_details?.coupon_value : 0 );

//   // Generate product lines
//   if (data?.existing_items) {
//     let serialNumber = 1;
//     products.forEach((item, index) => {
//       const formattedQuantity = item?.pick_qty;

//       if (parseFloat(formattedQuantity) !== 0) {
//         const itemTotal = item?.price_unit * item?.pick_qty;
//         totalAmount += itemTotal;
//         htmlProductLines += `
//           <tr key=${index}>
//             ${
//               isRTL
//                 ? `
//               <td style="text-align: center;">${itemTotal.toFixed(2)}</td>
//               <td style="text-align: center;">${item?.price_unit}</td>
//               <td style="text-align: center;">${formattedQuantity}</td>
//               <td style="text-align: right;">${item?.product_name}</td>
//               <td style="text-align: center; vertical-align: text-top;">${serialNumber}</td>
//             `
//                 : `
//               <td style="text-align: center; vertical-align: text-top;">${serialNumber}</td>
//               <td style="text-align: left;">${item?.product_name}</td>
//               <td style="text-align: center;">${formattedQuantity}</td>
//               <td style="text-align: center;">${item?.price_unit}</td>
//               <td style="text-align: end;">${itemTotal.toFixed(2)}</td>
//             `
//             }
//           </tr>
//           ${
//             item?.product_barcode
//               ? `
//             <tr>
//               <td colspan="5" style="text-align: center; padding-top: 10px;">
//                 <img src="https://barcode.tec-it.com/barcode.ashx?data=${item?.product_barcode}" alt="Receipt Barcode" width="70%" height="10" style="margin-top: 10px; height:100px;" />
//               </td>
//             </tr>
//           `
//               : ""
//           }
//         `;
//         serialNumber++;
//       }
//     });
//   }

//   // Additional fee tabs
//   const deliveryChargeTab = `
//     <tr>
//       ${
//         isRTL
//           ? `
//         <td style="text-align: center;">${data?.order_details?.shipping_charges?.toFixed(
//           2
//         )}</td>
//         <td style="text-align: center;"></td>
//         <td style="text-align: center;"></td>
//         <td style="text-align: right;">${t("Delivery Fee")}</td>
//         <td style="text-align: center; vertical-align: text-top;"></td>
//       `
//           : `
//         <td style="text-align: center; vertical-align: text-top;"></td>
//         <td style="text-align: left;">${t("Delivery Fee")}</td>
//         <td style="text-align: end;"></td>
//         <td style="text-align: end;"></td>
//         <td style="text-align: end;">${data?.order_details?.shipping_charges?.toFixed(
//           2
//         )}</td>
//       `
//       }
//     </tr>
//   `;

//   let offerTab = `
//     <tr>
//       ${
//         isRTL
//           ? `
//         <td style="text-align: end;">${data?.order_details?.offervalue?.toFixed(
//           2
//         )}</td>
//         <td style="text-align: center;"></td>
//         <td style="text-align: center;"></td>
//         <td style="text-align: right;">${t("Ramadan Offer")}</td>
//         <td style="text-align: center; vertical-align: text-top;"></td>
//       `
//           : `
//         <td style="text-align: center; vertical-align: text-top;"></td>
//         <td style="text-align: left;">${t("Ramadan Offer")}</td>
//         <td style="text-align: end;"></td>
//         <td style="text-align: end;"></td>
//         <td style="text-align: end;">${data?.order_details?.offervalue?.toFixed(
//           2
//         )}</td>
//       `
//       }
//     </tr>
//     `;

//   const mgi_platform_feeTab = `
//     <tr>
//       ${
//         isRTL
//           ? `
//         <td style="text-align: center;">${data?.order_details?.mgi_platform_fee?.toFixed(
//           2
//         )}</td>
//         <td style="text-align: center;"></td>
//         <td style="text-align: center;"></td>
//         <td style="text-align: right;">${t("Platform Fee")}</td>
//         <td style="text-align: center; vertical-align: text-top;"></td>
//       `
//           : `
//         <td style="text-align: center; vertical-align: text-top;"></td>
//         <td style="text-align: left;">${t("Platform Fee")}</td>
//         <td style="text-align: end;"></td>
//         <td style="text-align: end;"></td>
//         <td style="text-align: end;">${data?.order_details?.mgi_platform_fee?.toFixed(
//           2
//         )}</td>
//       `
//       }
//     </tr>
//   `;

//   const mgi_surchargeTab = `
//     <tr>
//       ${
//         isRTL
//           ? `
//         <td style="text-align: center;">${data?.order_details?.mgi_surcharge?.toFixed(
//           2
//         )}</td>
//         <td style="text-align: center;"></td>
//         <td style="text-align: center;"></td>
//         <td style="text-align: right;">${t("Surcharge")}</td>
//         <td style="text-align: center; vertical-align: text-top;"></td>
//       `
//           : `
//         <td style="text-align: center; vertical-align: text-top;"></td>
//         <td style="text-align: left;">${t("Surcharge")}</td>
//         <td style="text-align: end;"></td>
//         <td style="text-align: end;"></td>
//         <td style="text-align: end;">${data?.order_details?.mgi_surcharge?.toFixed(
//           2
//         )}</td>
//       `
//       }
//     </tr>
//   `;

//   const mgi_handling_feeTab = `
//     <tr>
//       ${
//         isRTL
//           ? `
//         <td style="text-align: center;">${data?.order_details?.mgi_handling_fee?.toFixed(
//           2
//         )}</td>
//         <td style="text-align: center;"></td>
//         <td style="text-align: center;"></td>
//         <td style="text-align: right;">${t("Handling Fee")}</td>
//         <td style="text-align: center; vertical-align: text-top;"></td>
//       `
//           : `
//         <td style="text-align: center; vertical-align: text-top;"></td>
//         <td style="text-align: left;">${t("Handling Fee")}</td>
//         <td style="text-align: end;"></td>
//         <td style="text-align: end;"></td>
//         <td style="text-align: end;">${data?.order_details?.mgi_handling_fee?.toFixed(
//           2
//         )}</td>
//       `
//       }
//     </tr>
//   `;

//   const mgi_Discount = `
//     <tr>
//       ${
//         isRTL
//           ? `
//         <td style="text-align: center;">${data?.order_details?.mgi_discount?.toFixed(
//           2
//         )}</td>
//         <td style="text-align: center;"></td>
//         <td style="text-align: center;"></td>
//         <td style="text-align: right;">${t("Instant Discount")}</td>
//         <td style="text-align: center; vertical-align: text-top;"></td>
//       `
//           : `
//         <td style="text-align: center; vertical-align: text-top;"></td>
//         <td style="text-align: left;">${t("Instant Discount")}</td>
//         <td style="text-align: end;"></td>
//         <td style="text-align: end;"></td>
//         <td style="text-align: end;">${data?.order_details?.mgi_discount?.toFixed(
//           2
//         )}</td>
//       `
//       }
//     </tr>
//   `;

//   const cashbackTab = `
//     <tr>
//       ${
//         isRTL
//           ? `
//         <td style="text-align: center;">-${data?.order_details?.cashback_used?.toFixed(
//           2
//         )}</td>
//         <td style="text-align: center;"></td>
//         <td style="text-align: center;"></td>
//         <td style="text-align: right;">${t("Cashback Used")}</td>
//         <td style="text-align: center; vertical-align: text-top;"></td>
//       `
//           : `
//         <td style="text-align: center; vertical-align: text-top;"></td>
//         <td style="text-align: left;">${t("Cashback Used")}</td>
//         <td style="text-align: end;"></td>
//         <td style="text-align: end;"></td>
//         <td style="text-align: end;">-${data?.order_details?.cashback_used?.toFixed(
//           2
//         )}</td>
//       `
//       }
//     </tr>
//   `;

//   const couponTab = `
//     <tr>
//       ${
//         isRTL
//           ? `
//         <td style="text-align: center;">-${data?.order_details?.coupon_value?.toFixed(2)}</td>
//         <td style="text-align: center;"></td>
//         <td style="text-align: center;"></td>
//         <td style="text-align: right;">${t("Coupon Applied")}</td>
//         <td style="text-align: center; vertical-align: text-top;"></td>
//       `
//           : `
//         <td style="text-align: center; vertical-align: text-top;"></td>
//         <td style="text-align: left;">${t("Coupon Applied")}</td>
//         <td style="text-align: end;"></td>
//         <td style="text-align: end;"></td>
//         <td style="text-align: end;">-${data?.order_details?.coupon_value?.toFixed(
//           2
//         )}</td>
//       `
//       }
//     </tr>
//   `;

//   const htmlContent = `
// <!DOCTYPE html>
// <html>
//   <head>
//     <style>
//       body {
//         font-family: Arial, sans-serif;
//         width: 100%;
//         color: #000000;
//         font-size: 10px;
//       }
//       .pos-receipt {
//         text-align: center;
//       }
//       .pos-receipt-contact div {
//         margin-top: 5px;
//         font-weight: semi-bold;
//       }
//       .pos-receipt-contact .cashier {
//         border-top: 1px dashed black;
//       }
//       .pos-receipt-contact table {
//         width: 100%;
//         margin-bottom: 5px;
//       }
//       .pos-receipt-contact table td:first-child {
//         width: 100%;
//       }
//       .receipt-orderdata {
//         width: 100%;
//         font-size: 10px;
//         margin-bottom: 5px;
//         margin-top: 5px;
//       }
//       .receipt-orderdata th,
//       .receipt-orderdata td {
//         padding: 0px;
//       }
//       .receipt-orderdata td {
//         font-size: 10px;
//       }
//       .receipt-orderdata td {
//         text-align: center;
//       }
//       .pos-receipt-right-align {
//         float: right;
//       }
//       .pos-receipt .pos-receipt-right-align {
//         font-size: 10px;
//       }
//       .orderDetail {
//         display: flex;
//         flex-direction: ${i18n.language === "ar" ? "row-reverse" : "row"};
//         justify-content: space-between;
//       }
//       .orderDetail div, .cashierDetail {
//         font-size: 10px;
//         margin-top: 5px;
//       }
//       .total {
//         border-top: 1px dashed black;
//         display: flex;
//         flex-direction: row;
//         justify-content: space-between;
//         padding-top: 10px;
//         margin-top: 10px;
//       }
//       .total div, .cashierDetail {
//         font-size: 9px;
//       }
//       .lines {
//         margin-top: 5px;
//         margin-bottom: 5px;
//       }
//       .order-delivery {
//         margin: 10px 0px;
//       }
//       .receiptHead {
//         text-align: center;
//         font-weight: semi-bold;
//         font-size: 14px;
//         margin: 10px 0px;
//         width: 100%;
//       }
//       .pos-receipt-bottomSection {
//         border-top: 1px dashed black;
//         margin-top: 10px;
//         font-size: 14px;
//       }
//       .bottomSection img {
//         width: 100px;
//       }
//       .after-footer {
//         margin-top: 0px;
//       }
//     </style>
//   </head>
//   <body>
//     <div class="pos-receipt-container">
//       <div class="pos-receipt">

//         <div style="text-align: center;">
//     ${
//       is_lahloob
//         ? `<div style="display: inline-block; border-radius: 15px; margin-bottom: -20px;">
//             <img src="https://assets.myginne.com/assets/store_app_UAT/lahloob-icon3.jpeg" alt="My SVG Image" width="100" style="border-radius: 15px;">
//         </div>`
//         : `<img style="max-height: 50px; max-width: 40px;" src="${data?.order_details?.retailer_image_url}" alt="Retailer Logo" width="100">`
//     }
// </div>
//         <div class="pos-receipt-contact">
//           <div class="lines"><strong>${
//             data?.order_details?.retailer_name
//           }</strong></div>
//           <div class="lines"><strong> +20 ${
//             data?.order_details?.partner_mobile
//           }</strong></div>
//         </div>
//         <div class="receiptHead">
//           <strong>${"Order Receipt"}</strong>
//         </div>
//         <div style="text-align: ${
//           i18n.language === "ar" ? "row-reverse" : "row"
//         }; border-top: 1px dashed black; border-bottom: 1px dashed black; padding: 5px 0px;">
//           <div class="orderDetail">
//             <div class="lines"><strong>${"Order No:"}</strong></div>
//             <div class="lines">${data?.order_details?.order_no}</div>
//           </div>
//           <div class="orderDetail">
//             <div class="lines"><strong>${"Order Date:"}</strong></div>
//             <div class="lines">${data?.order_details?.order_date}</div>
//           </div>
//           <div class="orderDetail">
//             <div class="lines"><strong>${"Customer:"}</strong></div>
//             <div class="lines">${data?.order_details?.partner_name}</div>
//           </div>
//           <div class="orderDetail">
//             <div class="lines"><strong>${"Customer Phone:"}</strong></div>
//             <div class="lines">${data?.order_details?.partner_mobile}</div>
//           </div>
//         </div>
//         <div>
//           <table class="receipt-orderdata">
//             <colgroup>
//               <col width="10%">
//               <col width="40%">
//               <col width="10%">
//               <col width="10%">
//               <col width="10%">
//             </colgroup>
//             <tr>
//               <th>${"S.N"}.</th>
//               <td style="text-align: center; font-weight: semi-bold; margin-top: 5px"><strong>${"Product Name"}</strong></td>
//               <td style="text-align: end; font-weight: semi-bold; margin-top: 5px"><strong>${"Qty"}</strong></td>
//               <td style="text-align: end; font-weight: semi-bold; margin-top: 5px"><strong>${"Unit Price"}</strong></td>
//               <td style="text-align: end; font-weight: semi-bold; margin-top: 5px"><strong>${"Amount"}</strong></td>
//             </tr>
//             ${htmlProductLines}
//             ${deliveryChargeTab}
//             ${
//               data?.order_details?.mgi_platform_fee != undefined &&
//               data?.order_details?.mgi_platform_fee != 0
//                 ? mgi_platform_feeTab
//                 : ""
//             }
//             ${
//               data?.order_details?.mgi_surcharge != undefined &&
//               data?.order_details?.mgi_surcharge != 0
//                 ? mgi_surchargeTab
//                 : ""
//             }
//             ${
//               data?.order_details?.mgi_handling_fee != undefined &&
//               data?.order_details?.mgi_handling_fee != 0
//                 ? mgi_handling_feeTab
//                 : ""
//             }
//             ${
//               data?.order_details?.mgi_discount != undefined &&
//               data?.order_details?.mgi_discount != 0
//                 ? mgi_Discount
//                 : ""
//             }
//             ${data?.order_details?.offer ? offerTab : ""}
//             ${
//               data?.order_details?.cashback_used &&
//               data?.order_details?.cashback_used > 0
//                 ? cashbackTab
//                 : ""
//             }
//             ${data?.order_details?.coupon &&
//               data?.order_details?.coupon !== ""
//                 ? couponTab
//                 : ""}
//           </table>
//         </div>
//         <div class="total">
//           <div><strong>${"TOTAL"}</strong></div>
//           <div><strong>EGP ${totalValue.toFixed(2)}</strong></div>
//         </div>
//         <div class="pos-receipt-bottomSection">
//           <br>
//           ${"Your Technology Partner"}<br>
//           <img style="max-height: 50px; max-width: 100px;" src="https://images.myginne.com/myginne.webp" alt="myGinne Logo">
//         </div>
//       </div>
//     </div>
//   </body>
// </html>
//   `;
//   return htmlContent;
// };
