import {isPointInPolygon} from 'geolib';
// import {
//   deliverablelocation,
//   getSavedDeliveryArea,
// } from '../../redux/reducer/AddressReducer';
 
export const GetGeoFencing = async(currentLocation, geoFencingData) => {
  let polygonData = {
    isInPolygon: false,
    deliveryArea: {},
  };
 
  for (const area of geoFencingData) {
    const polygonKey = Object.keys(area).find(key => !isNaN(Number(key)));
    if (!polygonKey) continue;
 
    const polygon = area[polygonKey].map(coord => ({
      latitude: parseFloat(coord.latitude),
      longitude: parseFloat(coord.longitude),
    }));
    // console.log("IS in location===========>>>>",isPointInPolygon(currentLocation, polygon));
    if (isPointInPolygon(currentLocation, polygon)) {
      let deliveryArea = {
        delivery_area: area?.deliver_area_name,
        delivery_area_id: area?.deliver_area_id,
        level: area?.level,
        service_area: area?.service_area,
      };
      polygonData = {
        isInPolygon: true,
        deliveryArea: deliveryArea,
      };
      break;
    }
  }
  return polygonData;
};