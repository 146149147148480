import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Sidebar from "../../../components/SideBar/sidebar";
import InventoryBar from "../../../components/Inventory/InventoryBar";
import InventoryOnboard from "../InventoryOnboard/InventoryOnboard";
import InventoryTableHeader from "../../../components/Inventory/InventoryTableHeader";
import { useSelector } from "react-redux";
import { routes } from "../../../Routes";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const Inventory = () => {
  const { t, i18n } = useTranslation();

  const [showInventoryOnboard, setShowInventoryOnboard] = useState(false);
  const [selectedInventoryComponent, setSelectedInventoryComponent] =
    useState(null);
  const [selectedInventoryTile, setSelectedInventoryTile] = useState(null);

  const [prevData, setPrevdata] = useState({});
  const [showTestDiv, setShowTestDiv] = useState(false);


  const handleInventoryTileClick = (heading) => {
    setSelectedInventoryTile(heading);
  };

  // const handleInventoryBoxClick = (heading) => {
  //   switch (heading) {
  //     case "Stock Out":
  //       setSelectedInventoryComponent("out");
  //       // setSelectedInventoryComponent(<StockOut user_id={user_id} store_id={store_id} />);
  //       break;
  //     case "Low On Inventory":
  //       setSelectedInventoryComponent("low");
  //       // setSelectedInventoryComponent(<LowOnInventory user_id={user_id} store_id={store_id} />);
  //       break;
  //     case "High On Inventory":
  //       setSelectedInventoryComponent("high");
  //       // setSelectedInventoryComponent(<HighOnInventory user_id={user_id} store_id={store_id} />);
  //       break;
  //     default:
  //       setSelectedInventoryComponent(null);
  //   }
  // };

  console.log("selectedInventoryComponent", selectedInventoryTile);

  const handleAddProductClick = () => {
    setShowInventoryOnboard(true);
  };

  const handleAllProductsClick = () => {
  console.log("handleAllProductsClick");

    setSelectedInventoryTile(null); // Reset selectedInventoryTile to null
  };

  const getSelectedType = () => {
    if (
      selectedInventoryTile === "Out of Stock" ||
      selectedInventoryTile === "المخزون المنتهي"
    ) {
      return "out";
    } else if (
      selectedInventoryTile === "Low on Inventory" ||
      selectedInventoryTile === "انخفاض في المخزون"
    ) {
      return "low";
    } else if (
      selectedInventoryTile === "High on Inventory" ||
      selectedInventoryTile === "ارتفاع في المخزون"
    ) {
      return "high";
    } else {
      return null;
    }
  };

  useEffect(() => {
    const container = document.getElementById("inventoryContainer"); // Update with actual container ID
 
    if (!container) {
      console.log("Scrollable div not found.");
      return;
    }
 
    console.log("Adding scroll listener to div:", container);
 
    const handleScroll = () => {
      // console.log("Div Scrolling...");
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;
 
      // console.log("Div Scroll Top:", scrollTop);
      // console.log("Div Half Screen:", scrollHeight / 2);
 
      if (scrollTop + clientHeight >= scrollHeight) {
        setShowTestDiv(true);
      } else {
        setShowTestDiv(false);
      }
    };
 
    container.addEventListener("scroll", handleScroll);
 
    return () => container.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {/* <div id="inventoryContainer" class="overflow-auto h-[900px]"> */}
      <div className="float-left fixed z-10">{/* <Sidebar /> */}</div>
      <div className="w-full pb-6">
        <div className="flex flex-col flex-1">
          <Navbar heading="inventory" />

          {selectedInventoryComponent ? (
            <div>{selectedInventoryComponent}</div>
          ) : (
            <>
              {selectedInventoryTile ? (
                // Only show breadcrumb if a tile has been selected
                <>
                  <Breadcrumb
                    separator={<DoubleRightOutlined />}
                    items={[
                      {
                        title: (
                          <Link
                            to={routes.inventory.root}
                            onClick={handleAllProductsClick}
                          >
                            {t("all_products")}
                          </Link>
                        ),
                        key: "all_products",
                      },
                      { title: selectedInventoryTile },
                    ]}
                    className="relative mt-20 ms-4 font-semibold text-[#5C5C5C]"
                  />
                </>
              ) : null}
              {showInventoryOnboard ? (
                <InventoryOnboard
                  onClose={() => setShowInventoryOnboard(false)}
                />
              ) : (
                <>
                  {selectedInventoryTile == null && (
                    <InventoryBar
                      setPrevData={setPrevdata}
                      onInventoryBoxClick={handleInventoryTileClick}
                    />
                  )}
                  <InventoryTableHeader
                    dashData={prevData}
                    selectedInventoryTile={getSelectedType()}
                  />
                </>
              )}
            </>
          )}
        </div>
        {showTestDiv && (
          <div className="fixed top-16 right-10 bg-gray-800 text-white p-4 rounded-lg shadow-lg">
            {/* Hello, this is a test. */}
          </div>
        )}
      </div>
      {/* </div> */}
    </>
  );
};

export default Inventory;
