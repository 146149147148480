import React from "react";
import {Image}  from "antd";
import one from "../../assets/Order/one.svg";
import two from "../../assets/Order/two.svg";
import three from "../../assets/Order/three.svg";
import fourth from "../../assets/Order/fourth.svg";

const DashboardEorder = () => {
    const values = {
        1: {
          img: one,
          heading: "New Order",
          productsleft: "10",
          bgcolor: "#fbdada",
          textcolor: "#EB3C3C",
        },
        2: {
          img: two,
          heading: "In-Process",
          productsleft: "12",
          bgcolor: "#ffe5cd",
          textcolor: "#EB853C",
        },
        3: {
          img: three,
          heading: "On the Way",
          productsleft: "02",
          bgcolor: "rgb(209,241,252)",
          textcolor: "#059003",
        },
        4: {
            img: three,
            heading: "Delivered",
            productsleft: "06",
            bgcolor: "rgb(209,241,252)",
            textcolor: "#059003",
          },
      };
  return (
    <div className="flex flex-wrap rounded-lg bg-[#ffffff] ml-3 mr-3">
        {Object.values(values).map((val, index) => (
  <div key={index} className="w-full sm:w-1/2 md:w-1/2 xl:w-1/4 px-3 mb-2 items-center justify-center relative">
    <div className="w-full rounded-lg flex p-2 px-3 xl:mb-0 relative">
      {/* <Image src={one} layout="fixed" width={30} height={30} className="absolute top-2 left-2" /> */}
      <div className="">
        <p className="text-blue-700 font-normal text-2xl ml-9 pl-9">{val.productsleft}</p>
        <p className="font-medium text-xl pt-2 ml-9 pl-3">{val.heading}</p>
      </div>
    </div>
    {/* Vertical grey line */}
    {index+4 !== Object.values(values).length  && (
      <div className="absolute h-50 left-0 top-4 bottom-4 bg-gray-300 w-1 shadow"></div>
    )}
  </div>
))}


      {/* <div className="w-full sm:w-1/2 md:w-1/2 xl:w-1/4 px-3 mb-2">
        <div className="w-full bg-[#D1F1FC] rounded-lg flex items-center p-2 px-3 xl:mb-0 shadow-sm relative">
          <Image src={two} layout="fixed" width={30} height={30} className="absolute top-2 left-2" />
          <div className="">
            <p className="font-normal text-lg ml-9">Total Revenue</p>
            <div className="flex items-center gap-1">
              <p className="font-medium text-xl pt-2">EGP 30,000</p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full sm:w-1/2 md:w-1/2 xl:w-1/4 px-3 mb-2">
        <div className="w-full bg-[#D1F1FC] rounded-lg flex items-center p-2 px-3 shadow-sm relative">
          <Image src={three} layout="fixed" width={30} height={30} className="absolute top-2 left-2" />
          <div className="text-gray-700">
            <p className="font-normal text-lg ml-9">Total Expense</p>
            <div className="flex items-center gap-1">
              <p className="font-medium text-xl pt-2">EGP 7,500</p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full sm:w-1/2 md:w-1/2  xl:w-1/4 px-3 mb-2">
        <div className="w-full bg-[#D1F1FC] rounded-lg flex items-center p-2 px-3 shadow-sm relative">
          <Image src={fourth} layout="fixed" width={30} height={30} className="absolute top-2 left-2" />
          <div className="text-gray-700">
            <p className="font-normal text-lg ml-9">Total Profit</p>
            <p className="font-medium text-xl ml-9 pt-2">23</p>
          </div>
        </div>
      </div> */}
      
    </div>
  );
};

export default DashboardEorder;
