import React from "react";
import {Image}  from "antd";
import one from "../../assets/Dashboard/TotalSale.svg";
import two from "../../assets/Dashboard/TotalRevenue.svg";
import three from "../../assets/Dashboard/TotalExpense.svg";
import fourth from "../../assets/Dashboard/TotalProfit.svg";

const DashboardAmount = () => {
  return (
    <div className="flex flex-wrap">
      <div className="w-full sm:w-1/2 md:w-1/2 xl:w-1/4 px-3 mb-2">
        <div className="w-full bg-[#D1F1FC] rounded-lg flex p-2 px-3 xl:mb-0 shadow-sm relative">
          <Image src={one} layout="fixed" width={30} height={30} className="absolute top-2 left-2" alt="Image"/>
          <div className="text-gray-700">
            <p className="font-normal text-lg ml-9">Total Sales</p>
            <p className="font-medium text-xl pt-2">EGP 29,872</p>
          </div>
        </div>
      </div>

      <div className="w-full sm:w-1/2 md:w-1/2 xl:w-1/4 px-3 mb-2">
        <div className="w-full bg-[#D1F1FC] rounded-lg flex items-center p-2 px-3 xl:mb-0 shadow-sm relative">
          <Image src={two} layout="fixed" width={30} height={30} className="absolute top-2 left-2" alt="Image"/>
          <div className="">
            <p className="font-normal text-lg ml-9">Total Revenue</p>
            <div className="flex items-center gap-1">
              <p className="font-medium text-xl pt-2">EGP 30,000</p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full sm:w-1/2 md:w-1/2 xl:w-1/4 px-3 mb-2">
        <div className="w-full bg-[#D1F1FC] rounded-lg flex items-center p-2 px-3 shadow-sm relative">
          <Image src={three} layout="fixed" width={30} height={30} className="absolute top-2 left-2" alt="Image"/>
          <div className="text-gray-700">
            <p className="font-normal text-lg ml-9">Total Expense</p>
            <div className="flex items-center gap-1">
              <p className="font-medium text-xl pt-2">EGP 7,500</p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full sm:w-1/2 md:w-1/2  xl:w-1/4 px-3 mb-2">
        <div className="w-full bg-[#D1F1FC] rounded-lg flex items-center p-2 px-3 shadow-sm relative">
          <Image src={fourth} layout="fixed" width={30} height={30} className="absolute top-2 left-2" alt="Image"/>
          <div className="text-gray-700">
            <p className="font-normal text-lg ml-9">Total Profit</p>
            <p className="font-medium text-xl ml-9 pt-2">23</p>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default DashboardAmount;
