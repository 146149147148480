import axiosInstance from "../AxiosInstance";
import { URLS } from "../API/API endpoints";
import { store } from "../Redux/store";
import i18next from "i18next";
import { toast } from "react-toastify";
import axios from "axios";


const { login } = store.getState();

// const user_id = login.user;
// const store_id = login.user;

export const getSessionData = async (payload) => {
  try {
    // const payload = {
    //   lang: i18next.language === "ar" ? "ar_001" : "en_US",
    //   user_id: user_id,
    //   //  store_id:store_id,
    // };
    const response = await axiosInstance.post(
      URLS.GET_SESSION_SUMMARY,
      payload
    );
    // console.log("Shipping Cost", response);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const closePosSession =  async (accessToken,payload) => {
  try {
    console.log('access=>>',accessToken);
    const response = await axios.post(URLS.POS_CLOSE_SESSION, payload,{
      headers: {
        "access-token": accessToken, 
        "Content-Type": "application/json",
      }}
    );
    // console.log("response==>", response?.data?.result)
    return response.data;
  } catch (error) {
    // dispatch(loginUserFailure(error.message));
    return error;
  }
};
