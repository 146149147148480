// actions/paymentActions.js

export const SET_DASH_DATA = "SET_SELECTED_PAYMENT_METHOD";
export const CLEAR_DASH_DATA = "CLEAR_PAYMENT_METHOD";

export const setDashData = (data) => ({
    type: SET_DASH_DATA,
    payload: data,
  });
  
  export const clearDashData = () => ({
    type: CLEAR_DASH_DATA,
  });

// export const storeSelectedPaymentMethod = (method) => ({
//   type: SET_DASH_DATA,
//   payload: method,
// });

// export const clearPaymentMethod = () => ({
//     type: CLEAR_DASH_DATA,
//   });