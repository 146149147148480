import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { URLS } from "../../API/API endpoints";
import axiosInstance from "../../AxiosInstance";

// Function to fetch data from the Google Maps Geocoding API
const getGoogleMapsData = async (latitude, longitude) => {
  try {
    if (latitude && longitude) {
      
      const googleMapsApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_G00GLE_API_KEY}`;
      const googleApiResponse = await axios.get(googleMapsApiUrl);
  // console.log("api map",googleApiResponse)
      // Extract administrative area level 2 and locality from the response
      const  city = googleApiResponse.data.results[0]?.address_components
        .find(component => component.types.includes('administrative_area_level_2'))?.long_name || '';
      const area = googleApiResponse.data.results[0]?.address_components
        .find(component => component.types.includes('locality'))?.long_name || '';

      return {
        city,
        area,
      };
    }
    return {};
  } catch (error) {
    throw error;
  }
};

// Async thunk to fetch delivery areas
export const fetchDeliveryAreas = async (location) => {
    try {

        const response = await axios.post(URLS.SHOP_LOCATION_API_URL, location);
       console.log("Delivery Area",response.data)
      return response.data;
  
    } catch (error) {
      throw error;
    }
  };
