import { CloseOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import sampleFile from "../../src/data/sample_file_upload_bulk_inventory.xlsx";
import download_icon from "../../src/assets/download_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getInventorydashboard, UploadBulkInventory } from "../API  Functions/InventoryFunctions";
import * as XLSX from "xlsx"; // Importing xlsx library
import { toast } from "react-toastify";
import { Download } from "lucide-react";
import { Upload } from "antd";
import { setDashData } from "../Redux/Action/DashData";

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
  container: {
    width: "70%",
    padding: "30px",
    borderRadius: "18px",
    margin: "0 auto",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    textAlign: "left",
    marginBottom: "10px",
  },
  cancelIcon: {
    cursor: "pointer",
  },
  description: {
    textAlign: "left",
    fontSize: "16px",
    color: "#272728",
    marginTop: "10px",
  },
  input: {
    width: "100%",
    padding: "10px",
    borderRadius: "12px",
    border: "1px solid #CCCCCC",
    marginBottom: "20px",
    boxSizing: "border-box",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    width: "180px",
    height: "45px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    marginRight: "10px",
    transition: "opacity 0.3s", // Smooth transition for opacity change
  },
  confirmButton: {
    backgroundColor: "primaryBlue",
    color: "#FFFFFF",
  },
  cancelButton: {
    backgroundColor: "#CCCCCC",
    color: "#000000",
  },
};

const ImportInventory = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);

  const [isPriceChecked, setIsPriceChecked] = useState(false);
  const [quantityOption, setQuantityOption] = useState("add");
  const [fileError, setFileError] = useState("");
  const [base64File, setBase64File] = useState("");
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isUpdatePriceOnlyChecked, setIsUpdatePriceOnlyChecked] =
    useState(false);
  const [isUpdateQtyOnlyChecked, setIsUpdateQtyOnlyChecked] = useState(false);
  const [bounceDetails, setBounceDetails] = useState([]); // State to store bounce details

    const company_id = useSelector((state) => state?.login?.user?.result?.company_id);
  
    const getInventoryKPI = () => {
      getInventorydashboard(user_id,company_id).then((res) => {
        dispatch(setDashData(res?.result[0]?.response));
        // console.log("INVENTORY DASHBOARD DATA", res?.result[0]?.response);
        
      });
    };

  const isSubmitDisabled =
    loading ||
    !base64File ||
    (!isPriceChecked && !isUpdatePriceOnlyChecked && !isUpdateQtyOnlyChecked);
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");


    // const payload = {
    //   user_id: user_id,
    //   file: base64File,
    //   is_update_price: !isUpdateQtyOnlyChecked && isPriceChecked,
    //   update_price_only: !isUpdateQtyOnlyChecked && isUpdatePriceOnlyChecked,
    //   is_update_qty: isUpdateQtyOnlyChecked || (!isUpdatePriceOnlyChecked && quantityOption === 'update'),
    //   is_add_qty: !isUpdateQtyOnlyChecked && !isUpdatePriceOnlyChecked && quantityOption === 'add'
    // };

    const payload = {
      user_id: user_id,
      file: base64File,
      is_update_price: !isUpdateQtyOnlyChecked && isPriceChecked,
      update_price_only: !isUpdateQtyOnlyChecked && isUpdatePriceOnlyChecked,
      is_update_qty: !isUpdateQtyOnlyChecked && quantityOption === 'update',
      is_add_qty: isUpdateQtyOnlyChecked || (!isUpdatePriceOnlyChecked && quantityOption === 'add')
    };

    try {
      const result = await UploadBulkInventory(payload);

      // console.log("result", result.result.bounce_details.length );
      // console.log("bpunce result", result.result.bounce_details );
      if (
        result.result.status_code === 200 &&
        result.result.bounce_details.length > 0 &&
        result.result.is_updated === true
      ) {
        toast.error(
          t(
            "Some data is updated some is invalid. Download the Excel file for invalid data."
          )
        );
      }

      if (
        result.result.status_code === 200 &&
        result.result.bounce_details.length === 0
      ) {
        toast.success(t("Inventory Updated Successfully"));
        getInventoryKPI()
        onClose();
        setBase64File(""); // Clear the base64 file
        setFileName(""); // Clear the file name
      } else if (result.result.bounce_details.length > 0) {
        console.log("RESSS", result.result.bounce_details);

        setBounceDetails(result.result.bounce_details);
        setBase64File(""); // Clear the base64 file
        setFileName(""); // Clear the file name
        // }
      } else {
        setErrorMessage(
          result.message || "An error occurred during file upload."
        );
      }
    } catch (error) {
      setErrorMessage("Failed to upload file. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // console.log("BOUNCE DETAILS", bounceDetails);

  const downloadBounceExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      bounceDetails.map((item) => ({
        Barcode: item.barcode,
        Quantity: item.onhand_qty,
        "Sales Price": item.sale_price,
        "Cost Price": item.cost_price,
        "Warning Message": item.message,
      }))
    );

    XLSX.utils.book_append_sheet(wb, ws, "Bounce Details");

    // Generate a file and trigger download
    XLSX.writeFile(wb, "bounce_details.xlsx");
  };

  useEffect(() => {
    setIsPriceChecked(true);
  }, []);

  // Function to trigger file download
  const downloadSampleFile = () => {
    const link = document.createElement("a");
    link.href = sampleFile;
    link.download = "sample_file_upload_bulk_inventory.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Function to handle file upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (fileExtension !== "xls" && fileExtension !== "xlsx") {
        setFileError("Please upload a valid Excel file.");
        setBase64File("");
        setFileName(""); // Clear file name if the file type is invalid
      } else {
        setFileError("");
        setFileName(file.name); // Store the file name
        convertToBase64(file);
      }
    }
  };

  const convertToBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64Data = reader.result.split(",")[1];
      setBase64File(base64Data);
    };
  };

  // console.log("base64File", base64File);

  // const isSubmitDisabled = loading || !base64File;

  return (
    <>
      {isOpen && (
        <>
          <div className="backdrop-blur" style={styles.overlay} onClick={onClose}></div>
          <div style={styles.container}>
            <div style={styles.header}>
              <p style={styles.title}>{t("Update Inventory")}</p>
              <CloseOutlined style={styles.cancelIcon} onClick={onClose} />
            </div>
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Update Options */}
              <div className="grid grid-cols-3 gap-4">
                {/* <label className="relative bg-gray-50 rounded-lg p-4 cursor-pointer"> */}
                <label
                  className={`relative rounded-lg p-4 cursor-pointer transition-colors duration-200 ${
                    !isUpdatePriceOnlyChecked && !isUpdateQtyOnlyChecked
                      ? "bg-gray-200 text-black"
                      : "bg-gray-50 border-2 border-gray-500 hover:bg-gray-100"
                  }`}
                >
                  <input
                    type="radio"
                    name="updateType"
                    checked={
                      !isUpdatePriceOnlyChecked && !isUpdateQtyOnlyChecked
                    }
                    onChange={() => {
                      setIsPriceChecked(true);
                      setIsUpdatePriceOnlyChecked(false);
                      setIsUpdateQtyOnlyChecked(false);
                    }}
                    className="sr-only"
                  />
                  <div
                    className={`flex items-center gap-2 ${
                      !isUpdatePriceOnlyChecked && !isUpdateQtyOnlyChecked
                        ? "text-blue-600"
                        : "text-gray-700"
                    }`}
                  >
                    <div
                      className={`w-4 h-4 rounded-full border-2 ${
                        !isUpdatePriceOnlyChecked && !isUpdateQtyOnlyChecked
                          ? "border-blue-500 bg-blue-500"
                          : "border-gray-300"
                      }`}
                    >
                      {!isUpdatePriceOnlyChecked && !isUpdateQtyOnlyChecked && (
                        <div className="w-2 h-2 bg-blue-500 rounded-full m-0.5" />
                      )}
                    </div>
                    <span className="text-sm">{t("Update Price & Quantity")}</span>
                  </div>
                </label>

                {/* <label className="relative bg-gray-50 rounded-lg p-4 cursor-pointer"> */}
                <label
                  className={`relative rounded-lg p-4 cursor-pointer transition-colors duration-200 ${
                    isUpdatePriceOnlyChecked
                      ? "bg-gray-200 text-black"
                      : "bg-gray-50 border-2 border-gray-500 hover:bg-gray-100"
                  }`}
                >
                  <input
                    type="radio"
                    name="updateType"
                    checked={isUpdatePriceOnlyChecked}
                    onChange={() => {
                      setIsUpdatePriceOnlyChecked(true);
                      setIsPriceChecked(false);
                      setIsUpdateQtyOnlyChecked(false);
                    }}
                    className="sr-only"
                  />
                  <div
                    className={`flex items-center gap-2 ${
                      isUpdatePriceOnlyChecked
                        ? "text-blue-600"
                        : "text-gray-700"
                    }`}
                  >
                    <div
                      className={`w-4 h-4 rounded-full border-2 ${
                        isUpdatePriceOnlyChecked
                          ? "border-blue-500 bg-blue-500"
                          : "border-gray-300"
                      }`}
                    >
                      {isUpdatePriceOnlyChecked && (
                        <div className="w-2 h-2 bg-blue-500 rounded-full m-0.5" />
                      )}
                    </div>
                    <span className="text-sm">{t("Update Price Only")}</span>
                  </div>
                </label>

                {/* <label className="relative bg-gray-50 rounded-lg p-4 cursor-pointer"> */}
                <label
                  className={`relative rounded-lg p-4 cursor-pointer transition-colors duration-200 ${
                    isUpdateQtyOnlyChecked
                      ? "bg-gray-200 text-black"
                      : "bg-gray-50  border-2 border-gray-500 hover:bg-gray-100"
                  }`}
                >
                  <input
                    type="radio"
                    name="updateType"
                    checked={isUpdateQtyOnlyChecked}
                    onChange={() => {
                      setIsUpdateQtyOnlyChecked(true);
                      setIsPriceChecked(false);
                      setIsUpdatePriceOnlyChecked(false);
                      setQuantityOption('update');
                    }}
                    className="sr-only"
                  />
                  <div
                    className={`flex items-center gap-2 ${
                      isUpdateQtyOnlyChecked ? "text-blue-600" : "text-gray-700"
                    }`}
                  >
                    <div
                      className={`w-4 h-4 rounded-full border-2 ${ 
                        isUpdateQtyOnlyChecked
                          ? "border-blue-500 bg-blue-500"
                          : "border-gray-300"
                      }`}
                    >
                      {isUpdateQtyOnlyChecked && (
                        <div className="w-2 h-2 bg-blue-500 rounded-full m-0.5" />
                      )}
                    </div>
                    <span className="text-sm">{t("Update Quantity Only")}</span>
                  </div>
                </label>
              </div>

              {/* Quantity Options */}
              {/* {!isUpdatePriceOnlyChecked && ( */}
              {!isUpdatePriceOnlyChecked && !isUpdateQtyOnlyChecked && (
                <div className="space-y-2 mt-4">
                  <h3 className="font-medium mb-2">{t("Quantity")}</h3>
                  <div className="grid grid-cols-3 gap-4">
                       {/* <label className="relative bg-gray-50 rounded-lg p-4 cursor-pointer"> */}
                       <label className={`relative rounded-lg p-4 cursor-pointer transition-colors duration-200 ${quantityOption === 'add' ? 'bg-gray-200 text-black' : 'bg-gray-50  border-2 border-gray-500 hover:bg-gray-100'}`}>
                      <input
                        type="radio"
                        name="quantityOption"
                        value="add"
                        checked={quantityOption === "add"}
                        onChange={(e) => setQuantityOption(e.target.value)}
                        className="sr-only"
                      />
                      <div
                        className={`flex items-center gap-2 ${
                          quantityOption === "add"
                            ? "text-blue-600"
                            : "text-gray-700"
                        }`}
                      >
                        <div
                          className={`w-4 h-4 rounded-full border-2 ${
                            quantityOption === "add"
                              ? "border-blue-500 bg-blue-500"
                              : "border-gray-300"
                          }`}
                        >
                          {quantityOption === "add" && (
                            <div className="w-2 h-2 bg-blue-500 rounded-full m-0.5" />
                          )}
                        </div>
                        <span className="text-sm">{t("Update Quantity")}</span>
                      </div>
                    </label>
                    
                    {/* <label className="relative bg-gray-50 rounded-lg p-4 cursor-pointer"> */}
                    <label className={`relative rounded-lg p-4 cursor-pointer transition-colors duration-200 ${quantityOption === 'update' ? 'bg-gray-200 text-black' : 'bg-gray-50  border-2 border-gray-500 hover:bg-gray-100'}`}>
                      <input
                        type="radio"
                        name="quantityOption"
                        value="update"
                        checked={quantityOption === "update"}
                        onChange={(e) => setQuantityOption(e.target.value)}
                        className="sr-only"
                      />
                      <div
                        className={`flex items-center gap-2 ${
                          quantityOption === "update"
                            ? "text-blue-600"
                            : "text-gray-700"
                        }`}
                      >
                        <div
                          className={`w-4 h-4 rounded-full border-2 ${
                            quantityOption === "update"
                              ? "border-blue-500 bg-blue-500"
                              : "border-gray-300"
                          }`}
                        >
                          {quantityOption === "update" && (
                            <div className="w-2 h-2 bg-blue-500 rounded-full m-0.5" />
                          )}
                        </div>
                        <span className="text-sm">{t("Add Quantity")}</span>
                      </div>
                    </label>

                 
                  </div>
                </div>
              )}

              {/* File Upload */}
              <div className="space-y-2 mt-4">
                <h3 className="font-medium mb-2">{t("Upload File")}</h3>
                <div className="border-2 border-dashed border-gray-300  bg-gray-100 rounded-lg p-8">
                  <div className="flex flex-col items-center">
                    <Upload className="w-5 h-5 text-gray-400 mb-2" />
                    {/* <p className="text-sm text-gray-500 mb-1"></p> */}
                    <input
                      type="file"
                      id="file-upload"
                      className="hidden"
                      onChange={handleFileUpload}
                      accept=".xlsx,.xls"
                    />
                    <button
                      type="button"
                      onClick={() =>
                        document.getElementById("file-upload").click()
                      }
                      className="bg-black text-white text-xs px-4 py-2 rounded-full mb-5"
                    >
                      {t("Choose Excel File")}
                    </button>
                  </div>
                </div>
                {fileName && (
                  <p className="text-sm text-gray-600 mt-2">
                    {t("Selected file")}: {fileName}
                  </p>
                )}
                {fileError && (
                  <p className="text-sm text-red-600">{fileError}</p>
                )}
              </div>

              {/* Download Sample */}
              <div className="flex items-center gap-2 text-black mt-5">
                <span>{t("Download Sample File")}:</span>
                <button
                  type="button"
                  className="text-blue-600 hover:text-blue-700"
                  onClick={downloadSampleFile}
                >
                  <Download size={20} />
                </button>
              </div>

              {bounceDetails.length > 0 && (
                <div className="mt-5">
                  <button
                    type="button"
                    className="w-full h-10 bg-[#78CFEC] text-white font-medium rounded-lg"
                    onClick={downloadBounceExcel}
                  >
                    {t("Download Error File")}
                  </button>
                </div>
              )}

              {/* Action Buttons */}
              <div className="grid grid-cols-3 gap-4 pt-4">
                <button
                  type="button"
                  onClick={onClose}
                  className="w-full px-4 py-2 border border-gray-300 font-semibold rounded-lg text-gray-700 hover:bg-gray-50"
                >
                  {t("Cancel")}
                </button>
                <button
                  type="submit"
                  disabled={!base64File || loading}
                  className={`w-full px-4 py-2 rounded-lg font-semibold text-white ${
                    loading || !base64File
                      ? "bg-primaryBlue opacity-50 cursor-not-allowed"
                      : "bg-primaryBlue hover:bg-gray-600"
                  }`}
                  // className="w-full px-4 py-2 bg-primaryBlue text-white rounded-lg hover:bg-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {t("Submit")}
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default ImportInventory;

// import { CloseOutlined } from "@ant-design/icons";
// import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
// import sampleFile from "../../src/data/sample_file_upload_bulk_inventory.xlsx";
// import download_icon from "../../src/assets/download_icon.svg";
// import { useSelector } from "react-redux";
// import { UploadBulkInventory } from "../API  Functions/InventoryFunctions";
// import * as XLSX from "xlsx"; // Importing xlsx library
// import { toast } from "react-toastify";

// const styles = {
//   overlay: {
//     position: "fixed",
//     top: 0,
//     left: 0,
//     width: "100%",
//     height: "100%",
//     backgroundColor: "rgba(0, 0, 0, 0.5)",
//     zIndex: 999,
//   },
//   container: {
//     width: "500px",
//     padding: "30px",
//     borderRadius: "18px",
//     margin: "0 auto",
//     backgroundColor: "#ffffff",
//     boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//     position: "fixed",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     zIndex: 1000,
//   },
//   header: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//   },
//   title: {
//     fontSize: "20px",
//     fontWeight: "bold",
//     textAlign: "left",
//     marginBottom: "10px",
//   },
//   cancelIcon: {
//     cursor: "pointer",
//   },
//   description: {
//     textAlign: "left",
//     fontSize: "16px",
//     color: "#272728",
//     marginTop: "10px",
//   },
//   input: {
//     width: "100%",
//     padding: "10px",
//     borderRadius: "12px",
//     border: "1px solid #CCCCCC",
//     marginBottom: "20px",
//     boxSizing: "border-box",
//   },
//   buttonContainer: {
//     display: "flex",
//     justifyContent: "center",
//   },
//   button: {
//     width: "180px",
//     height: "45px",
//     borderRadius: "5px",
//     border: "none",
//     cursor: "pointer",
//     marginRight: "10px",
//     transition: "opacity 0.3s", // Smooth transition for opacity change
//   },
//   confirmButton: {
//     backgroundColor: "primaryBlue",
//     color: "#FFFFFF",
//   },
//   cancelButton: {
//     backgroundColor: "#CCCCCC",
//     color: "#000000",
//   },
// };

// const ImportInventory = ({ isOpen, onClose }) => {
//   const { t, i18n } = useTranslation();
//   const user_id = useSelector((state) => state?.login?.user?.result?.user_id);

//   const [isPriceChecked, setIsPriceChecked] = useState(false);
//   const [quantityOption, setQuantityOption] = useState("add");
//   const [fileError, setFileError] = useState("");
//   const [base64File, setBase64File] = useState("");
//   const [fileName, setFileName] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [errorMessage, setErrorMessage] = useState("");
//   const [successMessage, setSuccessMessage] = useState("");
//   const [isUpdatePriceOnlyChecked, setIsUpdatePriceOnlyChecked] =
//     useState(false);
//   const [isUpdateQtyOnlyChecked, setIsUpdateQtyOnlyChecked] = useState(false);
//   const [bounceDetails, setBounceDetails] = useState([]); // State to store bounce details

//   const isSubmitDisabled =
//     loading ||
//     !base64File ||(
//       !isPriceChecked && !isUpdatePriceOnlyChecked && !isUpdateQtyOnlyChecked
//     );
//   // Handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     setErrorMessage("");
//     setSuccessMessage("");

//     const payload = {
//       user_id: user_id,
//       file: base64File,
//       is_update_price: isPriceChecked,
//       update_price_only: isUpdatePriceOnlyChecked,
//       is_update_qty: isUpdateQtyOnlyChecked,
//       is_add_qty: !isUpdatePriceOnlyChecked && quantityOption === "add",
//     };

//     try {
//       const result = await UploadBulkInventory(payload);

//       // console.log("result", result.result.bounce_details.length );
//       // console.log("bpunce result", result.result.bounce_details );
//       if (
//         result.result.status_code === 200 &&
//         result.result.bounce_details.length > 0 &&
//         result.result.is_updated === true
//       ) {
//         toast.error(
//           t(
//             "Some data is updated some is invalid. Download the Excel file for invalid data."
//           )
//         );
//       }

//       if (
//         result.result.status_code === 200 &&
//         result.result.bounce_details.length === 0
//       ) {
//         toast.success(t("Inventory Updated Successfully"));
//         onClose();
//         setBase64File(""); // Clear the base64 file
//         setFileName(""); // Clear the file name
//       } else if (result.result.bounce_details.length > 0) {
//         console.log("RESSS", result.result.bounce_details);

//         setBounceDetails(result.result.bounce_details);
//         setBase64File(""); // Clear the base64 file
//         setFileName(""); // Clear the file name
//         // }
//       } else {
//         setErrorMessage(
//           result.message || "An error occurred during file upload."
//         );
//       }
//     } catch (error) {
//       setErrorMessage("Failed to upload file. Please try again.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   // console.log("BOUNCE DETAILS", bounceDetails);

//   const downloadBounceExcel = () => {
//     const wb = XLSX.utils.book_new();
//     const ws = XLSX.utils.json_to_sheet(
//       bounceDetails.map((item) => ({
//         Barcode: item.barcode,
//         Quantity: item.onhand_qty,
//         "Sales Price": item.sale_price,
//         "Cost Price": item.cost_price,
//         "Warning Message": item.message,
//       }))
//     );

//     XLSX.utils.book_append_sheet(wb, ws, "Bounce Details");

//     // Generate a file and trigger download
//     XLSX.writeFile(wb, "bounce_details.xlsx");
//   };

//   useEffect(() => {
//     setIsPriceChecked(true);
//   }, []);

//   // Handle checkbox change for Price checkbox
//   const handlePriceChange = (e) => {
//     setIsPriceChecked(e.target.checked);
//     setIsUpdatePriceOnlyChecked(false);
//     setIsUpdateQtyOnlyChecked(false);
//   };

//   // Handle checkbox change for Update Price Only checkbox
//   const handleUpdatePriceOnlyChange = (e) => {
//     const checked = e.target.checked;
//     setIsUpdatePriceOnlyChecked(checked);

//     // If "Update Price Only" is checked, uncheck and disable the other checkboxes/radio buttons
//     if (checked) {
//       setIsPriceChecked(false);
//       setIsUpdateQtyOnlyChecked(false);
//       setQuantityOption("add");
//     }
//   };

//   const handleUpdateQtyOnlyChange = (e) => {
//     const checked = e.target.checked;
//     setIsUpdateQtyOnlyChecked(checked);

//     // If "Update Price Only" is checked, uncheck and disable the other checkboxes/radio buttons
//     if (checked) {
//       setIsPriceChecked(false);
//       setQuantityOption("");
//       setIsUpdatePriceOnlyChecked(false);
//     }
//   };

//   // Handle radio button change for Quantity options
//   const handleQuantityChange = (e) => {
//     setQuantityOption(e.target.value);
//   };

//   // Function to trigger file download
//   const downloadSampleFile = () => {
//     const link = document.createElement("a");
//     link.href = sampleFile;
//     link.download = "sample_file_upload_bulk_inventory.xlsx";
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };

//   // Function to handle file upload
//   const handleFileUpload = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       const fileExtension = file.name.split(".").pop().toLowerCase();
//       if (fileExtension !== "xls" && fileExtension !== "xlsx") {
//         setFileError("Please upload a valid Excel file.");
//         setBase64File("");
//         setFileName(""); // Clear file name if the file type is invalid
//       } else {
//         setFileError("");
//         setFileName(file.name); // Store the file name
//         convertToBase64(file);
//       }
//     }
//   };

//   const convertToBase64 = (file) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onloadend = () => {
//       const base64Data = reader.result.split(",")[1];
//       setBase64File(base64Data);
//     };
//   };

//   // console.log("base64File", base64File);

//   // const isSubmitDisabled = loading || !base64File;

//   return (
//     <>
//       {isOpen && (
//         <>
//           <div style={styles.overlay} onClick={onClose}></div>
//           <div style={styles.container}>
//             <div style={styles.header}>
//               <p style={styles.title}>{t("Update Inventory")}</p>
//               <CloseOutlined style={styles.cancelIcon} onClick={onClose} />
//             </div>
//             <form onSubmit={handleSubmit}>
//               {/* Price Checkbox */}
//               <div className="flex flex-row">
//                 <label>{t("Update Price & Quantity")}:</label>
//                 <input
//                   type="checkbox"
//                   checked={isPriceChecked}
//                   onChange={handlePriceChange}
//                   // disabled={isUpdatePriceOnlyChecked}
//                   className="flex ms-4 cursor-pointer"
//                 />
//               </div>

//               {/* Update Price Only Checkbox */}
//               <div className="flex flex-row mt-2">
//                 <label>{t("Update Price Only")}:</label>
//                 <input
//                   type="checkbox"
//                   checked={isUpdatePriceOnlyChecked}
//                   onChange={handleUpdatePriceOnlyChange}
//                   // disabled={isPriceChecked}
//                   className="flex ms-4 cursor-pointer"
//                 />
//               </div>

//               {/* Update Qty Only Checkbox */}
//               <div className="flex flex-row mt-2">
//                 <label>{t("Update Quantity Only")}:</label>
//                 <input
//                   type="checkbox"
//                   checked={isUpdateQtyOnlyChecked} // Check if the quantity option is "update"
//                   onChange={handleUpdateQtyOnlyChange}
//                   // onChange={(e) => {
//                   //   const checked = e.target.checked;
//                   //   if (checked) {
//                   //     setIsPriceChecked(false); // Uncheck the price checkbox
//                   //     setIsUpdatePriceOnlyChecked(false); // Uncheck the update price only checkbox
//                   //     setQuantityOption("update"); // Set quantity option to "update"
//                   //   }
//                   // }}
//                   className="flex ms-4 cursor-pointer"
//                 />
//               </div>

//               {/* Quantity Section */}
//               <div className="flex flex-row items-center gap-2 mt-2">
//                 <span>{t("Quantity")}: </span>
//                 <label>
//                   <input
//                     type="radio"
//                     name="quantityOption"
//                     value="add"
//                     checked={quantityOption === "add"}
//                     onChange={handleQuantityChange}
//                     disabled={
//                       isUpdatePriceOnlyChecked || isUpdateQtyOnlyChecked
//                     }
//                     className="me-1 cursor-pointer"
//                   />
//                   {t("Update Quantity")}
//                 </label>
//                 <label>
//                   <input
//                     type="radio"
//                     name="quantityOption"
//                     value="update"
//                     checked={quantityOption === "update"}
//                     onChange={handleQuantityChange}
//                     disabled={
//                       isUpdatePriceOnlyChecked || isUpdateQtyOnlyChecked
//                     }
//                     className="me-1 cursor-pointer"
//                   />
//                   {t("Add Quantity")}
//                 </label>
//               </div>

//               {/* Error message for file upload */}
//               {fileError && (
//                 <div style={{ color: "red", marginTop: "10px" }}>
//                   {fileError}
//                 </div>
//               )}

//               {/* File Upload Section */}
//               <div className="mt-8">
//                 <input
//                   type="file"
//                   style={{ display: "none" }}
//                   id="upload-file"
//                   accept=".xlsx,.xls" // Limit to Excel files only
//                   onChange={handleFileUpload}
//                 />
//                 <button
//                   type="button"
//                   className="w-full h-10 border-primaryBlue border text-primaryBlue font-semibold rounded-lg"
//                   onClick={() => document.getElementById("upload-file").click()}
//                 >
//                   {t("Upload File")}
//                 </button>
//                 {/* Display the uploaded file name */}
//                 {fileName && (
//                   <div style={{ marginTop: "10px" }}>
//                     <strong>{t("Uploaded File")}: </strong>
//                     {fileName}
//                   </div>
//                 )}
//               </div>

//               {/* Sample File Download */}
//               <div className="flex flex-row items-center gap-2 mt-5">
//                 <span>{t("Download Sample File")}: </span>
//                 <img
//                   type="button"
//                   src={download_icon}
//                   className="h-4 cursor-pointer"
//                   onClick={downloadSampleFile}
//                   alt="download"
//                 />
//               </div>

//               {/* Display error or success messages */}
//               {loading && <div>Loading...</div>}
//               {errorMessage && (
//                 <div style={{ color: "red", marginTop: "10px" }}>
//                   {errorMessage}
//                 </div>
//               )}
//               {successMessage && (
//                 <div style={{ color: "green", marginTop: "10px" }}>
//                   {successMessage}
//                 </div>
//               )}

//               {/* Bounce Details Excel Download Button (if bounce details exist) */}
//               {bounceDetails.length > 0 && (
//                 <div className="mt-5">
//                   <button
//                     type="button"
//                     className="w-full h-10 bg-primaryBlue text-white font-semibold rounded-lg"
//                     onClick={downloadBounceExcel}
//                   >
//                     {t("Download Bounce Excel File")}
//                   </button>
//                 </div>
//               )}

//               {/* Form Action Buttons */}
//               <div className="flex justify-center mt-5">
//                 <button
//                   className="w-52 h-10 border-primaryBlue border text-primaryBlue font-semibold rounded-lg me-5"
//                   onClick={() => onClose()}
//                 >
//                   {t("Cancel")}
//                 </button>
//                 <button
//                   type="submit"
//                   className="w-52 h-10 text-white font-semibold rounded-lg bg-primaryBlue"
//                   style={{
//                     opacity: isSubmitDisabled ? 0.5 : 1,
//                     pointerEvents: isSubmitDisabled ? "none" : "auto",
//                   }}
//                   disabled={isSubmitDisabled}
//                 >
//                   {t("Submit")}
//                 </button>
//               </div>
//             </form>
//           </div>
//         </>
//       )}
//     </>
//   );
// };

// export default ImportInventory;
