import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import "../../styles.css";
import {
  Button,
  Image,
  Input,
  Select,
  TimePicker,
  TimePickerProps,
} from "antd";
import { ProfileShopDetailSchema } from "../validationSchema";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { URLS } from "../../API/API endpoints";
import axiosInstance from "../../AxiosInstance";
import UpdatedProfile from "../../modals/UpdatedProfile";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import { UpdateRetailerDetails } from "../../API  Functions/ProfileFunctions";
import LoadingSpinner from "../Loading";

const format = "hh:mm";

const ShopDetails = ({ payload, data }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // const user_id = useSelector((state) => state.login?.user?.user_id);
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);
  const store_id = useSelector((state) => state.login?.user?.store_id);
  const is_lahloob = useSelector(
    (state) => state.login?.user?.result?.is_lahloob
  );

  const [selectedDate, setSelectedDate] = useState(null);
  const [showUpdatedProfile, setShowUpdatedProfile] = useState(false);
  const [restricted, setrestricted] = useState(true);
  const [offdayserror, setoffdayserror] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Opentimeoptions, setOpentimeoptions] = useState([
    { value: "", label: t("select"), disabled: true },
    { value: "0.0", label: "12:00 AM" },
    { value: "0.3", label: "12:30 AM" },
    { value: "1.0", label: "01:00 AM" },
    { value: "1.3", label: "01:30 AM" },
    { value: "2.0", label: "02:00 AM" },
    { value: "2.3", label: "02:30 AM" },
    { value: "3.0", label: "03:00 AM" },
    { value: "3.3", label: "03:30 AM" },
    { value: "4.0", label: "04:00 AM" },
    { value: "4.3", label: "04:30 AM" },
    { value: "5.0", label: "05:00 AM" },
    { value: "5.3", label: "05:30 AM" },
    { value: "6.0", label: "06:00 AM" },
    { value: "6.3", label: "06:30 AM" },
    { value: "7.0", label: "07:00 AM" },
    { value: "7.3", label: "07:30 AM" },
    { value: "8.0", label: "08:00 AM" },
    { value: "8.3", label: "08:30 AM" },
    { value: "9.0", label: "09:00 AM" },
    { value: "9.3", label: "09:30 AM" },
    { value: "10.0", label: "10:00 AM" },
    { value: "10.3", label: "10:30 AM" },
    { value: "11.0", label: "11:00 AM" },
    { value: "11.3", label: "11:30 AM" },
    { value: "12.0", label: "12:00 PM" },
    { value: "12.3", label: "12:30 PM" },
    { value: "13.3", label: "01:30 PM" },
    { value: "13.0", label: "01:00 PM" },
    { value: "14.0", label: "02:00 PM" },
    { value: "14.3", label: "02:30 PM" },
    { value: "15.0", label: "03:00 PM" },
    { value: "15.3", label: "03:30 PM" },
    { value: "16.0", label: "04:00 PM" },
    { value: "16.3", label: "04:30 PM" },
    { value: "17.0", label: "05:00 PM" },
    { value: "17.3", label: "05:30 PM" },
    { value: "18.0", label: "06:00 PM" },
    { value: "18.3", label: "06:30 PM" },
    { value: "19.0", label: "07:00 PM" },
    { value: "19.3", label: "07:30 PM" },
    { value: "20.0", label: "08:00 PM" },
    { value: "20.3", label: "08:30 PM" },
    { value: "21.0", label: "09:00 PM" },
    { value: "21.3", label: "09:30 PM" },
    { value: "22.0", label: "10:00 PM" },
    { value: "22.3", label: "10:30 PM" },
    { value: "23.0", label: "11:00 PM" },
    { value: "23.3", label: "11:30 PM" },
  ]);
  const [Closingtimeoptions, setClosingtimeoptions] = useState([
    { value: "", label: t("select"), disabled: true },
    { value: "0.0", label: "12:00 AM" },
    { value: "0.3", label: "12:30 AM" },
    { value: "1.0", label: "01:00 AM" },
    { value: "1.3", label: "01:30 AM" },
    { value: "2.0", label: "02:00 AM" },
    { value: "2.3", label: "02:30 AM" },
    { value: "3.0", label: "03:00 AM" },
    { value: "3.3", label: "03:30 AM" },
    { value: "4.0", label: "04:00 AM" },
    { value: "4.3", label: "04:30 AM" },
    { value: "5.0", label: "05:00 AM" },
    { value: "5.3", label: "05:30 AM" },
    { value: "6.0", label: "06:00 AM" },
    { value: "6.3", label: "06:30 AM" },
    { value: "7.0", label: "07:00 AM" },
    { value: "7.3", label: "07:30 AM" },
    { value: "8.0", label: "08:00 AM" },
    { value: "8.3", label: "08:30 AM" },
    { value: "9.0", label: "09:00 AM" },
    { value: "9.3", label: "09:30 AM" },
    { value: "10.0", label: "10:00 AM" },
    { value: "10.3", label: "10:30 AM" },
    { value: "11.0", label: "11:00 AM" },
    { value: "11.3", label: "11:30 AM" },
    { value: "12.0", label: "12:00 PM" },
    { value: "12.3", label: "12:30 PM" },
    { value: "13.0", label: "01:00 PM" },
    { value: "13.3", label: "01:30 PM" },
    { value: "14.0", label: "02:00 PM" },
    { value: "14.3", label: "02:30 PM" },
    { value: "15.0", label: "03:00 PM" },
    { value: "15.3", label: "03:30 PM" },
    { value: "16.0", label: "04:00 PM" },
    { value: "16.3", label: "04:30 PM" },
    { value: "17.0", label: "05:00 PM" },
    { value: "17.3", label: "05:30 PM" },
    { value: "18.0", label: "06:00 PM" },
    { value: "18.3", label: "06:30 PM" },
    { value: "19.0", label: "07:00 PM" },
    { value: "19.3", label: "07:30 PM" },
    { value: "20.0", label: "08:00 PM" },
    { value: "20.3", label: "08:30 PM" },
    { value: "21.3", label: "09:30 PM" },
    { value: "21.0", label: "09:00 PM" },
    { value: "22.0", label: "10:00 PM" },
    { value: "22.3", label: "10:30 PM" },
    { value: "23.0", label: "11:00 PM" },
    { value: "23.3", label: "11:30 PM" },
  ]);

  const [offDayOptions, setOffDayOptions] = useState([
    { value: 1, label: "Sunday" },
    { value: 2, label: "Monday" },
    { value: 3, label: "Tuesday" },
    { value: 4, label: "Wednesday" },
    { value: 5, label: "Thursday" },
    { value: 6, label: "Friday" },
    { value: 7, label: "Saturday" },
  ]);

  const [nextday, setnextday] = useState(false);

  const convertTo12HourFormat = (time24) => {
    if (time24 === "24:00") {
      return "12:00 AM";
    }
    const [hours, minutes] = time24.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 || 12; // Convert to 12-hour format
    return `${hours12}:${String(minutes).padStart(2, "0")} ${period}`;
  };

  const convertTo24HourFormat = (time12) => {
    const [timePart, period] = time12.split(" ");
    const [hours, minutes] = timePart.split(":").map(Number);
    let hours24 = hours;
    if (period === "PM" && hours < 12) {
      hours24 = hours + 12; // Convert PM time to 24-hour format
    } else if (period === "AM" && hours === 12) {
      hours24 = 0; // Midnight (12 AM) in 24-hour format
    }
    return `${String(hours24).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };

  useEffect(() => {
    if (data) {
      SetData();
    }
  }, [data]);

  const SetData = () => {
    if (data) {
      const retailerDetails = data?.retailer_details;

      const offDayIds = retailerDetails.off_days.map((day) => day.off_day_id);

      const [prepHours, prepMinutes] = retailerDetails.order_preperation_time
        .split(":")
        .map(Number);
      const formattedPreparationTime = `${prepHours
        .toString()
        .padStart(2, "0")}:${prepMinutes.toString().padStart(2, "0")}`;

      const openingTime12 = convertTo12HourFormat(
        retailerDetails.shop_open_time || "00:00"
      );
      const closingTime12 = convertTo12HourFormat(
        retailerDetails.shop_close_time || "00:00"
      );

      console.log("Opening Time (12-hour):", openingTime12);
      console.log("Closing Time (12-hour):", closingTime12);

      formik.setValues({
        minimum_order_value: retailerDetails.min_order_value || 0,
        shop_close: retailerDetails.online_shop ? 0 : 1,
        prepration_time: formattedPreparationTime || "00:00",
        closing_time: closingTime12,
        opening_time: openingTime12,
        off_day_ids: offDayIds.length !== 0 ? offDayIds : null,
      });

      setrestricted(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      opening_time: "00:00",
      closing_time: "00:00",
      operatingdays: "7",
      minimum_order_value: 0,
      shop_close: 0,
      prepration_time: "00:00",
      off_day_ids: [],
    },

    // validationSchema: ProfileShopDetailSchema,
    validateOnChange: false,
    validateOnBlur: false,

    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      // Check if off_day_ids is valid
      if (
        formik?.values?.off_day_ids?.length === 1 &&
        formik?.values?.off_day_ids[0] === 0
      ) {
        setoffdayserror(true);
        setLoading(false);
        return; // Prevent submission if there's an error
      }

      const apipayload = {
        user_id: user_id,
        // open_time: convertTo24HourFormat(values.opening_time),
        // close_time: convertTo24HourFormat(values.closing_time),
        // check_next_date:
        //   values.opening_time === "12:00 AM" &&
        //   values.closing_time === "12:00 AM"
        //     ? false
        //     : convertTo24HourFormat(values.opening_time) >=
        //       convertTo24HourFormat(values.closing_time),
        order_prep_time: values.prepration_time,
        min_order_value: Number(values.minimum_order_value),
        // enable_disable_shop: values.shop_close === 0,
        // off_days:
        //   values.off_day_ids.map((off_day_id) => parseInt(off_day_id)) || [],
      };

      try {
        const response = await UpdateRetailerDetails(apipayload); // Ensure this function returns a promise
        if (response?.status === "Update Successfully") {
          setShowUpdatedProfile(true);
          setrestricted(true);
          setLoading(false);
          // SetData();
          // resetForm(); // Optionally reset the form after successful submission
        }
      } catch (error) {
        console.error("Error updating retailer details:", error);
        setLoading(false);
        // Handle error (e.g., show a notification)
      }
    },
    // resetForm();
    // },
  });
  const handleSubmit = (e) => {
    // console.log("DDDDDDDDD");

    // e.preventDefault(); // Prevent default form submission behavior
    formik.handleSubmit(e); // Call Formik's submit handler
  };

  const handleOptionChange = (selectedValues) => {
    setrestricted(false);
    formik.setFieldValue("off_day_ids", selectedValues);
  };

  // const handletimingChange = (name, selectedValues) => {
  //   formik.setFieldValue(name, selectedValues);
  //   setrestricted(false);
  //   name == "opening_time" &&
  //     !nextday &&
  //     setClosingtimeoptions(
  //       Opentimeoptions.slice(
  //         Opentimeoptions.findIndex(
  //           (option) => option.value === selectedValues
  //         ) + 1,
  //         Opentimeoptions.length
  //       )
  //     );
  //   name == "opening_time" &&
  //     formik.setFieldValue(
  //       "closing_time",
  //       Opentimeoptions[
  //         Opentimeoptions.findIndex(
  //           (option) => option.value === selectedValues
  //         ) + 1
  //       ]?.value
  //     );
  // };

  const handleorderpreparationtimeChange = (time) => {
    formik.setFieldValue("prepration_time", JSON.stringify(time));
  };

  console.log("FORMIK VALUES", formik.values);

  // const onChanged: TimePickerProps["onChange"] = (time, timeString) => {
  //   console.log("FFFFF", time, timeString);
  // };

  const handleTimeChange = (fieldName) => (time, timeString) => {
    // Update the formik value when time is selected
    let value = document.getElementById("open_time").value;
    formik.setFieldValue(fieldName, value);
    setrestricted(false);
  };

  return (
    <>
    {loading && <LoadingSpinner/>}
      <form onSubmit={handleSubmit} className="h-120vh overflow-x-hidden">
        <div className=" text-[20px] font-medium mt-6 mx-7">
          {t("shop_timings")}
        </div>

        {/* <div className="grid grid-cols-3 gap-x-4 mt-6 mx-7 ">
          <div className="w-[300px] md:w-full leading-[0px] ">
            <label className="text-[14px] text-[#5C5C5C]">
              {t("open_time")}
            </label>
            <TimePicker
              id="open_time"
              format="hh:mm A" // Use 12-hour format
              name="opening_time"
              onChange={handleTimeChange("opening_time")}
              // changeOnScroll={false}
              // autoFocus={false}
              className={`mt-4 border leading-none text-[#5C5C5C] text-sm rounded-md block w-full p-2 ${
                formik.touched.opening_time && formik.errors.opening_time
                  ? "border-red"
                  : "border-[#ccc]"
              }`}
              // onChange={(time, timeString) => {
              //   console.log("Time selected:", time);
              //   formik.setFieldValue("opening_time", timeString); // Set the value in 12-hour format
              //   setrestricted(false);
              // }}
              value={
                formik.values.opening_time
                  ? moment(formik.values.opening_time, "hh:mm A")
                  : null
              } // Convert to moment object
              required
            />
            {formik.errors.opening_time && (
              <div className=" text-red text-sm h-2 mt-0 ml-1">
                {formik.errors.opening_time}
              </div>
            )}
          </div>

          <div className="w-[300px] md:w-full leading-[0px] ">
            <label className="text-[14px] text-[#5C5C5C]">
              {t("close_time")}
            </label>
            <TimePicker
              format="hh:mm A"
              name="closing_time"
              className={`mt-4 border leading-none text-[#5C5C5C] text-sm rounded-md block w-full p-2 ${
                formik.touched.closing_time && formik.errors.closing_time
                  ? "border-red"
                  : "border-[#ccc]"
              }`}
              // onChange={onChanged}
              // onChange={(time, timeString) => {
              // formik.setFieldValue("closing_time", timeString);
              // setrestricted(false);
              // }}
              value={
                formik.values.closing_time
                  ? moment(formik.values.closing_time, "hh:mm A")
                  : null
              } // Convert to moment object
              required
            />
            {formik.errors.closing_time && (
              <div className=" text-red text-sm h-2 mt-0 ml-1">
                {formik.errors.closing_time}
              </div>
            )}
          </div>

          <div className="w-[300px] md:w-full leading-[0px] ">
            <label className="text-[14px] text-[#5C5C5C]">{t("offdays")}</label>
            <Select
              style={{ width: "100%", overflowY: "hidden" }}
              mode="multiple"
              className="h-10 mt-4 w-full text-md text-body !border-[#CCCCCC]  !text-[#5C5C5C]  "
              defaultValue={formik.values.off_day_ids}
              name="off_day_ids"
              onChange={handleOptionChange}
              options={offDayOptions}
              onBlur={formik.handleBlur}
              value={formik.values.off_day_ids}
              dropdownStyle={{ maxHeight: 200, overflowY: "auto" }}
              showArrow
              error={formik.errors.off_day_ids}
              touched={formik.touched.off_day_ids}
            />
            {formik.errors.off_day_ids && (
              <div className=" text-red text-sm h-2 mt-0 ml-1">
                {/* Select Off Days */}
        {/* {formik.errors.off_day_ids} */}
        {/* </div> */}
        {/* )} */}
        {/* {formik.errors.off_day_ids && <div className=" text-red text-sm h-2 mt-0 ml-1">{formik.errors.off_day_ids}</div>} */}
        {/* </div> */}
        {/* </div> */}
        <div className="grid grid-cols-3 gap-4 mt-10 mx-7">
          {!is_lahloob && (
            <div className="w-[360px] md:w-full leading-[0px] ">
              <label className="text-[14px] text-[#5C5C5C]">
                {t("prepartion_time")}{" "}
              </label>
              <div className="mt-4">
                <TimePicker
                  format="HH:mm" // Use 24-hour format
                  name="prepration_time"
                  className={`w-full border leading-none text-[#5C5C5C] text-sm rounded-md p-2 ${
                    formik.touched.prepration_time &&
                    formik.errors.prepration_time
                      ? "border-red"
                      : "border-[#ccc]"
                  }`}
                  onChange={(time, timeString) => {
                    formik.setFieldValue("prepration_time", timeString); // Set the value in HH:mm format
                    setrestricted(false);
                  }}
                  value={
                    formik.values.prepration_time
                      ? moment(formik.values.prepration_time, "HH:mm")
                      : null
                  } // Convert to moment object
                  required
                />
                {formik.errors.prepration_time && (
                  <div className="text-red text-sm h-2 mt-0 ml-1">
                    {formik.errors.prepration_time}
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="w-[300px] md:w-full leading-[0px] ">
            <label className="text-[14px] text-[#5C5C5C]">
              {t("min_order_value")}
            </label>

            <Input
              style={{
                WebkitAppearance: "none",
                MozAppearance: "textfield",
                paddingRight: "1rem",
              }}
              type="number"
              className={`mt-4 h-10 w-full bg-transparent text-[#5C5C5C]  text-sm text-body outline-none focus:outline-none  rounded-lg border p-3 font-[inherit] font-normal ${
                formik.errors.minimum_order_value
                  ? ""
                  : // "border-red" // Apply red border when touched or notification shown
                    ""
              } `}
              name="minimum_order_value"
              placeholder="Enter value in EGP"
              onChange={(val) => {
                formik.handleChange(val);
                setrestricted(false);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.minimum_order_value}
              // error={formik.errors.minimum_order_value}
              // touched={formik.touched.minimum_order_value}
            />
            {formik.errors.minimum_order_value && (
              <div className=" text-red text-sm h-2 mt-0 ml-1">
                {formik.errors.minimum_order_value}
              </div>
            )}
          </div>

          {/* <div className="w-[30px] md:w-full leading-[0px] ">
            <label className="text-[14px] text-[#5C5C5C]">
              {t("enable_online")}
            </label>
            <Select
              defaultValue=""
              className="h-10 mt-4 w-full text-md text-body  !border-[#CCCCCC]  !text-[#5C5C5C] "
              name="shop_close"
              onChange={(val) => {
                formik.setFieldValue("shop_close", val);
                setrestricted(false);
              }}
              options={[
                { value: 0, label: t("Enable") },
                {
                  value: 1,
                  label: t("Disable"),
                },
              ]}
              onBlur={formik.handleBlur}
              value={formik.values.shop_close}
              error={formik.errors.shop_close}
              touched={formik.touched.shop_close}
            />
            {formik.errors.shop_close && (
              <div className=" text-red text-sm h-2 mt-0 ml-1">
                {formik.errors.shop_close}
              </div>
            )}
          </div> */}
        </div>

        <div className="mt-12 mb-10 mx-7 flex gap-5">
          <button
            type="submit"
            className={`w-[180px] h-[45px] ${
              restricted ? "bg-primaryBluedisabled" : "bg-primaryBlue"
            } text-white rounded-lg font-semibold`}
            disabled={restricted}
          >
            {t("update_and_save")}
          </button>
          <Button
            className="w-[180px] h-[45px] text-primaryBlue font-semibold rounded-lg inline border border-primaryBlue"
            // onClick={ SetData()}
            onClick={() => {
              SetData();
            }}
          >
            {t("cancel")}
          </Button>
        </div>
      </form>
      {showUpdatedProfile && (
        <UpdatedProfile
          onClose={() => setShowUpdatedProfile(false)}
          message={t("Shop Details")}
        />
      )}
    </>
  );
};

export default ShopDetails;
