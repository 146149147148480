import axiosInstance from "../AxiosInstance";
import { URLS } from "../API/API endpoints";
import { store } from "../Redux/store";
import i18next from "i18next";



export const getCashbackDetails = async (payload) => {
  try {
    const response = await axiosInstance.post(URLS.CASHBACK_DETAILS, payload);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const getCashBackPaymentHistory = async (payload) => {
  try {
    const response = await axiosInstance.post(URLS.CASHBACK_PAYMENT_HISTORY, payload);
    console.log("GET_getCashBackPaymentHistory_HISTORY", response);

    return response.data;
  } catch (error) {
    return error;
  }
};

