// kpiReducer.js
import { SET_LAHLOOB_KPI_DATA } from "../Action/LahloobEOrdersDataAction";

const initialState = {
  kpiData: null, // Initial state for KPI data
};

const LahloobEorderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LAHLOOB_KPI_DATA:
      return {
        ...state,
        LahloobkpiData: action.payload, // Update the state with the new KPI data
      };
    default:
      return state;
  }
};

export default LahloobEorderReducer;