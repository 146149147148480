import React from 'react'
import SelectBox from "../CustomWidgets/SelectBox";
import { useFormik } from 'formik';
import Refresh from "../../assets/Dashboard/Refresh.svg"
import { Image } from 'antd';

const Dashboardthird = () => {
    const formik = useFormik({
        initialValues: {
          order: "",
        },
      });
  return (
    <>
    <div>
      <div className="float-left">
       <h1 className='text-3xl mt-5'>Welcome</h1>
      </div>
      <div className="grid grid-cols-2 gap-x-3 relative bottom-2 float-right w-80">
      {/* <Image src={Refresh} layout="fixed" width={30} height={30} className="absolute top-2 left-2" /> */}
        <h2 className='mt-9'> Refresh</h2>
        <SelectBox
          defaultValue="Today"
          name="businessprofile"
          onChange={formik.handleChange}
          options={[
            { value: "Retailer", label: "Retailer" },
            { value: "option 2", label: "option 2" },
          ]}
          onBlur={formik.handleBlur}
          value={formik.values.businessprofile}
          error={formik.errors.businessprofile}
          touched={formik.touched.businessprofile}
        />
      </div>
    </div>
  </>
  )
}

export default Dashboardthird

 
