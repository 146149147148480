import { createAsyncThunk } from "@reduxjs/toolkit";
import { URLS } from "../../API/API endpoints";
import axiosInstance from "../../AxiosInstance";


export const SendOTP = 

createAsyncThunk("SendOTP", async (payload) => {
  // console.log("API Request Payload:", payload)
  try {
    // console.log("API Request Payload:", payload)
    const response = await axiosInstance.post(URLS.SEND_OTP, payload);

    //

    // if(payload.page=="basic_details" && response.data.status==200)
    // {
    
    //  console.log("in basic details succes",response)
    //   localStorage.setItem("user_id",response.data.user_id)
    //   localStorage.setItem("store_id",response.data.store_id)
     
    // }
    

    return response.data;
  } catch (error) {
    console.error("API Request Error:", error);
    throw error;
  }
});

