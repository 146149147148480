import React, { useState } from "react";
import { useFormik } from "formik";
import { InputBox } from "../../components/CustomWidgets/InputBox";
import { Input } from "antd";
import { Image } from "antd";
import "react-phone-number-input/style.css";
import { routes } from "../../Routes";
import FlagIcon from "../../assets/egypt.svg";
import SuccessModal from "../../modals/Sucessmodal";
import "../../styles.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { addVendor } from "../../components/validationSchema";
import { createVendor } from "../../API  Functions/PurchaseFunctions";

const AddVendor = ({ isEdit, onClose }) => {
  const { t, i18n } = useTranslation();
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);

  const [showsucesspopup, setshowsucesspopup] = useState(false);
  const [showemail, setshowemail] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [Error, setError] = useState(0);

  const formik = useFormik({
    initialValues: {
      user_id: user_id,
      name: "",
      mobile: "",
      email: "",
      address: "",
    },
    validationSchema: addVendor,
    // validateOnChange: false,
    // validateOnBlur: false,

    onSubmit: async (values, { resetForm }) => {
      try {
        if (!isEdit) {
          const payload = {
            user_id: user_id,
            name: values.name,
            email: values.email.toLowerCase(),
            phone: values.mobile,
            address: values.address,
          };

          const res = await createVendor(payload);
          // console.log("CREATE Vendor RES", res);

          if (res?.data?.result?.status === "Vendor Created.") {
            // setmessage("User  Created Successfully");
            setshowsucesspopup(true);
            resetForm();
          } else if (
            res?.data?.result?.status_code === 400 &&
            res?.data?.result?.status === "Duplicate Email Not Allowed!"
          ) {
            setError(1);
          } else if (
            res?.data?.result?.status === "Duplicate Phone/Name Not Allowed!"
          ) {
            setError(2);
          }
        }
      } catch (error) {
        console.error("Error during submission:", error);
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className=" ">
          <div className="flex justify-between items-baseline">
            <div className="font-semibold text-xl mt-5">
              {t("Add New Vendor/Supplier")}{" "}
            </div>
            <div className="flex gap-5 ">
              <button
                onClick={() => onClose()}
                className="w-36 h-10 bg-white text-primaryBlue font-semibold rounded-lg inline border border-primaryBlue"
              >
                {t("cancel")}
              </button>
              <button
                type="submit"
                className={`w-36 h-10 rounded-lg font-semibold ${
                  formik.values.name.trim() === ""
                    ? "bg-primaryBlue text-white opacity-50 cursor-not-allowed"
                    : "bg-primaryBlue text-white"
                }`}
                disabled={formik.values.name.trim() === ""}
              >
                {t("save")}
              </button>
            </div>
          </div>
          <div className="md:w-full min-h-auto bg-[#FFFFFF] border rounded-xl mt-5">
            <div className="w-full h-10 bg-[#C9ECF7] px-4  flex items-center font-medium text-base rounded-tr-xl rounded-tl-xl text-[#272728]">
              {t("basic_details")}
            </div>
            <div className="flex justify-center mt-4 pb-4 gap-x-8 px-6">
              <div className="font-normal text-sm text-[#5C5C5C] flex flex-col gap-6 sm:flex-row sm:gap-7 sm:items-baseline sm:w-[75%]">
                <div className="w-full flex flex-col gap-4 leading-[0px]">
                  <label className="text-sm" style={{ marginBottom: -15, marginTop: 15 }}>
                    {t("Name")}
                  </label>
                  <InputBox
                    name="name"
                    placeholder={t("enter_fullname")}
                    className={`mt-2 ${
                      formik.errors.name
                        ? "border-red" // Apply red border when touched or there's an error
                        : ""
                    }`}
                    // onChange={formik.handleChange}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setHasChanges(true);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    touched={formik.touched.name}
                    error={formik.errors.name}
                    // required
                  />

                  <div className="flex flex-col text-[#5C5C5C] gap-1.5 mt-2">
                    <label>{t("Phone Number")}</label>
                    <div className="flex items-center ">
                      <div className="h-10 w-16 mt-4 text-md text-body outline-none focus:outline-none rounded-md border border-[#ccc] p-3 flex items-center justify-center">
                        <Image src={FlagIcon} alt="img" />
                      </div>

                      <Input
                        type="number"
                        // maxLength={11}
                        max={9999999999}
                        placeholder={t("Enter Number")}
                        international
                        defaultCountry="EG"
                        //   onChange={handleChange}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.length > 10) {
                            e.target.value = value.slice(0, 10);
                          }
                          formik.handleChange(e);
                          //   setHasChanges(true);
                        }}
                        value={formik.values.mobile}
                        onBlur={formik.handleBlur}
                        name="mobile"
                        className="h-10 w-100 ms-2 mt-4 text-[#5C5C5C] text-md text-body outline-none focus:outline-none ltr:pl-5 rtl:pr-5 rounded-md border p-3"
                      />
                    </div>
                    {formik.touched.mobile && formik.errors.mobile && (
                      <div className="text-xs text-red">
                        {formik.errors.mobile}
                      </div>
                    )}
                    {Error == 2 && (
                      <div className="text-xs text-red">
                        {" "}
                        {t("Phone Number already registered")}
                      </div>
                    )}
                  </div>
                </div>

                <div className="w-full flex flex-col gap-0 leading-[0px]">
                  {/* <label className="">{t("email")}</label>
                    <InputBox
                      name="email"
                      placeholder={t("enter_email")}
                      onChange={(val) => {
                        formik.handleChange(val);
                        // setHasChanges(true);
                        setshowemail(false);
                        setError(0);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      //   touched={formik.touched.email}
                      error={formik.errors.email}
                      // required
                      //   autoComplete="new-password"
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="text-xs text-red">
                        {formik.errors.email}
                      </div>
                    )}
                    {Error == 1 && (
                      <div className="text-xs text-red">
                        {" "}
                        {t("Email already registered")}
                      </div>
                    )} */}
                  <div>
                    <label className="text-sm">{t("Address")}</label>
                    <InputBox
                      name="address"
                      placeholder={t("Enter Address")}
                      onChange={(e) => {
                        formik.handleChange(e);
                        // setHasChanges(true);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                      className="mt-2"
                      //   touched={formik.touched.address}
                      //   error={formik.errors.address}
                      // required
                    />
                  </div>
                  <div className="flex flex-col mt-5">
                    <div>
                      <label className="text-sm">{t("email")}</label>
                      <InputBox
                        name="email"
                        placeholder={t("enter_email")}
                        onChange={(val) => {
                          formik.handleChange(val);
                          // setHasChanges(true);
                          setshowemail(false);
                          setError(0);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        //   touched={formik.touched.email}
                        error={formik.errors.email}
                        className=""
                        // required
                        //   autoComplete="new-password"
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className="text-xs text-red mt-2">
                          {formik.errors.email}
                        </div>
                      )}
                      {Error == 1 && (
                        <div className="text-xs text-red mt-2">
                          {" "}
                          {t("email_already_registered")}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {showsucesspopup && (
        <SuccessModal
          onClose={() => {
            setshowsucesspopup(false);
            onClose();
          }}
          state={isEdit ? true : false}
          message={` ${t("Vendor Added Successfully")}  `}
          route={routes.purchase.addBill}
        />
      )}
    </>
  );
};

export default AddVendor;
