import React, { useEffect, useState, useRef } from "react";
import {
  Breadcrumb,
  Button,
  Dropdown,
  Image,
  Select,
  AutoComplete,
} from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import { routes } from "../../../Routes";
import {
  BarcodeInputBox,
  InputBox,
} from "../../../components/CustomWidgets/InputBox";
import { useFormik, isInteger } from "formik";
import "../../../styles.css";
import styles from "./autocomplete.module.css";
import {
  InventorySearchonboard,
  InventorySearchonboardBarcode,
} from "../../../API  Functions/InventoryFunctions";
import { debounce } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
// import Autocomplete from "react-autocomplete";
import { InventoryOnboardSchema } from "../../../components/validationSchema";
import BarcodeScanner from "react-barcode-reader";
import InventoryOnboardPopup from "../../../Popups/InventoryOnboardPopup";
import Navbar from "../../../components/Navbar/Navbar";
import { useSelector } from "react-redux";
import axiosInstance from "../../../AxiosInstance";
import { URLS } from "../../../API/API endpoints";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { toast } from "react-toastify";
import AddBarcode from "../../../modals/AddBarcodeModal";
import barcodeIcon from "../../../assets/Inventory/barcode_icon.svg";
import LoadingSpinner from "../../../components/Loading";

const InventoryOnboard = () => {
  const [value, setValue] = useState("");
  const [requestProduct, setrequestProduct] = useState(false);
  const [Productoptions, setProductoptions] = useState([]);
  const [ProductImage, setProductImage] = useState("");
  const [ProductName, setProductName] = useState("");
  const [availableQty, setAvailableQty] = useState(0); // Step 1: State for available quantity
  const [productUom, setProductUom] = useState(""); // Step 1: State for available quantity
  const [prodUpdateState, setProdUpdateState] = useState(""); // Step 1: State for available quantity
  const [isProductSelected, setIsProductSelected] = useState(false);
  const [addBarcode, setAddBarcode] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [isAddBarcodeModalOpen, setIsAddBarcodeModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [popup, setpopup] = useState(false);
  const [message, setmessage] = useState(" ");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isProductFound, setIsProductFound] = useState(false); // Step 1: State to track if product is found
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const company_id = useSelector(
    (state) => state?.login?.user?.result?.company_id
  );

  // console.log("VALLLLL", value);

  const searchResult = debounce(async (value) => {
    let res = await InventorySearchonboard(value);
    // console.log("API Response:", res);

    if (res.result && Array.isArray(res.result)) {
      const productResponse = res.result[0];
      if (productResponse.status_code === 400) {
        setrequestProduct(true);
        setProductoptions([]);
        // setValue("");
      } else {
        setProductoptions(res.result);
        setrequestProduct(false);
      }
    } else {
      setProductoptions([]);
      setrequestProduct(false);
    }
  }, 500);

  const searchBarcodeResult = debounce(async (value) => {
    let res = await InventorySearchonboardBarcode(value);
    // console.log("API Response Barcode:", res);

    if (res.result && Array.isArray(res.result)) {
      const productResponse = res.result[0];
      // console.log("PPPPPp", productResponse);

      if (productResponse.status_code === 400) {
        // If product not found, try fetching from GS1 API
        try {
          const fetchGtinResponse = await axiosInstance.post(
            "/fetch_gs1_data",
            {
              gtin: value, // Use the barcode value
            }
          );

          if (fetchGtinResponse.data && fetchGtinResponse.data.result) {
            const fetchedProduct = fetchGtinResponse.data.result;

            // Check if the fetched product has the necessary data
            if (fetchedProduct.gtin) {
              // Redirect to ProductRequest page with fetched product data
              navigate("/inventory/ProductRequest", {
                state: {
                  fetchedProduct,
                  // product_description_english: fetchedProduct.product_description_english,
                  // product_description_arabic: fetchedProduct.product_description_arabic,
                  // barcode: fetchedProduct.gtin,
                },
              });
            } else {
              // Show the Request Product option if no product found
              setrequestProduct(true);
              setProductoptions([]);
              setValue("");
              setIsProductFound(false);
            }
          } else {
            // Handle case where no result is returned
            setrequestProduct(true);
            setProductoptions([]);
            setValue("");
            setIsProductFound(false);
          }
        } catch (error) {
          console.error("Error fetching GS1 data:", error);
          setrequestProduct(true); // Show Request Product option on error
        }
      } else {
        // Fill out the input fields with the found product data
        formik.setFieldValue("barcode", productResponse.barcode);
        setProductImage(productResponse.image);
        setValue(productResponse.name);
        setProductName(productResponse.name);
        setAvailableQty(productResponse.available_qty);
        setProductUom(productResponse.uom);
        // console.log("PPP", productResponse);

        if (productResponse?.cost_price > 0) {
          formik.setFieldValue("cost_price", productResponse.cost_price);
        }

        if (productResponse?.sale_price > 0) {
          formik.setFieldValue("sale_price", productResponse.sale_price);
        }

        setIsProductFound(true); // Mark product as found
      }
    }
  }, 500);
  const handleScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    const itemHeight = 60;
    const index = Math.floor(scrollTop / itemHeight);
    setScrollPosition(index);
  };

  const formik = useFormik({
    initialValues: {
      // name: "",
      product_id: "",
      barcode: "",
      category: "",
      cost_price: "",
      sale_price: "",
      qty: "",
      machine_barcode: "",
    },

    validationSchema: InventoryOnboardSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const submitProductData = async () => {
    setLoading(true);
    const { barcode, qty, machine_barcode } = formik.values;

    const cost_price = formik.values.cost_price
      ? Number(formik.values.cost_price)
      : 0;
    const sale_price = formik.values.sale_price
      ? Number(formik.values.sale_price)
      : 0;

    const product_name = ProductName || "";
    const product_image = ProductImage || "";
    const totalQuantity =
      availableQty > 0 ? Number(qty) + availableQty : Number(qty);

    if (sale_price < cost_price) {
      toast.error("Sale price cannot be less than cost price");
      setLoading(false);
      // setpopup(true);
      // setmessage("Sale Price cannot be less than Cost Price.");
      return; // Exit the function if validation fails
    }

    if (!barcode) {
      setAddBarcode(true);
      setLoading(false);
      return;
    }
    if (!qty || !ProductName) {
      setLoading(false);
      // setpopup(true);
      // setmessage("Please fill in all required fields: Quantity, Cost Price, and Sale Price.");
      return; // Exit the function if validation fails
    }

    const payload = {
      barcode: barcode || "",
      quantity: totalQuantity,
      product_name,
      cost_price: cost_price ? Number(cost_price) : 0,
      sales_price: sale_price ? Number(sale_price) : 0,
      company_id,
      product_image,
      machine_barcode: machine_barcode || false,
    };

    try {
      const response = await axiosInstance.post(URLS.ADD_PRODUCT, payload);
      // console.log("API RESPONSE", response);

      if (response?.data?.result?.status === "Product Created.") {
        setpopup(true);
        setmessage("Inventory Updated Successfully");
        formik.resetForm();
        setLoading(false);
        setProductImage("");
        setProductName("");
        setValue("");
        setAvailableQty(0);
        setProductUom("");
        setIsProductSelected(false);
        setProdUpdateState("");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      setpopup(true);
      setmessage("Error submitting data. Please try again.");
    }
  };

  const handleBarcodeClick = () => {
    // console.log("CHAAAA");
    // console.log("Name", ProductName);
    // console.log("Barcoe", formik.values.barcode);

    if (ProductName && !formik.values.barcode) {
      setAddBarcode(true);
    }
  };

  const isSaveDisabled = !ProductName || !formik.values.barcode; // Check if product name or barcode is empty

  // console.log("availableQty", availableQty);

  return (
    <>
      {loading && <LoadingSpinner />}

      <Navbar heading="Add Products" />
      <div className="bg-[#F6F7F9]">
        <div className="flex flex-col ">
          <div className="mr-4 ml-4 mt-14 font-extrabold">
            {/* <Breadcrumb
              separator={<DoubleRightOutlined />}
              items={[
                { title: "Inventory", to: routes.Inventory },
                { title: "Inventory Onboard" },
              ]}
              className="relative top-3"
            /> */}

            {/* <Breadcrumb
              separator={<DoubleRightOutlined />}
              items={[
                {
                  title: t("inventory"),
                  key: "inventory",
                  children: (
                    <Link to={routes.inventory.root}>{t("inventory")}</Link>
                  ),
                },
                { title: t("Inventory Onboard") },
              ]}
              className="relative mt-3"
            /> */}

            <Breadcrumb
              separator={<DoubleRightOutlined />}
              items={[
                {
                  title: (
                    <Link to={routes.inventory.root}>{t("inventory")}</Link>
                  ),
                  key: "inventory",
                },
                { title: t("Inventory Onboard") },
              ]}
              className="relative mt-3"
            />
          </div>
          <div className="flex flex-row items-end justify-start">
            <div className="flex flex-row justify-end relative bottom-6  w-180 mt-4 me-4"></div>
          </div>
          <div className="mx-4 h-12 bg-[#C9ECF7] rounded-t-xl">
            <p className="mt-3 ms-4">{t("Product Details")}</p>
          </div>
          <div className="flex flex-row   bg-white mx-4 border rounded-b-xl">
            <div className="m-2 w-2/4 p-4 ">
              <span className="relative">
                <label htmlFor="default-search" className="sr-only">
                  Product
                </label>
                <div>
                  <AutoComplete
                    value={value}
                    options={Productoptions.map((option) => ({
                      value: option.name,
                      label: option.name,
                    }))}
                    onSearch={(value) => {
                      if (!isProductSelected) {
                        setValue(value);
                        if (value.length >= 3) {
                          searchResult(value);
                        } else {
                          setrequestProduct(false);
                          setProductoptions([]);
                        }
                      }
                    }}
                    onSelect={(value) => {
                      const selectedProduct = Productoptions.find(
                        (product) => product.name === value
                      );

                      if (selectedProduct?.barcode === false) {
                        setAddBarcode(true);
                        setIsAddBarcodeModalOpen(true);
                        setSelectedProductId(selectedProduct.prod_id);
                      }
                      setValue(selectedProduct?.name);
                      setProductImage(selectedProduct?.image);
                      setProductName(selectedProduct?.name);
                      setAvailableQty(selectedProduct?.available_qty);
                      formik.setFieldValue("barcode", selectedProduct?.barcode);
                      formik.setFieldValue("prod_id", selectedProduct?.prod_id);
                      setProductUom(selectedProduct?.uom);
                      if (selectedProduct?.sale_price == 0.0) {
                        formik.setFieldValue("sale_price", "");
                      } else if (selectedProduct?.sale_price) {
                        formik.setFieldValue(
                          "sale_price",
                          selectedProduct?.sale_price
                        );
                      }
                      if (selectedProduct?.cost_price == 0.0) {
                        formik.setFieldValue("cost_price", "");
                      } else if (selectedProduct?.cost_price) {
                        formik.setFieldValue(
                          "cost_price",
                          selectedProduct?.cost_price
                        );
                      }

                      setProdUpdateState(selectedProduct?.updated);
                      setIsProductSelected(true);
                      setProductoptions([]);
                    }}
                    style={{ width: "100%", height: "60px" }}
                    placeholder={t("search_product")}
                    readOnly={isProductSelected}
                    disabled={isAddBarcodeModalOpen}
                  />
                  {value && (
                    <button
                      type="button"
                      onClick={() => {
                        setValue(""); // Clear the input value
                        setProductoptions([]); // Clear the product options
                        setValue(null);
                        setProductImage(null);
                        setProductName(null);
                        setAvailableQty(null);
                        setProdUpdateState("");
                        setIsProductSelected(false);
                        formik.setFieldValue("barcode", "");
                        formik.setFieldValue("prod_id", "");
                        formik.setFieldValue("cost_price", "");
                        formik.setFieldValue("sale_price", "");
                        formik.setFieldValue("qty", "");
                      }}
                      className={`absolute ${
                        i18n.language === "ar" ? "left-2" : "right-2"
                      } top-2 text-gray-500 hover:text-gray-700`}
                      // className="absolute right-2 top-2 text-gray-500 hover:text-gray-700"
                    >
                      &times;
                    </button>
                  )}
                </div>
              </span>
              <BarcodeScanner
                onScan={(data) => {
                  console.log("isAddBarcodeModalOpen", isAddBarcodeModalOpen);

                  if (!isAddBarcodeModalOpen) {
                    // Check if the modal is not open
                    console.log("BArcode O Data", data);
                    setValue(data);
                    searchBarcodeResult(data);
                  }
                }}
                onError={(err) => {
                  console.error(err);
                }}
                disabled={isAddBarcodeModalOpen}
                facingMode="environment"
                className="absolute inset-y-0 right-0 me-4 pointer-events-none cursor-pointer z-10"
              />
              {requestProduct && (
                <Link
                  to="/inventory/ProductRequest"
                  style={{ color: "blue", textDecoration: "underline" }}
                  className="flex flex-col items-end text-sm"
                >
                  {t("Request Product")}{" "}
                </Link>
              )}
              <form onSubmit={formik.handleSubmit}>
                <span className="block leading-[0px]">
                  {/* <label className="text-sm">{t("barcode")}</label> */}
                  <BarcodeInputBox
                    label={t("barcode")}
                    type="number"
                    className={`${formik.errors.barcode ? "border-red" : ""}`}
                    name="barcode"
                    barcodeIcon={barcodeIcon}
                    placeholder={t("barcode")}
                    onClick={handleBarcodeClick}
                    // max={9999999999999}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.barcode}
                    error={formik.errors.barcode}
                    touched={formik.errors.barcode}
                    disabled={
                      formik.values.barcode?.length >= 13 ||
                      isAddBarcodeModalOpen
                    }
                    readOnly
                  />
                </span>
                <div className="flex flex-row gap-5 mt-1">
                  <span className="w-full leading-[0px]">
                    {/* <label className="text-sm">{t("quantity")}</label> */}
                    <InputBox
                      label={t("quantity")}
                      name="qty"
                      type="text" // Change to text to handle custom validation
                      className={`${formik.errors.qty ? "border-red" : ""}`}
                      placeholder={t("Enter Quantity")}
                      onChange={(event) => {
                        const { value } = event.target;

                        // Allow only valid input based on UOM
                        if (
                          (productUom !== "Units" &&
                            /^\d*\.?\d{0,2}$/.test(value)) || // Allow decimals for non-units
                          (productUom === "Units" && /^\d{0,5}$/.test(value)) // Only whole numbers for units
                        ) {
                          // Prevent multiple hyphens and other invalid characters
                          if (!/[^\d.]/.test(value) && !/(--)/.test(value)) {
                            formik.setFieldValue("qty", value);
                          }
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.qty}
                      error={formik.errors.qty}
                      touched={formik.errors.qty}
                      disabled={isAddBarcodeModalOpen}
                    />
                    {/* <InputBox
                      name="qty"
                      type="number"
                      className={`${
                        formik.errors.qty
                          ? "border-red" // Apply red border when touched or notification shown
                          : ""
                      }`}
                      placeholder={t("Enter Quantity")}
                      // onChange={formik.handleChange}
                      onChange={(event) => {
                        const { value } = event.target;

                        // Allow only numbers and restrict to 7 digits
                        // if (/^\d{0,5}$/.test(value)) {
                        //   formik.setFieldValue("qty", value);
                        // }
                        if (
                          (productUom !== "Units" &&
                            /^\d*\.?\d{0,2}$/.test(value)) ||
                          (productUom === "Units" && /^\d{0,5}$/.test(value))
                        ) {
                          formik.setFieldValue("qty", value);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.qty}
                      error={formik.errors.qty}
                      touched={formik.errors.qty}
                    /> */}
                    {/* {availableQty > 0 && (
                      <p className="text-sm mt-1 italic text-red">
                        {t("Available Quantity:")} {availableQty}
                      </p>
                    )} */}

                    {prodUpdateState === "Already Updated" ? (
                      availableQty === 0 ? ( // Check if availableQty is 0
                        <p className="text-sm mt-1 italic text-red">
                          {t("Available Quantity: 0")}
                        </p>
                      ) : (
                        <p className="text-sm mt-1 italic text-primaryBlue">
                          {/* {t("Inventory already updated for this product.")}
                          <br /> */}
                          <p className="text-sm mt-0 italic text-red">
                            {t("Available Quantity:")} {availableQty}
                          </p>
                        </p>
                      )
                    ) : availableQty > 0 ? (
                      <p className="text-sm mt-1 italic text-red">
                        {t("Available Quantity:")} {availableQty}
                      </p>
                    ) : null}
                  </span>
                  {productUom === "KG" && (
                    <span className="w-full leading-[0px]">
                      {/* <label className="text-sm">{t("Machine Barcode")}</label> */}
                      <InputBox
                        label={t("Machine Barcode")}
                        name="machine_barcode"
                        type="number"
                        className={`${
                          formik.errors.machine_barcode ? "border-red" : ""
                        }`}
                        placeholder="Enter Machine Barcode"
                        onChange={(event) => {
                          const { value } = event.target;

                          // Allow only numbers and restrict to 7 digits
                          if (/^\d{0,7}$/.test(value)) {
                            formik.setFieldValue("machine_barcode", value);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.machine_barcode}
                        error={formik.errors.machine_barcode}
                        touched={formik.errors.machine_barcode}
                        disabled={isAddBarcodeModalOpen}
                      />
                    </span>
                  )}
                </div>
                <div className="flex flex-row gap-5 mt-1 mb-5">
                  <span className="w-[50%] leading-[0px]">
                    {/* <label className="text-sm">{t("cost_price")}</label> */}
                    <InputBox
                      label={t("cost_price")}
                      className={`${
                        formik.errors.cost_price ? "border-red" : ""
                      }`}
                      name="cost_price"
                      type="text"
                      placeholder={t("Enter Cost Price")}
                      onChange={(event) => {
                        const { value } = event.target;

                        // Allow only valid decimal numbers and restrict to 7 digits total
                        if (
                          value === "" || // Allow empty input
                          /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(value) // Allow numbers with up to 6 digits before decimal and 2 after
                        ) {
                          // Prevent multiple hyphens and other invalid characters
                          if (!/[^\d.]/.test(value) && !/(--)/.test(value)) {
                            formik.setFieldValue("cost_price", value);
                          }
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.cost_price}
                      error={formik.errors.cost_price}
                      touched={formik.errors.cost_price}
                      disabled={isAddBarcodeModalOpen}
                    />
                  </span>
                  <span className="inline-block w-[50%] leading-[0px]">
                    {/* <label className="text-sm">{t("sale_price")}</label> */}
                    <InputBox
                      label={t("sale_price")}
                      name="sale_price"
                      type="text"
                      // className={`${
                      //   formik.errors.sale_price ? "border-red" : ""
                      // }`}
                      placeholder={t("Enter Sale Price")}
                      onChange={(event) => {
                        const { value } = event.target;

                        // Allow only valid decimal numbers and restrict to 7 digits total
                        if (
                          value === "" || // Allow empty input
                          /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(value) // Allow numbers with up to 6 digits before decimal and 2 after
                        ) {
                          // Prevent multiple hyphens and other invalid characters
                          if (!/[^\d.]/.test(value) && !/(--)/.test(value)) {
                            formik.setFieldValue("sale_price", value);
                          }
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.sale_price}
                      error={formik.errors.sale_price}
                      disabled={isAddBarcodeModalOpen}

                      // touched={formik.errors.sale_price}
                    />
                  </span>
                </div>
                <div className="flex gap-5 mt-8  ">
                  <button
                    type="submit"
                    onClick={submitProductData}
                    className={`w-48 h-12 bg-primaryBlue text-white text-sm font-semibold rounded-lg flex items-center justify-center gap-2 ${
                      isSaveDisabled ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={isSaveDisabled}
                    // className="w-48 h-12 bg-primaryBlue text-white text-sm font-semibold rounded-lg flex items-center justify-center gap-2"
                  >
                    <span>{t("Save & Add more")}</span>
                  </button>
                  <Button
                    type="button"
                    className="w-[180px] h-12 text-primaryBlue text-sm font-bold rounded-lg  border-primaryBlue"
                    onClick={() => {
                      formik.resetForm();
                      setValue("");
                      setProductoptions([]);
                      setProductImage();
                      setAvailableQty("");
                      setProductUom("");
                      setProdUpdateState("");
                      setIsProductSelected(false);
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                </div>
              </form>
            </div>
            <div className="m-2 p-4 ">
              <span
                //  className="flex items-center justify-center w-72 h-72 border rounded-xl  relative top-7 left-20">
                className={`flex items-center justify-center w-72 h-72 border rounded-xl relative top-7 ${
                  i18next.language === "ar" ? "right-20" : "left-20"
                }`}
              >
                {ProductImage ? (
                  <Image
                    style={{ maxHeight: 280, maxWidth: 280 }}
                    src={ProductImage}
                    height={280}
                    width={280}
                    alt="Image"
                    objectFit="contain"
                    className="rounded-xl"
                  />
                ) : (
                  <svg
                    className=" float-left h-10 w-12 text-gray-300"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                    />
                  </svg>
                )}
              </span>
              <div className="flex gap-3 relative top-10 left-20"></div>
            </div>
          </div>
        </div>
        <AddBarcode
          isOpen={addBarcode}
          onClose={() => {
            setAddBarcode(false);
            // setIsAddBarcodeModalOpen(false);
          }}
          prod_id={selectedProductId}
          onBarcodeAdded={(newBarcode) => {
            formik.setFieldValue("barcode", newBarcode); // Automatically fill the barcode field
            setAddBarcode(false); // Close the modal after adding the barcode
            setIsAddBarcodeModalOpen(false);
          }}
          setIsAddBarcodeModalOpen={setIsAddBarcodeModalOpen}
          // onBarcodeAdded={handleBarcodeAdded}
        />
        {popup && (
          <InventoryOnboardPopup
            message={message}
            onClose={() => setpopup(false)}
          />
        )}
      </div>
    </>
  );
};

export default InventoryOnboard;
