import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { InputBox } from "../../components/CustomWidgets/InputBox";
import upload from "../../assets/Images/Uploadminimalistic.svg";
// import {Image}  from "antd";
import axiosInstance from "../../AxiosInstance";
import Notification from "../../modals/Notification";
import UpdatedProfile from "../../modals/UpdatedProfile"; // Import the UpdatedProfile modal
import UploadMediaonly from "../upload-imageonly";
import { Button, Image } from "antd";
import "../../styles.css";
import {
  BusinessProofSchema,
  profileBusinessProofSchema,
} from "../validationSchema";
import { useNavigate } from "react-router";
import { URLS } from "../../API/API endpoints";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  GetRetailerDocs,
  UpdateRetailerDetails,
} from "../../API  Functions/ProfileFunctions";
import UploadMedia from "../upload-media";
import UploadPDF from "../upload-pdf";

const BusinessProof = ({ payload, onDataSubmit, data }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [notification, setnotification] = useState(false);
  const [showUpdatedProfile, setShowUpdatedProfile] = useState(false);
  const [restricted, setrestricted] = useState(true);
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);
  const [base64Images, setBase64Images] = useState([]);
  const [uploadedPdf, setuploadedPdf] = useState([]);

  // const company_id = useSelector((state) => state?.login?.user?.result?.company_id);

  useEffect(() => {
    formik.setValues({
      commercial_num: data?.retailer_details?.company_registry || "",
      tax_card_num: data?.retailer_details?.tax_card_number || "",
      national_id: data?.retailer_details?.manager_id || "",
    });
    setBase64Images([]);
    fetchRetailerDocs();
  }, [data, user_id]);

  const fetchRetailerDocs = async () => {
    try {
      const response = await GetRetailerDocs(user_id);
      // console.log("DOCUMENTS response", response);
      if (response?.status_code === 200) {
        const documents = response?.response;

        // Initialize an object to map filenames to their corresponding documents
        // const initialValues = {
        //   commercial_num_url: "",
        //   tax_card_num_url: "",
        //   national_id_url: "",
        // };

        // console.log("DOCUMENTS", documents);
        setBase64Images([]);

        const addedFilenames = new Set();

        documents.forEach((doc) => {
          if (
            doc.document &&
            [
              "commercial_registry_certificate",
              "tax_card",
              "national_id",
            ].includes(doc.filename)
          ) {
            setBase64Images((prev) => [
              ...prev,
              {
                filename: doc.filename,
                attachment: doc.document,
              },
            ]);
          }
        });
      }
    } catch (error) {
      console.error("Error fetching retailer documents:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      commercial_num: data?.retailer_details?.company_registry || "",
      commercial_num_url: "",
      tax_card_num: data?.retailer_details?.tax_card_number || "",
      tax_card_num_url: "",
      national_id: data?.retailer_details?.manager_id || "",
      national_id_url: "",
    },
    // validationSchema: profileBusinessProofSchema,
    onSubmit: async (values, { resetForm }) => {
      const apipayload = {
        user_id: user_id,
        manager_id: values.national_id,
        tax_card_number: values.tax_card_num,
        company_registry: values.commercial_num,
      };

      try {
        if (uploadedPdf.length > 0) {
          // console.log("uploadedPdf.length", uploadedPdf.length);

          for (const file of uploadedPdf) {
            if (file.attachment) {
              const uploadPayload = {
                user_id: user_id,
                filename: file.filename,
                attachment: file.attachment,
              };
              // console.log("uploadPayload", uploadPayload);

              const uploadResponse = await axiosInstance.post(
                URLS.UPLOAD_RETAILER_DOCUMENTS,
                uploadPayload
              );
              // console.log("uploadResponse", uploadResponse);

              if (uploadResponse.status !== 200) {
                throw new Error("Failed to upload document: " + file.filename);
              }
            }
          }
        }

        const response = await UpdateRetailerDetails(apipayload);
        if (response?.status === "Update Successfully") {
          setnotification(true);
          setrestricted(true);
          setShowUpdatedProfile(true);
        }
      } catch (error) {}

      // resetForm();
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    formik.handleSubmit(e);
  };

  const handleurlgenerated = (values, keyname) => {
    setrestricted(false);
    formik.setFieldValue(keyname, values.url);

    setBase64Images((prev) => {
      const updatedImages = [...prev];
      const index = updatedImages.findIndex((img) => img.filename === keyname);

      if (index !== -1) {
        updatedImages[index] = { filename: keyname, attachment: values.url };
      } else {
        updatedImages.push({ filename: keyname, attachment: values.url });
      }
      return updatedImages;
    });
  };
  const commercialFiles = base64Images.filter(
    (img) => img.filename === "commercial_registry_certificate"
  );
  const nationalIdFiles = base64Images.filter(
    (img) => img.filename === "national_id"
  );
  const taxCardFiles = base64Images.filter(
    (img) => img.filename === "tax_card"
  );
  // console.log("commercialFiles  PROOF", commercialFiles);
  // console.log("nationalIdFiles  PROOF", nationalIdFiles);

  // console.log("base64Images BUSINESS PROOF", base64Images);
  // console.log("uploaded PDF BUSINESS PROOF", uploadedPdf);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="font-normal text-sm text-[#5c5c5c] grid grid-rows-2 grid-flow-col  gap-4 gap-x-9 mx-7 mt-6">
          <div className="w-[320px]  leading-[0px]">
            {/* <label> {t("commercial_registry_number")}</label> */}
            <InputBox
              name="commercial_num"
              label={t("commercial_registry_number")}
              placeholder="000000000"
              maxLength={14}
              onChange={(val) => {
                formik.handleChange(val);
                setrestricted(false);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.commercial_num}
              error={formik.errors.commercial_num}
              touched={formik.touched.commercial_num}
            />
            {formik.errors.commercial_num && (
              <div className="text-red text-xs">
                {formik.errors.commercial_num}
              </div>
            )}
            {formik.values.commercial_num_url !== "" &&
              formik.values.commercial_num === "" && (
                <div className="text-red text-xs">
                  {("Please enter Commercial Registry Number")}{" "}
                </div>
              )}
            <div className="w-[320px] mt-2">
              <div className="flex flex-col rounded-lg border border-gray-900/25 px-4 py-3 bg-white shadow-lg">
                {/* <h3 className="text-lg font-semibold mb-3 text-gray-800">
                  Commercial Registry Certificate
                </h3> */}
                <div className="flex flex-wrap justify-center gap-3 mb-4">
                  {commercialFiles.length > 0 ? (
                    commercialFiles.map((file, index) => (
                      <div
                        key={index}
                        className="flex items-center  justify-between p-2 py-4 bg-gray-100 rounded-md shadow-sm"
                      >
                        <a
                          href={`data:application/pdf;base64,${file.attachment}`}
                          download={`commercial_registry_certificate_${
                            index + 1
                          }.pdf`}
                          className="text-blue-500 font-medium hover:underline"
                        >
                          {t("View PDF")} {index + 1}
                        </a>
                        {/* <span className="text-gray-600 text-xs">
                          {new Date().toLocaleDateString()}
                        </span> */}
                      </div>
                    ))
                  ) : (
                    <div className="flex items-center justify-center w-full h-16 bg-gray-200 rounded-md">
                      <svg
                        className="h-10 w-10 text-gray-400"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                        />
                      </svg>
                      <span className="text-gray-500 ms-2">{t("No files uploaded")}</span>
                    </div>
                  )}
                </div>
                <div className="flex justify-center mt-4">
                  <label
                    htmlFor="file-upload"
                    className="flex items-center cursor-pointer rounded-md bg-white  inline border border-primaryBlue py-2 px-4 text-white font-semibold hover:bg-gray-100 transition duration-200"
                  >
                    <UploadPDF
                      foldername={"store_app_UAT"}
                      onfileAdd={"commercial_registry_certificate"}
                      filename={"commercial_registry_certificate"}
                      setBase64Images={setBase64Images}
                      setuploadedPdf={setuploadedPdf}
                      urlgenerated={handleurlgenerated}
                      imageurl={formik.values.commercial_num_url}
                      message={(message) => !message}
                    />
                    {/* <span className="ml-2">Add More</span> */}
                  </label>
                </div>
              </div>
            </div>

            {/* <div className="w-[363px] md:w-full  mt-2">
              <div className="flex flex-row rounded-lg border border-inset border-gray-900/25 px-2  py-3">
                <div
                  className="flex flex-row"
                  style={{ minHeight: 70, maxHeight: 70 }}
                >
                  {commercialFiles.length > 0 ? (
                    commercialFiles.map((file, index) => (
                      <a
                        key={index}
                        href={`data:application/pdf;base64,${file.attachment}`}
                        download={`commercial_registry_certificate_${
                          index + 1
                        }.pdf`}
                        className="ml-4 text-blue-500 text-nowrap"
                      >
                        View PDF
                        {index + 1}
                      </a>
                    ))
                  ) : (
                    <svg
                      className="float-left mt-3 h-10 w-12 text-gray-300"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                      />
                    </svg>
                  )}
                  <div class="flex flex-row text-sm leading-6 pl-20 ml-10 text-gray-600">
                    <label
                      for="file-upload"
                      class="flex items-center relative cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                    >
                      <UploadPDF
                        foldername={"store_app_UAT"}
                        onfileAdd={"commercial_registry_certificate"}
                        filename={"commercial_registry_certificate"}
                        setBase64Images={setBase64Images}
                        setuploadedPdf={setuploadedPdf}
                        urlgenerated={handleurlgenerated}
                        imageurl={formik.values.commercial_num_url}
                        message={(message) => !message}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          <div className="w-[320px] leading-[0px] mt-2">
            {/* <label> {t("managerid")}</label> */}
            <InputBox
              label={t("managerid")}
              name="national_id"
              placeholder="000000000"
              maxLength={14}
              onChange={(val) => {
                formik.handleChange(val);
                setrestricted(false);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.national_id}
              error={formik.errors.national_id}
              touched={formik.errors.national_id}
            />
            {formik.errors.national_id && (
              <div className="text-red text-xs">
                {formik.errors.national_id}
              </div>
            )}
            {formik.values.national_id_url !== "" &&
              formik.values.national_id === "" && (
                <div className="text-red text-xs">
                  {t("Please enter National ID")}{" "}
                </div>
              )}
            <div className="w-[320px] mt-2">
              <div className="flex flex-col rounded-lg border border-gray-900/25 px-4 py-3 bg-white shadow-lg">
                {/* <h3 className="text-lg font-semibold mb-3 text-gray-800">
                  Commercial Registry Certificate
                </h3> */}
                <div className="flex flex-wrap justify-center gap-3 mb-4">
                  {nationalIdFiles.length > 0 ? (
                    nationalIdFiles.map((file, index) => (
                      <div
                        key={index}
                        className="flex items-center  justify-between p-2 py-4 bg-gray-100 rounded-md shadow-sm"
                      >
                        <a
                          href={`data:application/pdf;base64,${file.attachment}`}
                          download={`commercial_registry_certificate_${
                            index + 1
                          }.pdf`}
                          className="text-blue-500 font-medium hover:underline"
                        >
                          {("View PDF")} {index + 1}
                        </a>
                        {/* <span className="text-gray-600 text-xs">
                          {new Date().toLocaleDateString()}
                        </span> */}
                      </div>
                    ))
                  ) : (
                    <div className="flex items-center justify-center w-full h-16 bg-gray-200 rounded-md">
                      <svg
                        className="h-10 w-10 text-gray-400"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                        />
                      </svg>
                      <span className="text-gray-500 ms-2">{("No files uploaded")}</span>
                    </div>
                  )}
                </div>
                <div className="flex justify-center mt-4">
                  <label
                    htmlFor="file-upload"
                    className="flex items-center cursor-pointer rounded-md bg-white  inline border border-primaryBlue py-2 px-4 text-white font-semibold hover:bg-gray-100 transition duration-200"
                  >
                    <UploadPDF
                        foldername={"store_app_UAT"}
                        onfileAdd={"national_id"}
                        filename={"national_id"}
                        urlgenerated={handleurlgenerated}
                        setBase64Images={setBase64Images}
                        setuploadedPdf={setuploadedPdf}
                        imageurl={formik.values.national_id}
                        message={(message) => !message}
                      />
                    {/* <span className="ml-2">Add More</span> */}
                  </label>
                </div>
                </div>
            </div>
          </div>

          <div className="w-[320px]  leading-[0px]">
            {/* <label> {t("taxcard_number")}</label> */}
            <InputBox
              label={t("taxcard_number")}
              name="tax_card_num"
              placeholder="000000000"
              maxLength={9}
              onChange={(val) => {
                formik.handleChange(val);
                setrestricted(false);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.tax_card_num}
              error={formik.errors.tax_card_num}
              touched={formik.touched.tax_card_num}
            />
            {formik.errors.tax_card_num && (
              <div className="text-red text-xs">
                {formik.errors.tax_card_num}{" "}
              </div>
            )}
            {formik.values.tax_card_num_url !== "" &&
              formik.values.tax_card_num === "" && (
                <div className="text-red text-xs">
                  {("Please enter Tax card number")}{" "}
                </div>
              )}
           <div className="w-[320px] mt-2">
              <div className="flex flex-col rounded-lg border border-gray-900/25 px-4 py-3 bg-white shadow-lg">
                {/* <h3 className="text-lg font-semibold mb-3 text-gray-800">
                  Commercial Registry Certificate
                </h3> */}
                <div className="flex flex-wrap justify-center gap-3 mb-4">
                  {taxCardFiles.length > 0 ? (
                    taxCardFiles.map((file, index) => (
                      <div
                      key={index}
                      className="flex items-center  justify-between p-2 py-4 bg-gray-100 rounded-md shadow-sm"
                    >
                      <a
                        href={`data:application/pdf;base64,${file.attachment}`}
                        download={`commercial_registry_certificate_${
                          index + 1
                        }.pdf`}
                        className="text-blue-500 font-medium hover:underline"
                      >
                        {t("View PDF")} {index + 1}
                      </a>
                      {/* <span className="text-gray-600 text-xs">
                        {new Date().toLocaleDateString()}
                      </span> */}
                    </div>
                  ))
                ) : (
                  <div className="flex items-center justify-center w-full h-16 bg-gray-200 rounded-md">
                    <svg
                      className="h-10 w-10 text-gray-400"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                      />
                    </svg>
                    <span className="text-gray-500 ms-2">{t("No files uploaded")}</span>
                  </div>
                )}
              </div>
              <div className="flex justify-center mt-4">
                <label
                  htmlFor="file-upload"
                  className="flex items-center cursor-pointer rounded-md bg-white  inline border border-primaryBlue py-2 px-4 text-white font-semibold hover:bg-gray-100 transition duration-200"
                >
                  <UploadPDF
                        foldername={"store_app_UAT"}
                        onfileAdd={"tax_card_num_url"}
                        urlgenerated={handleurlgenerated}
                        filename={"tax_card"}
                        setBase64Images={setBase64Images}
                        setuploadedPdf={setuploadedPdf}
                        imageurl={formik.values.tax_card_num_url}
                        message={(message) => !message}
                      />
                  {/* <span className="ml-2">Add More</span> */}
                </label>
              </div>
              </div>
          </div>
          </div>
        </div>

        <div className=" flex gap-5 mx-7 mt-6 mb-5">
          <button
            type="submit"
            className={`w-[180px] h-[45px] ${
              restricted ? "bg-primaryBluedisabled" : "bg-primaryBlue"
            } text-white rounded-lg font-semibold `}
            disabled={restricted}
          >
            {t("update_and_save")}
          </button>
          <Button
            className="w-[180px] h-[45px] text-primaryBlue font-semibold rounded-lg inline border border-primaryBlue"
            // onClick={getbusinessproofdata}
            onClick={() => {
              formik?.setValues({
                commercial_num: data?.retailer_details?.company_registry || "",
                tax_card_num: data?.retailer_details?.tax_card_number || "",
                national_id: data?.retailer_details?.manager_id || "",
              });
              fetchRetailerDocs();
              // setBase64Images([]);
            }}
          >
            {t("cancel")}
          </Button>
        </div>
      </form>
      {notification && (
        <Notification
          message={t("Business Proof  Details Updated Sucessfully")}
          onClose={() => setnotification(false)}
        />
      )}
      {showUpdatedProfile && (
        <UpdatedProfile
          onClose={() => setShowUpdatedProfile(false)}
          message={t("Business Proof Details")}
        />
      )}
    </>
  );
};

export default BusinessProof;
