import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "../../styles.css";

export const InputBox = ({ label, error, touched, backgroundColor, ...props }) => {
  return (
    <div className="flex flex-col mt-2">
      {label && <label className="py-2 text-sm text-gray-600  font-medium">{label}</label>}
      <input
        {...props}
        className={`h-10 ${
          label ? "" : ""
        } w-full ${backgroundColor || "bg-transparent"} text-sm text-body outline-none focus:outline-none ltr:pl-5 rtl:pr-5 rounded-md border p-3 font-[inherit] font-normal ${
          touched && error
            ? "border-red" // Apply red border when touched or notification shown
            : "border-[#ccc]"
        } `}
        style={{
          WebkitAppearance: "none", // Remove default spinner appearance (Chrome/Safari)
          MozAppearance: "textfield", // Firefox spinner removal
          paddingRight: "1rem",
        }}
      />
      {/* {touched && error ? (
        <div className="font-medium italic text-red ">{error}</div>
      ) : null} */}
    </div>
  );
};

export const BarcodeInputBox = ({ label, error, touched, barcodeIcon, ...props }) => {
  return (
    <div className="flex flex-col ">
      {label && <label className="py-2 text-sm text-gray-600 font-medium">{label}</label>}
      <div className="relative"> {/* Container for input and icon */}
        <input
          {...props}
          className={`h-10 ${
            label ? "" : ""
          } w-full bg-gray-100 text-sm text-body outline-none focus:outline-none ltr:pl-5 rtl:pr-5 rounded-md border p-3 font-[inherit] font-normal ${
            touched && error
              ? "border-red" // Apply red border when touched or notification shown
              : "border-[#ccc]"
          } `}
          style={{
            WebkitAppearance: "none", // Remove default spinner appearance (Chrome/Safari)
            MozAppearance: "textfield", // Firefox spinner removal
            paddingRight: "2.5rem", // Add padding to the right for the icon
          }}
        />
        {barcodeIcon && ( // Display the barcode icon if provided
          <img
            src={barcodeIcon} // Use the provided barcode icon
            alt="Barcode Icon"
            className="absolute right-3 top-1/2 mt-1 transform -translate-y-1/2 w-5 h-5" // Adjust size and position
          />
        )}
      </div>
      {/* {touched && error ? (
        <div className="font-medium italic text-red ">{error}</div>
      ) : null} */}
    </div>
  );
};

export const Password = ({
  label,
  show,
  del,
  DeliveryBoyData,
  loc,
  error,
  touched,
  ...props
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleMouseOver = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseOut = () => {
    setIsTooltipVisible(false);
  };

  return (
    <div className="flex flex-col mt-2">
      {label && <label className="py-2 text-md text-black font-medium">{label}</label>}
      <div className="relative">
        <input
          {...props}
          type={isPasswordVisible ? "text" : "password"}
          className={`h-10 w-full bg-transparent text-md text-body outline-none focus:outline-none pl-5 pr-10 rounded-md border p-3 ${
            touched && error ? "border-red" : "border-[#ccc]"
          } `}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        />

        {isTooltipVisible && show != 0 && (
          <div
            className={`absolute z-10 p-1  text-xs text-[#6e6e6e] bg-[#ffffff] border border-x-gray-500 rounded shadow-lg`}
          >
            <p>
              <span className=" text-red">*</span> 1 Uppercase
            </p>
            <p>
              <span className=" text-red">*</span> 1 Lowercase
            </p>
            <p>
              <span className=" text-red">*</span> 1 Number
            </p>
            <p>
              <span className=" text-red">*</span> 1 Special character (@#$%&*)
            </p>
            <p>
              <span className=" text-red">*</span> Minimum length: 6 characters
            </p>
          </div>
        )}
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className={`absolute right-3 ${
            loc == "0"
              ? del == "0"
                ? DeliveryBoyData != undefined
                  ? "bottom-1.5"
                  : "bottom-9"
                : "bottom-1.5"
              : "top-[49%]"
          }  transform -translate-y-1/2 text-gray-600`}
        >
          {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
        </button>
      </div>
    </div>
  );
};
