import React from "react";
import { useFormik } from "formik";
import { Password } from "../../components/CustomWidgets/InputBox";
import { NewPasswordSchema } from "../../components/validationSchema";
import { useTranslation } from "react-i18next";

const NewPassword = ({ phone, onDataSubmit }) => {
  const {t,i18n}=useTranslation()
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: NewPasswordSchema,
    onSubmit: (values) => {
      const payload = {
        mobile: phone,
        password: values.password,
      };
      onDataSubmit("New Password", payload);
    },
  });
  return (
    <div className=" w-[75%] mx-auto">
      <h1 className="text-lg font-bold text-center mb-1">{t('reset_password')}</h1>
      <p className="text-center text-sm font-normal text-[#5C5C5C] mb-2">
      {t('reset_password_note')}
      </p>
      <form onSubmit={formik.handleSubmit}>
        <div className=" text-sm mb-5">
          <Password
            label={t('Password')}
            type="password"
            name="password"
            placeholder={t('Enter Password')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={formik.errors.password}
            touched={formik.touched.password}
            secureTextEntry
          />
          <Password
            label={t('confirm_password')}
            type="password"
            name="confirmPassword"
            placeholder={t('enter_confirm_password')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            error={formik.errors.confirmPassword}
            touched={formik.touched.confirmPassword}
            secureTextEntry
          />
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 mt-4 text-white bg-primaryBlue font-semibold rounded-lg "
        >
         {t('confirm')}
        </button>
      </form>
    </div>
  );
};

export default NewPassword;
