import React, { useMemo, useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import "tailwindcss/tailwind.css";
import CustomPagination from "../../CustomWidgets/CustomPagination";
import { Empty } from "antd";
// import { ArrowRightOutlined } from "@ant-design/icons";
import arrowright from "../../../assets/arrowright.svg";
import Vectorsorting from "../../../assets/Vectorsorting.svg";
// import { Image } from "antd";
import { useNavigate } from "react-router";
import ShimmerLoader from "../../../loading";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const LahloobInProcess = ({ user_id, store_id, tabledata, pagination }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [flag, setflag] = useState(false);
  const is_lahloob = useSelector(
    (state) => state.login?.user?.result?.is_lahloob
  );

  // console.log("TABLE  DATA _------>", tabledata);

  const data = useMemo(() => {
    if (Array.isArray(tabledata)) {
      return tabledata;
    } else if (tabledata && typeof tabledata === "object") {
      return [];
    }
    return [];
  }, [tabledata]);

  useEffect(() => {
    if (data.length === 0 && !flag) {
      const timer = setTimeout(() => {
        setflag(true);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [data, flag]);

  // const openOrderList = (orderNumber) => {
  //   // console.log("order_number",orderNumber);

  //   navigate(`/Orderlist?orderDetail=${orderNumber}`);
  //   // navigate(`/Orderlist`, { state: { orderDetail: rowData } });
  // };

  // const columns = useMemo(
  //   () => [
  //     {
  //       Header: t("order_no"),
  //       accessor: "order_details.order_no",
  //       Cell: ({ value }) => (
  //         <>
  //           <button
  //             // className="bg-[#7EC242] text-white w-28 h-10 rounded-lg"
  //             className=" text-[#1b4497] rounded-lg"
  //             onClick={() => openOrderList(value)}
  //           >
  //             {value || "N/A"}
  //             {/* {value} */}
  //           </button>
  //           {/* )
  //         } */}
  //         </>
  //       ),
  //     },
  //     { Header: t("customer_name"), accessor: "order_details.partner_name" },
  //     {
  //       Header: t("order_date_time"),
  //       accessor: (row) => `${row.order_details.order_date}`,
  //       Cell: ({ value }) => (
  //         <span>
  //           {value?.substring(0, 10).replace(/-/g, "/")}{" "}
  //           {value.substring(11, 13) == 12
  //             ? value.substring(11, 16) + " PM"
  //             : value.substring(11, 13) > 12
  //             ? value.substring(11, 13) - 12 + value.substring(13, 16) + " PM"
  //             : value.substring(11, 16) + " AM"}
  //         </span>
  //       ),
  //     },
  //     ...(is_lahloob
  //       ? []
  //       : [
  //           {
  //             Header: t("delivery_schedule"),
  //             accessor: "order_details.delivery_schedule",
  //           },
  //         ]),
  //     {
  //       Header: t("Payment"),
  //       accessor: "order_details.payment_mode",
  //       Cell: ({ value }) => `${t(value)}`,
  //     },
  //     {
  //       Header: t("Target Delivery Time"),
  //       accessor: "order_details.target_delivery_time",
  //       Cell: ({ value }) => `${value} ${t("Mins")}`,
  //     },

  //     {
  //       Header: t("Total Delivery Time"),
  //       accessor: "order_details.total_delivery_time", // Assuming this is the delivered time
  //       Cell: ({ row }) => {
  //         const orderPlacedTime = dayjs(
  //           row.original.order_details.order_placed_time
  //         ); // Parse order placed time
  //         const deliveredTime = dayjs(
  //           row.original.order_details.total_delivery_time
  //         ); // Parse delivered time

  //         // Calculate the difference in minutes
  //         const totalDeliveryTimeInMinutes = deliveredTime.diff(
  //           orderPlacedTime,
  //           "minute"
  //         );

  //         // Check if the calculated time is a valid number
  //         if (isNaN(totalDeliveryTimeInMinutes)) {
  //           return null; // or return <span></span> to render an empty space
  //         }

  //         return (
  //           <div className="flex justify-start">
  //             {" "}
  //             {/* Left alignment */}
  //             <span>{totalDeliveryTimeInMinutes} Mins</span>{" "}
  //             {/* Display total delivery time in minutes */}
  //           </div>
  //         );
  //       },
  //     },
  //     // {
  //     //   Header: t("Total Delivery Time"),
  //     //   accessor: "order_details.total_delivery_time",
  //     //   Cell: ({ value }) => `${value}`,
  //     // },

  //     {
  //       Header: t("order_status"),
  //       accessor: "order_details.order_status",
  //       Cell: ({ value }) => {
  //         let backgroundColor,
  //           textColor,
  //           borderRadius,
  //           paddingTop,
  //           paddingBottom,
  //           paddingLeft,
  //           paddingRight;

  //         // Conditionally set styles based on order status
  //         // if (value === "Order Pending") {
  //         //   backgroundColor = "#999999";
  //         // } else if (value === "In Processing") {
  //         //   backgroundColor = "#f1c232  ";
  //         // } else if (value === "Ready For Delivery") {
  //         //   backgroundColor = "#93c47d";
  //         // } else if (value === "Out For Delivery") {
  //         //   backgroundColor = "#f1c232";
  //         // } else if (value === "Order Delivered") {
  //         //   backgroundColor = "#1b4497";
  //         // } else {
  //         //   backgroundColor = "#E74C3C";
  //         // }

  //         if (value === "Order Pending") {
  //           backgroundColor = "#999999";
  //         } else if (value === "In Processing") {
  //           backgroundColor = "#FFEBC5";
  //           textColor = "#E8A700";
  //         } else if (value === "Ready For Delivery") {
  //           backgroundColor = "#D1FFDE";
  //           textColor = "#00B833";
  //         } else if (value === "Out For Delivery") {
  //           // backgroundColor = "#f1c232";
  //           backgroundColor = "#FFEBC5";
  //           textColor = "#E8A700";
  //         } else if (value === "Order Delivered") {
  //           // backgroundColor = "#1b4497";
  //           backgroundColor = "#D1E1FF";
  //           textColor = "#006BB8";
  //         } else {
  //           // backgroundColor = "#E74C3C";
  //           backgroundColor = "#FFD1D1";
  //           textColor = "#EF3A3A";
  //         }

  //         // Padding values for top, bottom, left, right
  //         // textColor = "#ffffff";
  //         paddingTop = "8px";
  //         paddingBottom = "8px";
  //         paddingLeft = "1px";
  //         paddingRight = "1px";
  //         borderRadius = "10px";

  //         return (
  //           <h4
  //             style={{
  //               backgroundColor,
  //               color: textColor,
  //               borderRadius,
  //               padding: `${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft}`,
  //               fontWeight: 500,
  //               display: "inline-block",
  //               minWidth: "90px",
  //               alignItems: "center",
  //               textAlign: "center",
  //             }}
  //           >
  //             {(() => {
  //               switch (value) {
  //                 case "Order Pending":
  //                   return t("Pending");
  //                 case "In Processing":
  //                   return t("Processing");
  //                 case "Ready For Delivery":
  //                   return t("Ready");
  //                 case "Out For Delivery":
  //                   return t("Out");
  //                 case "Order Delivered":
  //                   return t("Delivered");

  //                 default:
  //                   return t("Cancelled");
  //               }
  //             })()}
  //           </h4>
  //         );
  //       },
  //     },
  //     {
  //       Header: t("Order Placed Time"),
  //       accessor: "order_details.order_placed_time",
  //       Cell: ({ value }) => `${value}`,
  //     },
  //     {
  //       Header: t("Ready For Delivery Time"),
  //       accessor: "order_details.ready_for_delivery_time",
  //       Cell: ({ value }) => `${value}`,
  //     },
  //     {
  //       Header: t("Out For Delivery Time"),
  //       accessor: "order_details.out_for_delivery_time",
  //       Cell: ({ value }) => `${value}`,
  //     },
  //     {
  //       Header: t("Delivery Time"),
  //       accessor: "order_details.delivery_time",
  //       Cell: ({ value }) => `${value}`,
  //     },
  //     {
  //       Header: t("Delivery Boy Name"),
  //       accessor: "order_details.delivery_boy_name",
  //       Cell: ({ value }) => `${value}`,
  //     },
  //     {
  //       Header: t("Ordered Quantity"),
  //       accessor: "order_details.ordered_quantity",
  //       Cell: ({ value }) => `${value}`,
  //     },
  //     {
  //       Header: t("Delivered Quantity"),
  //       accessor: "order_details.delivered_quantity",
  //       Cell: ({ value }) => `${value}`,
  //     },
  //     {
  //       Header: t("Total Delivery Item Value"),
  //       accessor: "order_details.total_delivery_item_value",
  //       Cell: ({ value }) => `EGP ${value.toFixed(2)}`,
  //     },
  //     {
  //       Header: t("MGI Surcharge"),
  //       accessor: "order_details.mgi_surcharge",
  //       Cell: ({ value }) => `EGP ${value.toFixed(2)}`,
  //     },
  //     {
  //       Header: t("MGI Platform Fee"),
  //       accessor: "order_details.mgi_platform_fee",
  //       Cell: ({ value }) => `EGP ${value.toFixed(2)}`,
  //     },
  //     {
  //       Header: t("MGI Delivery Fee"),
  //       accessor: "order_details.shipping_charges",
  //       Cell: ({ value }) => `EGP ${value.toFixed(2)}`,
  //     },
  //     {
  //       Header: t("MGI Discount"),
  //       accessor: "order_details.mgi_discount",
  //       Cell: ({ value }) => `EGP ${value.toFixed(2)}`,
  //     },
  //     {
  //       Header: t("Total Collection"),
  //       accessor: "order_details.total_collection",
  //       Cell: ({ value }) => `EGP ${value.toFixed(2)}`,
  //     },
  //     {
  //       Header: t("MGI Comm."),
  //       accessor: "order_details.mgi_commission",
  //       Cell: ({ value }) => `EGP ${value.toFixed(2)}`,
  //     },
  //     {
  //       Header: t("Store Collection"),
  //       accessor: "order_details.store_collection",
  //       Cell: ({ value }) => `EGP ${value.toFixed(2)}`,
  //     },
  //     {
  //       Header: t("MGI Collection"),
  //       accessor: "order_details.mgi_collection",
  //       Cell: ({ value }) => `EGP ${value.toFixed(2)}`,
  //     },
  //     // {
  //     //   Header: t("Order Cancelled Time"),
  //     //   accessor: "order_details.cancelled_datetime",
  //     //   Cell: ({ value }) => `${value}`,
  //     // },
  //     // {
  //     //   Header: t("Retailer Cancel Reason"),
  //     //   accessor: "order_details.retailer_cancel_reason",
  //     //   Cell: ({ value }) => `${value}`,
  //     // },
  //     // {
  //     //   Header: t("Customer Reason"),
  //     //   accessor: "order_details.customer_cancel_reason",
  //     //   Cell: ({ value }) => `${value}`,
  //     // },

  //     {
  //       id: "action",
  //       Header: "",
  //       accessor: (row) =>
  //         `${row.customer_details?.mobile1}, ${row?.order_number},${row?.order_details.order_status}`,
  //       Cell: ({ row }) => (
  //         <>
  //           <button
  //             className=" text-[#1b4497] rounded-lg"
  //             onClick={() => openOrderList(row.original.order_details.order_no)}
  //           >
  //             <img
  //               src={arrowright}
  //               alt="arrowright"
  //               className={`${i18n.language == "en" ? "" : " rotate-180"}`}
  //               height={3}
  //               width={20}
  //             />
  //           </button>
  //           {/* )
  //           } */}
  //         </>
  //       ),
  //     },
  //   ],
  //   []
  // );

  const openOrderList = (orderNumber) => {
    navigate(`/Orderlist?orderDetail=${orderNumber}`);
  };

  const columns = useMemo(() => {
    const cancelColumns = [
      {
        Header: t("Order Cancelled Time"),
        accessor: "order_details.cancelled_datetime",
        Cell: ({ value }) => `${value}`,
      },
      {
        Header: t("Retailer Cancel Reason"),
        accessor: "order_details.retailer_cancel_reason",
        Cell: ({ value }) => `${value}`,
      },
      {
        Header: t("Customer Reason"),
        accessor: "order_details.customer_cancel_reason",
        Cell: ({ value }) => `${value}`,
      },
    ];

    const baseColumns = [
      {
        Header: t("order_no"),
        accessor: "order_details.order_no",
        Cell: ({ value }) => (
          <button
            className="text-[#1b4497] rounded-lg"
            onClick={() => openOrderList(value)}
          >
            {value || "N/A"}
          </button>
        ),
      },
      { Header: t("customer_name"), accessor: "order_details.partner_name" },
      {
        Header: t("order_date_time"),
        accessor: (row) => `${row.order_details.order_date}`,
        Cell: ({ value }) => (
          <span>
            {value?.substring(0, 10).replace(/-/g, "/")}{" "}
            {value.substring(11, 13) == 12
              ? value.substring(11, 16) + " PM"
              : value.substring(11, 13) > 12
              ? value.substring(11, 13) - 12 + value.substring(13, 16) + " PM"
              : value.substring(11, 16) + " AM"}
          </span>
        ),
      },
      ...(is_lahloob
        ? []
        : [
            {
              Header: t("delivery_schedule"),
              accessor: "order_details.delivery_schedule",
            },
          ]),
      {
        Header: t("Payment"),
        accessor: "order_details.payment_mode",
        Cell: ({ value }) => `${t(value)}`,
      },
      {
        Header: t("Target Delivery Time"),
        accessor: "order_details.target_delivery_time",
        Cell: ({ value }) => `${value} ${t("Mins")}`,
      },
      {
        Header: t("Total Delivery Time"),
        accessor: "order_details.total_delivery_time",
        Cell: ({ row }) => {
          const orderPlacedTime = dayjs(
            row.original.order_details.order_placed_time
          );
          const deliveredTime = dayjs(
            row.original.order_details.total_delivery_time
          );
          const totalDeliveryTimeInMinutes = deliveredTime.diff(
            orderPlacedTime,
            "minute"
          );

          if (isNaN(totalDeliveryTimeInMinutes)) {
            return null;
          }

          return (
            <div className="flex justify-start">
              <span>{totalDeliveryTimeInMinutes} Mins</span>
            </div>
          );
        },
      },
      {
        Header: t("order_status"),
        accessor: "order_details.order_status",
        Cell: ({ value }) => {
          let backgroundColor, textColor, borderRadius, paddingTop, paddingBottom, paddingLeft, paddingRight;

          if (value === "Order Pending") {
            backgroundColor = "#999999";
          } else if (value === "In Processing") {
            backgroundColor = "#FFEBC5";
            textColor = "#E8A700";
          } else if (value === "Ready For Delivery") {
            backgroundColor = "#D1FFDE";
            textColor = "#00B833";
          } else if (value === "Out For Delivery") {
            backgroundColor = "#FFEBC5";
            textColor = "#E8A700";
          } else if (value === "Order Delivered") {
            backgroundColor = "#D1E1FF";
            textColor = "#006BB8";
          } else {
            backgroundColor = "#FFD1D1";
            textColor = "#EF3A3A";
          }

          paddingTop = "8px";
          paddingBottom = "8px";
          paddingLeft = "1px";
          paddingRight = "1px";
          borderRadius = "10px";

          return (
            <h4
              style={{
                backgroundColor,
                color: textColor,
                borderRadius,
                padding: `${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft}`,
                fontWeight: 500,
                display: "inline-block",
                minWidth: "90px",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {(() => {
                switch (value) {
                  case "Order Pending":
                    return t("Pending");
                  case "In Processing":
                    return t("Processing");
                  case "Ready For Delivery":
                    return t("Ready");
                  case "Out For Delivery":
                    return t("Out");
                  case "Order Delivered":
                    return t("Delivered");
                  default:
                    return t("Cancelled");
                }
              })()}
            </h4>
          );
        },
      },
      {
        Header: t("Order Placed Time"),
        accessor: "order_details.order_placed_time",
        Cell: ({ value }) => `${value}`,
      },
      {
        Header: t("Ready For Delivery Time"),
        accessor: "order_details.ready_for_delivery_time",
        Cell: ({ value }) => `${value}`,
      },
      {
        Header: t("Out For Delivery Time"),
        accessor: "order_details.out_for_delivery_time",
        Cell: ({ value }) => `${value}`,
      },
      {
        Header: t("Delivery Time"),
        accessor: "order_details.delivery_time",
        Cell: ({ value }) => `${value}`,
      },
      {
        Header: t("Delivery Boy Name"),
        accessor: "order_details.delivery_boy_name",
        Cell: ({ value }) => `${value}`,
      },
      {
        Header: t("Ordered Quantity"),
        accessor: "order_details.ordered_quantity",
        Cell: ({ value }) => `${value}`,
      },
      {
        Header: t("Delivered Quantity"),
        accessor: "order_details.delivered_quantity",
        Cell: ({ value }) => `${value}`,
      },
      {
        Header: t("Total Delivery Item Value"),
        accessor: "order_details.total_delivery_item_value",
        Cell: ({ value }) => `EGP ${value.toFixed(2)}`,
      },
      {
        Header: t("MGI Surcharge"),
        accessor: "order_details.mgi_surcharge",
        Cell: ({ value }) => `EGP ${value.toFixed(2)}`,
      },
      {
        Header: t("MGI Platform Fee"),
        accessor: "order_details.mgi_platform_fee",
        Cell: ({ value }) => `EGP ${value.toFixed(2)}`,
      },
      {
        Header: t("MGI Delivery Fee"),
        accessor: "order_details.shipping_charges",
        Cell: ({ value }) => `EGP ${value.toFixed(2)}`,
      },
      {
        Header: t("MGI Discount"),
        accessor: "order_details.mgi_discount",
        Cell: ({ value }) => `EGP ${value.toFixed(2)}`,
      },
      {
        Header: t("Total Collection"),
        accessor: "order_details.total_collection",
        Cell: ({ value }) => `EGP ${value.toFixed(2)}`,
      },
      {
        Header: t("MGI Comm."),
        accessor: "order_details.mgi_commission",
        Cell: ({ value }) => `EGP ${value.toFixed(2)}`,
      },
      {
        Header: t("Store Collection"),
        accessor: "order_details.store_collection",
        Cell: ({ value }) => `EGP ${value.toFixed(2)}`,
      },
      {
        Header: t("MGI Collection"),
        accessor: "order_details.mgi_collection",
        Cell: ({ value }) => `EGP ${value.toFixed(2)}`,
      },
      {
        id: "action",
        Header: "",
        accessor: (row) =>
          `${row.customer_details?.mobile1}, ${row?.order_number},${row?.order_details.order_status}`,
        Cell: ({ row }) => (
          <button
            className=" text-[#1b4497] rounded-lg"
            onClick={() => openOrderList(row.original.order_details.order_no)}
          >
            <img
              src={arrowright}
              alt="arrowright"
              className={`${i18n.language == "en" ? "" : " rotate-180"}`}
              height={3}
              width={20}
            />
          </button>
        ),
      },
    ];

    // Conditionally add cancellation-related columns
    if (data.some(row => row.order_details.order_status === "Order Cancelled")) {
      return [...baseColumns, ...cancelColumns];
    }

    return baseColumns;
  }, [data, t, is_lahloob, i18n.language]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  const [pageIndex, setpageIndex] = useState(1);
  useEffect(() => {
    const pageDetails = {
      limit: pageSize,
      offset: pageSize * (pageIndex - 1) < 0 ? 0 : pageSize * (pageIndex - 1),
    };
    pagination(pageDetails);
  }, [pageIndex, pageSize, previousPage, nextPage]);

  return (
    <div
      className="container mx-auto float-left h-[100%]"
      style={{ maxWidth: "100%" }}
    >
      <div className="flex flex-col h-full items-center">
        {tabledata?.length === 0 && flag === false ? (
          <div className="h-screen w-[90%] mt-2 ms-4">
            <ShimmerLoader />
          </div>
        ) : (
          <div className="overflow-x-auto scrollbar-thin w-full">
            {" "}
            {/* Add this wrapper for horizontal scrolling */}
            {page.length === 0 ? (
              <Empty className="mt-16" description={t("No orders found")} />
            ) : (
              <table
                {...getTableProps()}
                className="mt-7 font-normal ms-3 me-3 min-w-[800px] sm:w-[97.6%] md:w-[97.6%] lg:w-[97.6%] xl:w-[97.6%] min-h-auto h-[100%] shadow-xl rounded-lg"
                // className="mt-7 font-normal max-w-[100%] sm:w-[97.6%] md:w-[97.6%] lg:w-[97.6%] xl:w-[97.6%] min-h-auto h-[100%] shadow-xl rounded-lg overflow-hidden"
                style={{
                  direction: i18n.language === "ar" ? "rtl" : "ltr",
                }}
              >
                <thead>
                  {headerGroups.map((headerGroup, headerIndex) => {
                    const { key: headerKey, ...headerGroupProps } =
                      headerGroup.getHeaderGroupProps();
                    return (
                      <tr
                        key={headerKey}
                        {...headerGroupProps}
                        className="bg-[#78CFEC66] text-sm font-semibold"
                      >
                        {headerGroup.headers.map((column, colIndex) => {
                          const { key: columnKey, ...columnProps } =
                            column.getHeaderProps(
                              column.getSortByToggleProps()
                            );
                          return (
                            <th
                              key={columnKey}
                              {...columnProps}
                              className={`py-3 px-4 whitespace-nowrap ${
                                i18n.language === "ar"
                                  ? "text-right"
                                  : "text-left"
                              }`}
                            >
                              <div className="flex items-center justify-start">
                                {column.render("Header")}
                                <span>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <img
                                        src={Vectorsorting}
                                        style={{ minHeight: 10, minWidth: 10 }}
                                        alt="NA"
                                      />
                                    ) : (
                                      <img
                                        className="rotate-180"
                                        src={Vectorsorting}
                                        style={{ minHeight: 10, minWidth: 10 }}
                                        alt="NA"
                                      />
                                    )
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="bg-[#F7F9FD] text-left"
                >
                  {page.map((row, rowIndex) => {
                    prepareRow(row);
                    const { key: rowKey, ...rowProps } = row.getRowProps();
                    return (
                      <React.Fragment key={rowKey}>
                        <tr
                          {...rowProps}
                          className="relative hover:bg-white transition duration-300 border-b border-gray-300 cursor-pointer"
                          onClick={() =>
                            openOrderList(row.original.order_details.order_no)
                          }
                        >
                          {row.cells.map((cell, cellIndex) => {
                            const { key: cellKey, ...cellProps } =
                              cell.getCellProps();
                            return (
                              <td
                                key={cellKey}
                                {...cellProps}
                                className={`p-2 px-4 text-[#272728] text-sm font-normal ${
                                  i18n.language === "ar"
                                    ? "text-right"
                                    : "text-left"
                                }`}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      </React.Fragment>
                    );
                  })}
                  {page?.length === 0 && (
                    <tr>
                      <td colSpan={columns.length}>
                        <Empty description="No data available" />
                      </td>
                    </tr>
                  )}
                  {/* <tr>
                <td colSpan={columns.length}>
                  <CustomPagination
                    data={tabledata?.length}
                    pageIndex={pageIndex}
                    pageCount={page?.length}
                    canPreviousPage={pageIndex !== 1}
                    canNextPage={tabledata?.length >= 10}
                    gotoPage={(page) => nextPage(page)}
                    previousPage={() => setpageIndex(pageIndex - 1)}
                    nextPage={() => setpageIndex(pageIndex + 1)}
                    pageSize={pageSize}
                    setPageSize={(pageSize) => setPageSize(pageSize)}
                  />
                </td>
              </tr> */}
                </tbody>
              </table>
            )}
          </div>
        )}
         {page.length != 0 && (
        <div className="w-full">
          <CustomPagination
            data={tabledata?.length}
            pageIndex={pageIndex}
            pageCount={page?.length}
            canPreviousPage={pageIndex !== 1}
            canNextPage={tabledata?.length >= 10}
            gotoPage={(page) => nextPage(page)}
            previousPage={() => setpageIndex(pageIndex - 1)}
            nextPage={() => setpageIndex(pageIndex + 1)}
            pageSize={pageSize}
            setPageSize={(pageSize) => setPageSize(pageSize)}
          />
        </div>
         )}
      </div>
    </div>
  );
};

export default LahloobInProcess;
