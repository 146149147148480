import React from "react";
import {Image}  from "antd";
import one from "../../assets/Dashboard/DashboardInventory.svg";
import two from "../../assets/Order/two.svg";
import three from "../../assets/Order/three.svg";
import fourth from "../../assets/Order/fourth.svg";
import { Button } from "antd";

const Dashboardfourth = () => {
  const inventoryData = [
    { id: 1, productName: 'Product 1', totalSold: 10, price: 100, quantity: 50 },
    { id: 2, productName: 'Product 2', totalSold: 20, price: 150, quantity: 30 },
    { id: 3, productName: 'Product 3', totalSold: 15, price: 120, quantity: 40 },
  ];
  const data = {
    1: {
      img: one,
      heading: "Inventory",
      productsleft: "10",
      button:"Add Product",
      bgcolor: "#fbdada",
      textcolor: "#EB3C3C",
    },
    2: {
      img: two,
      heading: "Total Vendors",
      productsleft: "12",
      button:"Add Vendor",
      bgcolor: "#ffe5cd",
      textcolor: "#EB853C",
    },
   
  };
  return (
    <div className="flex flex-wrap rounded-lg  ml-3 mr-3">
      <div className="w-1/4 p-4">
      {Object.values(data).map((val,i) => (
        <div key={i} className="rounded-xl mb-4 mt-2 bg-[#ffffff] ">
                  
        <div className="flex  justify-left items-left ml-10 shadow-sm relative">  
        <Image src={one} layout="fixed" width={30} height={30} className="relative mt-5 left--10" />  
         <h1 className="ml-6  mt-5">{val.heading}</h1> 
        </div>
      
        <div className="flex mt-3 justify-center items-center">
        <h1> {val.productsleft} Products</h1> 
        </div>
        {/* Add more divs for other content as needed */}
    
      <div className="flex justify-center items-center">
     <Button className="w-52 h-10 border rounded-lg bg-[#1D5C96] text-white leading-8 mb-3 mt-5 mr-2">+{val.button}</Button>
     </div>
    </div>
      ))}
    {/* Second row
    <div>
    hello
    
    </div> */}
        {/* Takes up 33% width */}
        {/* Content for the first grid object */}
      </div>
      <div className="w-3/4 p-4 height-full">
      <div  className="rounded-xl min-h-[20.7rem]  mt-2 bg-[#ffffff] ">
      <div  class="flex justify-between items-center mt-1">
          <span class="text-left ml-5">
            <h1 class="text-xl">Low in Inventory</h1>
          </span>
          <span class="text-right mr-5">
            <h1 class="text-xl">View all</h1>
          </span>
        </div>
        <table className="w-full table-auto">
        <thead>
          <tr >
            <th className="text-grey pl-4 pr-10 py-2">Product</th>
            <th className="text-grey pl-10 pr-5 py-2">Total Sold</th>
            <th className="text-grey px-4 py-2">Price</th>
            <th className="text-grey px-4 py-2">Quantity</th>
            <th className="text-grey px-4 py-2">Action</th>
          </tr>
        </thead>
        <tbody>
          {/* Render inventory items dynamically */}
          {inventoryData.map((item) => (
            <tr key={item.id} className="border-b border-gray-200">
              <td className="pl-4 pr-10 py-2">{item.productName}</td>
              <td className="pl-10 pr-5 py-2">{item.totalSold}</td>
              <td className="px-4 py-2">${item.price}</td>
              <td className="px-4 py-2">{item.quantity}</td>
              <td className="px-4 py-2">
                <button className="bg-red-500 text-red px-3 py-1 rounded-lg">BUY</button>
                <button className="bg-red-500 text-white px-3 py-1 rounded-lg ml-2">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
        
        {/* Takes up 66% width */}
        {/* Content for the second grid object */}
      </div>
</div>
     
    </div>
  );
};

export default Dashboardfourth;
