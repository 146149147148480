import React, { useState, useCallback } from "react";
import upload from "../assets/Images/Uploadminimalistic.svg";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const UploadPDF = ({
  onfileAdd,
  urlgenerated,
  filename,
  setBase64Images,
  setuploadedPdf,
  multiple = false,
  message,
}) => {
  const { t } = useTranslation();
  const [fileError, setFileError] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const user_id = useSelector((state) => state.login.user.result.user_id);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.size > 5 * 1024 * 1024) { // Check for file size
          setSizeError(true);
        } else {
          setSizeError(false);
          const base64Data = reader.result.split(',')[1]; // Get the base64 part of the data URL
          if (file.type === "application/pdf") {
            // Create an object to hold the filename and base64 data
            const newFileData = { filename: filename, attachment: base64Data };
            // Update the base64Images state
            setBase64Images((prevImages) => [...prevImages, newFileData]);
            setuploadedPdf((prevImages) => [...prevImages, newFileData]);
            setFileError(false);
            // Optionally call urlgenerated if you want to pass the URL back
            urlgenerated(newFileData); // Call the urlgenerated function with the new file data
          } else {
            message(false);
            setFileError(true);
          }
        }
      };
      reader.onerror = (error) => {
        console.log("Error:", error);
      };
    });
  }, [setBase64Images, message, filename, urlgenerated, setuploadedPdf]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/pdf", // Accept only PDF files
    multiple,
    onDrop,
  });

  return (
    <div className="upload">
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <p className="flex flex-col justify-end text-center text-sm ">
          <span className="flex flex-row justify-center gap-1 items-center font-semibold text-[#1b4597]">
            <img src={upload} width={18} alt="Upload Icon" />
            {t("upload PDF file")}
          </span>
          <div className="">
            <span className="text-xs font-normal text-[#999999]">
             {t("Only PDF files are allowed")}
            </span>
            <div className="text-xs font-normal text-[#999999]">
              (max 5mb)
            </div>
          </div>
        </p>
      </div>
      {sizeError && (
        <p className="text-red text-xs mt-1">{t("File cannot be more than 5 mb")}</p>
      )}
      {fileError && (
        <p className="text-red text-xs mt-1">{t("Unsupported File Type")}</p>
      )}
    </div>
  );
};

export default UploadPDF;