import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { verifyOtpUser } from "../components/Actions/verifyOtpActions";
import TermsModal from "./termsandconditions";
import { signupActions } from "../components/Redux Slices/signupSlice";
import { signupUser } from "../components/Actions/signupActions";
import {Image} from "antd";
import Edit from "../assets/Edit.svg";
import { useTranslation } from "react-i18next";




const OTPModal = ({
  isOpen,
  onClose,
  responseData,
  numberChange,
  handlecancel,
}) => {
  const {t,i18n}=useTranslation();
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(30);
  const [otpValue, setOtpValue] = useState("");
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showIncorrectOTPMessage, setShowIncorrectOTPMessage] = useState(false);
  const [userPhoneno, setuserPhoneno] = useState("");

  const styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 999,
    },
    container: {
      width: "550px",
      padding: "20px",
      borderRadius: "30px",
      margin: "0 auto",
      backgroundColor: "#ffffff",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 1000,
    },
    title: {
      fontSize: "1.2rem",
      fontWeight: "bold",
      textAlign: "center",
      marginBottom: "4px",
      marginTop: "10px",
    },
    description: {
      textAlign: "center",
      fontSize: "0.9rem",
      color: "#5C5C5C",
      marginBottom: "20px",
    },
    otpInput: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "20px",
      marginTop: "40px",
    },
    otpInputField: {
      width: "40px",
      height: "40px",
      fontSize: "1.5rem",
      border: "1px solid #ccc",
      borderRadius: "7px",
      textAlign: "center",
      margin: "0 5px",
      outline: "none",
      color: "black",
    },
    resendText: {
      textAlign: "center",
      color: "#5C5C5C",
      fontSize: "0.875rem",
      marginBottom: "20px",
    },
    resendButton: {
      fontWeight: "bold",
      color: "#1b4597",
      cursor: "pointer",
    },
    verifyButton: {
      width: "60%",
      padding: "10px",
      backgroundColor: otpValue?.length<4?"#1b4497":"#1b4497",
      color: "white",
      borderRadius: "8px",
      cursor: "pointer",
      margin: "0 auto",
      display: "block",
      marginTop: "10px",
      marginBottom: "20px",
      fontWeight: "600",
    },
  };

  const handleResendOtp = async () => {
    console.log(" RESEND", responseData);
    let payload = {
      new_mobile: responseData.new_mobile,
      country_code: "+20",
      // user_type: responseData.meta.arg.user_type,
    };
    // console.log("HANDLE RESEND", payload);

    
    try {
      // Dispatch the signup request action
      dispatch(signupActions.signupRequest());

      // Dispatch the signupUser action
      const response = await dispatch(signupUser(payload));
      if (response) {
        setTimer(30);
        setOtpValue("");
      }

      // Check if the signupUser action was fulfilled successfully
      if (signupUser.fulfilled.match(response)) {
        // Dispatch the signup success action
        dispatch(signupActions.signupSuccess(response.payload));
      } else {
        // Dispatch the signup failure action with the error message
        dispatch(signupActions.signupFailure(response.payload));
      }
    } catch (error) {
      console.error("API request error:", error);
    }
  };

  useEffect(() => {
    let timerId;

    if (timer > 0) {
      timerId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [timer]);

  useEffect(() => {
    let timerId;

    if (timer > 0) {
      timerId = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [timer]);

  const formik = useFormik({
    initialValues: { otp: "" },
    // validationSchema:otpschema,
    onSubmit: async (values) => {
      // console.log("REPONSE DATA FORM LOGIN ",responseData);
      
      let payload = {
        new_mobile: responseData.new_mobile,
        otp: otpValue,
        country_code: "+20",
        // user_type: responseData.meta.arg.user_type,
      };
      try {
        //
        const response = await dispatch(verifyOtpUser(payload));

        // console.log("VERIFY RES----->", response);
        

        if (response.payload?.status === "OTP Verify successfully") {
          setuserPhoneno(responseData.new_mobile);
          setShowTermsModal(true);
        }
        if (response.payload?.status === "Otp Verified") {
          setuserPhoneno(responseData.new_mobile);
          setShowTermsModal(true);
        } else {
          setShowIncorrectOTPMessage(true);
        }
      } catch (error) {
        console.error("Error during OTP verification:", error);
      }
    },
  });

  const handleAcceptTerms = () => {
    setShowTermsModal(false);
    onClose();
   
  };

  return (
    <>
      {isOpen && (
        <>
          <div style={styles.overlay} onClick={onClose}></div>
          <div style={styles.container}>
            
            <div>
            <form  onSubmit={formik.handleSubmit}>
              <p style={styles.title}>{t('verify_phone_number')}</p>
              <div className="flex flex-row justify-center">
                <p style={styles.description}>
                {t('otp_sent_msg')}{" "}
                  0{responseData?.new_mobile}
                </p>
                <img
                  src={Edit}
                  style={{
                    cursor: "pointer",
                    // transform: "rotateY(180deg)",
                    marginTop: ".15rem",
                    height: "0.9rem",
                    width: "0.9rem",
                  }}
                  className="ml-2 mt--2 "
                  alt="Edit"
                  onClick={() => {
                    numberChange();
                  }}
                />
              </div>
              <div style={styles.otpInput}>
                
                <OtpInput
                  type="number"
                  inputType="numeric"
                  value={otpValue}
                  onChange={(otp) => {
                    setOtpValue(otp.replace(/\D/g, ""));
                    setShowIncorrectOTPMessage(false);
                  }}
                  numInputs={4}
                  placeholder="    "
                  separator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                  isInputSecure={true}
                  inputStyle={{
                    ...styles.otpInputField,
                    color: "black",
                    fontSize: "medium",
                  }}
                  className={`${showIncorrectOTPMessage ? "border-red" : ""}`}
                />
           

              </div>
              
              <div>
                <p style={styles.resendText}>
                  {t('not_received')}{" "}
                  {timer > 0 ? (
                    <span className="text-primaryBlue font-bold">
                      {timer} {t('seconds')}
                    </span>
                  ) : (
                    <span style={styles.resendButton} onClick={handleResendOtp}>
                    {t('resend')}
                    </span>
                  )}
                </p>
              </div>
              {/* <Button text="Verify"  type="submit" style={styles.verifyButton} onClick={formik.handleSubmit} /> */}
              {showIncorrectOTPMessage && (
                <p className="flex justify-center text-md mt-[5vh] text-red">
                 {t('incorrectotp')}
                </p>
              )}
              <button
                type="submit"
                style={styles.verifyButton}
                onClick={formik.handleSubmit}
                disabled={otpValue?.length<4?true:false}
              >
                {t('verify')}
              </button>
              </form>
            </div>
            {showTermsModal && (
              <TermsModal
                isOpen={showTermsModal}
                onClose={handleAcceptTerms}
                UserPhone={userPhoneno}
                Details={responseData.payload}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default OTPModal;
