

import React from "react";
import { useTranslation } from "react-i18next";

const PaymentInfo = ({ totalPrice, payment, delivery_charge = 0, billDetails }) => {
  const { t, i18n } = useTranslation();

  // console.log("BILL", billDetails);

  return (
    <div className="w-full h-auto mt-20 rounded-xl bg-[rgb(255,255,255)] mb-2 float-left mr-6">
      <p className="flex items-center mx-4 py-1 border-b">
        <span className="text-xs text-gray-600 font-semibold italic">{t('payment_info')}</span>
      </p>
      <div className="flex flex-col mx-4 bg-white">
        {/* Render bill details dynamically */}
        {billDetails?.length > 0 && billDetails?.map((item, index) => (
          <p key={index} className="flex justify-between">
            <span className="text-xs my-1" style={{ color: item?.color, fontSize: item?.fontSize }}>
              {t(item?.name)}
            </span>
            <span className="text-xs my-1" style={{ color: item?.color, fontSize: item?.fontSize }}>
              {item?.currency ? `${item?.currency}${parseFloat(item.value).toFixed(2)}` : item?.value}
            </span>
          </p>
        ))}
      </div>
      {/* <div className="flex flex-col mx-4 bg-white">
        <p className="flex justify-between border-t border-gray-300 text-sky-900">
          <span className="text-xs my-1">{t('paid_myginne')}</span>
          <span className="text-xs my-1">EGP {(totalPrice * 0.5 < 100 ? totalPrice * 0.5 : 100)?.toFixed(2)}</span>
        </p>
        <p className="flex justify-between text-green-700 pb-4">
          <span className="text-xs">{t('collected_customer')}</span>
          <span className="text-xs">
            EGP {((totalPrice + delivery_charge) - (totalPrice * 0.5 < 100 ? totalPrice * 0.5 : 100))?.toFixed(2)}
          </span>
        </p>
      </div> */}
    </div>
  );
};

export default PaymentInfo;
// import React from "react";
// import { useTranslation } from "react-i18next";

// const PaymentInfo = ({ totalPrice, payment, delivery_charge = 0 , billDetails }) => {
//   const {t,i18n}=useTranslation()

//   console.log("BILL",billDetails);
  
  
//   return (
//     <div className="w-full  h-auto mt-20 rounded-xl  bg-[rgb(255,255,255)] float-left mr-6 ">
//       <p className="flex items-center mx-4 py-1 border-b">
//         <span className="text-xs">{t('payment_info')}</span>
//         {/* <span className="font-medium text-base">Payment Mode</span>
//             <span className="w-20 h-7 bg-[#78CFEC33] rounded-lg  px-6 py-1 text-[#1D5C96] text-xs my-1 ml-3 font-semibold">
//             {payment}
//             </span> */}
//       </p>
//       <div className="flex flex-col mx-4  bg-white">
//         <p className="flex justify-between mt-1">
//           <span className="text-xs my-1">{t('item_total')}</span>
//           <span className="text-xs my-1">EGP {totalPrice?.toFixed(2)}</span>
//         </p>
//         <p className="flex justify-between mt-1">
//           <span className="text-xs mb-0.5">{t('delivery_fee')}</span>
//           <span className="text-xs mb-0.5">
//             EGP {delivery_charge?.toFixed(2)}
//           </span>{" "}
//           {/* Example tax calculation */}
//         </p>
//         <p className="flex justify-between  border-t border-gray-300">
//           <span className="font-semibold my-1">{t('grand_total')}:</span>
//           <span className="text-xs my-2">
//             EGP {(totalPrice + delivery_charge)?.toFixed(2)}
//           </span>{" "}
//           {/* Example grand total calculation */}
//         </p>
//       </div>
//       <div className="flex flex-col mx-4  bg-white">
//         <p className="flex justify-between  border-t border-gray-300 text-sky-900 ">
//           <span className="text-xs my-1">{t('paid_myginne')}</span>
//           <span className="text-xs my-1">EGP {(totalPrice*.5<100?totalPrice*.5:100)?.toFixed(2)}</span>{" "}
//           {/* Example grand total calculation */}
//         </p>
//         <p className="flex justify-between text-green-700 pb-4">
//           <span className=" text-xs">{t('collected_customer')}</span>
//           <span className="text-xs ">
//             EGP {((totalPrice + delivery_charge)-(totalPrice*.5<100?totalPrice*.5:100))?.toFixed(2)}
//           </span>{" "}
//           {/* Example grand total calculation */}
//         </p>
//       </div>
//     </div>
//   );
// };

// export default PaymentInfo;
