import { useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import { Image } from "antd";
import Language from "../assets/lang-switcher-icon.svg";
import arrow from "../assets/Arrow.svg";
import LoadingSpinner from "./Loading";

export default function LanguageSwitcher({loading, setLoading}) {
  const { t, i18n } = useTranslation(); // Initialize i18n hooks
  const languageMenu = [
    { label: "العربية", value: "ar" },
    { label: "English", value: "en" },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const [darkMode, setDarkMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // Start with null or an initial value if needed
  // const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   const currentLocale = searchParams.get("lang") || "en";
  //   const currentSelectedItem = languageMenu.find(
  //     (item) => item.value === currentLocale
  //   );
  //   setSelectedItem(currentSelectedItem || languageMenu[0]); // Default to first language if none selected
  // }, [location.search]);

  // useEffect(() => {
  //   // Load selected language from localStorage
  //   const storedLanguage = i18n.language;
  //   if (storedLanguage && storedLanguage !== selectedItem?.value) {
  //     const storedItem = languageMenu.find(item => item.value === storedLanguage);
  //     setSelectedItem(storedItem || languageMenu[0]);
  //     i18n.changeLanguage(storedLanguage);
  //   }
  // }, [selectedItem, languageMenu, i18n]);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage") || "ar"; // Default to "en"
    const initialItem =
      languageMenu.find((item) => item.value === storedLanguage) ||
      languageMenu[0];
    setSelectedItem(initialItem);
    i18n.changeLanguage(initialItem.value); // Set the initial language
  }, []);

  const handleItemClick = (option) => {
    setLoading(true);
    let selectedLang = localStorage.getItem("selectedLanguage");

    if (selectedLang === option.value) {
      setLoading(false);
      return;
    }
    setSelectedItem(option);
    i18n.changeLanguage(option.value); // Change language using i18next
    localStorage.setItem("selectedLanguage", option.value); // Save selected language to localStorage
    // const newUrl = `${location.pathname}?lang=${option.value}`;
    window.location.reload();
    // setLoading(false);
    // navigate(newUrl);
  };

  const handleToggleDarkMode = () => {
    setDarkMode(!darkMode);
    // Additional logic for toggling dark mode if needed
  };

  // useEffect(() => {
  //   if (selectedItem?.value === "ar") {
  //     document.body.setAttribute("dir", "rtl"); // Set direction to RTL if Arabic is selected
  //   } else {
  //     document.body.setAttribute("dir", "ltr"); // Set direction to LTR for other languages
  //   }
  // }, [selectedItem]);
  return (
    <>
    {loading && <LoadingSpinner/>}
    <div className={darkMode ? "dark:bg-black" : ""}>
    <div className=" flex flex-row ms-2 lg:ms-0 z-10 h-8 w-28 rounded-lg">
      <Listbox value={selectedItem} onChange={handleItemClick}>
        {({ open }) => (
          <>
            {/* <Listbox.Button className="relative flex h-full w-[100px] cursor-pointer rounded-xl items-center text-[10px] font-medium focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 border border-solid border-[#CFD3DA] bg-white py-2 text-sm ltr:pl-3 ltr:pr-7 rtl:pl-7 rtl:pr-3"> */}
            <Listbox.Button className="relative flex h-full w-[100px] cursor-pointer rounded-xl items-center text-[10px] font-medium focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 border border-solid border-[#CFD3DA] bg-white py-2 text-sm ltr:pl-3 ltr:pr-7 rtl:pl-7 rtl:pr-3">
              <span
                className={`relative  ${
                  i18n.language == "en" ? "left-5" : "left-2"
                }`}
              >
                <span className="relative top-[1px] block text-sm ms-1">
                  {selectedItem && t(selectedItem.label)}
                </span>
              </span>
              <span className="hidden items-center truncate xl:flex">
                <span className="text-md ltr:mr-3 rtl:ml-3">
                  {selectedItem && selectedItem.icon}
                </span>{" "}
                {selectedItem && t(selectedItem.label)}
              </span>
              <span className="pointer-events-none inset-y-0 absolute top-2 left-3">
                <Image
                  src={Language}
                  alt="language"
                  className={darkMode ? "dark:text-white" : "dark:text-black"}
                />
              </span>
              <span
                className={`${
                  darkMode ? "dark:text-black" : "dark:text-black"
                } ms-14`}
              >
                <img src={arrow} alt="arrow" />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              // as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Listbox.Options
                static
                className="absolute mt-1 max-h-60 w-[100px] overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                {languageMenu.map((option, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      `${active ? "bg-gray-100 text-black" : "text-gray-900"}
                          relative cursor-pointer select-none py-2 px-3`
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <span className="flex items-center text-md">
                        <span className="">{option.icon}</span>
                        <span
                          className={`${
                            selected ? "font-medium" : "font-normal"
                          } block truncate ${
                            selectedItem?.value === "ar"
                              ? "rtl:mr-1.5"
                              : "ltr:ml-1.5"
                          }`}
                        >
                          {t(option.label)}
                        </span>
                        {selected && (
                          <span
                            className={`${
                              active && "text-amber-600"
                            } absolute inset-y-0 flex items-center ${
                              selectedItem?.value === "ar"
                                ? "rtl:right-0 rtl:pr-3"
                                : "ltr:left-0 ltr:pl-3"
                            }`}
                          />
                        )}
                      </span>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>
    </div>
     </div>
      </>
  );
}

// LanguageSwitcher.js

// import React from 'react';
// import { useTranslation } from 'react-i18next';

// const LanguageSwitcher = () => {
//   const { i18n } = useTranslation();

//   const changeLanguage = async () => {
//     try {
//     //   setLoading(true);
//       i18n.changeLanguage(i18n.language === 'ar' ? 'en' : 'ar');
//       // console.log('i18n.languages', i18n.language);
//     //   saveData();
//     //   I18nManager.forceRTL(i18n.language === 'ar');
//     //   setTimeout(() => {
//     //     RNRestart.restart();
//     //   }, 2000);
//     } catch (err) {
//        console.log('Something went wrong while changing language:', err);
//     }
//   };

//   return (
//     <div>
//       <button onClick={() => changeLanguage('en')}>English</button>
//       <button onClick={() => changeLanguage('ar')}>Arabic</button>
//       {/* Add more language buttons as needed */}
//     </div>
//   );
// };

// export default LanguageSwitcher;
