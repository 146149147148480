import React, { useState, useEffect } from "react";
import {Image}  from "antd";
import total_cashback from "../../assets/cashback/total_cashback.svg";
import amount_due from "../../assets/cashback/amount_due.svg";
import amount_paid from "../../assets/cashback/amount_paid.svg";
import { getInventoryallproducts, getInventorydashboard } from "../../API  Functions/InventoryFunctions";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const CashbackBar = ({ tileData}) => {
  const {t,i18n} =useTranslation();
console.log("tileData", tileData);



  const values = {
    1: {
      img: total_cashback,
      heading: t('Total Cashback'),
      productsleft: `${t('EGP')} ${tileData?.total_cashback_amount??0} `,
      bgcolor: "#ffe5cd",
      textcolor: "#EB853C",
      // link:"/inventory/Outofstock"
    },
    2: {
      img: amount_due,
      heading: t('Amount Due'),
      productsleft: `${t('EGP')} ${tileData?.total_due_amount??0}`,
      bgcolor: "#fbdada",
      textcolor: "#EB3C3C",
      // link:"/inventory/Lowoninventory"
    },
    3: {
      img: amount_paid,
      heading: t('Amount Paid'),
      productsleft: `${t('EGP')} ${tileData?.total_paid_amount??0} `,
      bgcolor: "rgb(229 255 224)",
      textcolor: "#059003",
      // link:"/inventory/Highoninventory"
    },
  };

  return (
    <div className="flex flex-wrap mt-20 cursor-text">
      {Object.values(values).map((val, index) => (
        
        <div
          key={index}
          className="w-1/3 sm:w-1/3 md:w-1/3 xl:w-1/3 px-3 mb-2 cursor-text"
          //onClick={() => onInventoryBoxClick(val.heading)}
        >
          <div
            style={{ backgroundColor: val.bgcolor }}
            className="w-full rounded-lg flex items-center p-2 shadow-sm relative cursor-text"
          >
            <Link to={val.link} className="w-full rounded-lg flex items-center  relative cursor-text">
            <div className="me-4">
              <img src={val.img} alt={val.heading} width={48} height={48}  />
            </div>
            <div>
              <p className="font-normal text-md">{val.heading}</p>
              <p
                style={{ color: val.textcolor }}
                className="font-medium text-xl"
              >
                {val.productsleft}
              </p>
            </div>
            </Link>
          </div>
        </div>
        
      ))}
    </div>
  );
};

export default CashbackBar;
