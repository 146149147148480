import React, { useState, useEffect } from "react";
import Deliverypartners from "../../components/EordersDeliveryPartners/Deliverypartners";
import Navbar from "../../components/Navbar/Navbar";
import OrderAmount from "../../components/Orders/OrderAmount";
import Tableheader from "../../components/Orders/Tableheader";
import Sidebar from "../../components/SideBar/sidebar";
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import {
  getStageIds,
  getKPIs,
  getEorderDashboard,
  getEorderDashboardLahloob,
} from "../../API  Functions/Eorders";
import { routes } from "../../Routes";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setKPIData } from "../../Redux/Action/EordersDataAction";
import SelectBox from "../../components/CustomWidgets/SelectBox";
import { DatePicker, Space } from "antd";
import { useTranslation } from "react-i18next";
import { setLahloobKPIData } from "../../Redux/Action/LahloobEOrdersDataAction";
import moment from "moment";
const { RangePicker } = DatePicker;

const formatDate = (date) => date.toISOString().split("T")[0]; // Format date to YYYY-MM-DD

const getStartOfWeek = () => {
  const today = new Date();
  const dayOfWeek = today.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - dayOfWeek);
  return startOfWeek.toISOString().split("T")[0];
};

const getStartOfMonth = () => {
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  // Format the date as 'YYYY-MM-DD' without using toISOString to avoid timezone issues
  const year = startOfMonth.getFullYear();
  const month = String(startOfMonth.getMonth() + 1).padStart(2, "0"); // Ensure month is two digits
  const day = String(startOfMonth.getDate()).padStart(2, "0"); // Ensure day is two digits

  return `${year}-${month}-${day}`;
};

const Orderpage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [showDeliveryPartners, setShowDeliveryPartners] = useState(false);
  const [activeHeading, setActiveHeading] = useState(1);
  const [id, setid] = useState([]);
  const [kpidata, setkpidata] = useState([]);
  const [kpiLahloobdata, setkpilahloobdata] = useState([]);
  const dispatch = useDispatch();

  const store_id = useSelector(
    (state) => state?.login?.user?.result?.company_id
  );
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);
  const company_id = useSelector(
    (state) => state?.login?.user?.result?.company_id
  );
  const is_lahloob = useSelector(
    (state) => state.login?.user?.result?.is_lahloob
  );

  const [selectedRange, setSelectedRange] = useState("today");

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Format YYYY-MM-DD
  };

  useEffect(() => {
    if (user_id) {
      getKPI();
    }
  }, [user_id]);

  const getKPI = async () => {
    let start_date = getCurrentDate();
    let end_date = getCurrentDate();

    try {
      // Fetch KPI data for the first API
      const res1 = await getEorderDashboard(
        user_id,
        start_date,
        end_date,
        is_lahloob
      );
      const kpiData = res1?.result?.[0]?.response;
      console.log("First API data: ", kpiData);
      setkpidata(kpiData); // Store the data in local state
      dispatch(setKPIData(kpiData)); // Dispatch action to save data in Redux

      // Fetch KPI data for the second API
      const res2 = await getEorderDashboardLahloob(
        company_id,
        start_date,
        end_date
      );
      const kpiLahloobData = res2?.result?.data;
      console.log("Second API data: ", kpiLahloobData);
      setkpilahloobdata(kpiLahloobData); // Store the data in local state
      dispatch(setkpilahloobdata(kpiLahloobData));
    } catch (error) {
      console.error("Error fetching KPI data:", error);
    }
  };

  const handleDateRangeChange = (range) => {
    let CurrentSelection = range.target.value;
    // console.log("CurrentSelection", CurrentSelection);

    setSelectedRange(range.target.value); // Update selected range state
    const currentDate = new Date(); // Use native Date
    let startDate, endDate;

    if (CurrentSelection === "today") {
      startDate = getCurrentDate();
      endDate = getCurrentDate();
    } else if (CurrentSelection === "thisWeek") {
      startDate = getStartOfWeek();
      endDate = getCurrentDate();
    } else if (CurrentSelection === "thisMonth") {
      startDate = getStartOfMonth();
      endDate = getCurrentDate();
    } else if (CurrentSelection === "custom") {
      return;
    }

    // console.log("START DATE", startDate);
    // console.log("END DATE", endDate);

    // settodayDate(startDate);
    // formik.setFieldValue("today", [startDate, endDate]);

    // Fetch data from both APIs in parallel
    Promise.all([
      getEorderDashboard(user_id, startDate, endDate, is_lahloob),
      getEorderDashboardLahloob(store_id, startDate, endDate),
    ])
      .then(([dashboardDataRes, lahloobDataRes]) => {
        const kpiData = dashboardDataRes?.result?.[0]?.response;
        dispatch(setKPIData(kpiData)); // Dispatch action to save data in Redux

        const lahloobData = lahloobDataRes?.result?.data;
        dispatch(setLahloobKPIData(lahloobData)); // Dispatch Lahloob data to Redux
      })
      .catch((err) => {
        console.error("Error fetching KPIs:", err);
      });
  };

  const handleRangePickerChange = (dates) => {
    if (dates) {
      const startDate = formatDate(dates[0]);
      const endDate = formatDate(dates[1]);

      // Update Formik field and state with selected range
      // formik.setFieldValue("today", [startDate, endDate]);
      // settodayDate(startDate);

      // Fetch KPIs using the selected custom date range
      // getEorderDashboard(user_id, startDate, endDate, is_lahloob)
      //   .then((res) => {
      //     const kpiData = res?.result?.[0]?.response;
      //     dispatch(setKPIData(kpiData)); // Dispatch action to save data in Redux
      //   })
      //   .catch((err) => {
      //     console.error("Error fetching KPIs:", err);
      //   });
      Promise.all([
        getEorderDashboard(user_id, startDate, endDate, is_lahloob),
        getEorderDashboardLahloob(user_id, startDate, endDate),
      ])
        .then(([dashboardDataRes, lahloobDataRes]) => {
          const kpiData = dashboardDataRes?.result?.[0]?.response;
          dispatch(setKPIData(kpiData)); // Dispatch action to save data in Redux

          const lahloobData = lahloobDataRes?.result?.data;
          dispatch(setLahloobKPIData(lahloobData)); // Dispatch Lahloob data to Redux
        })
        .catch((err) => {
          console.error("Error fetching KPIs:", err);
        });
    }
  };

  // console.log("DATE", formik.values.today);
  // console.log("SELECTED DATE filter", selectedRange);

  const disabledDate = (current) => {
    // Disable dates that are after today
    return current && current > moment().endOf("day");
  };

  return (
    <div className="h-full">
      {/* <div className="float-left fixed z-10">
        <Sidebar />
        </div> */}
      <Navbar heading="eorders" />
      <div className="min-h-14"></div>
      <div className=" pb-6  ">
        <div className="flex flex-col flex-1">
          {showDeliveryPartners ? (
            navigate("/deliverypartner")
          ) : (
            <>
              {/* <div className="flex gap-x-1 ms-[12px] w-[20%]"> */}
                {/* Dropdown for selecting the date range */}
                {/* <SelectBox
                  value={selectedRange}
                  options={[
                    { value: "today", label: t("Today") },
                    { value: "thisWeek", label: t("This Week") },
                    { value: "thisMonth", label: t("This Month") },
                    { value: "custom", label: t("Custom Range") },
                  ]}
                  onChange={handleDateRangeChange}
                  placeholder={t("select_date_range")}
                /> */}
                {/* RangePicker for custom date selection */}
                {/* {selectedRange === "custom" && (
                  <RangePicker
                    className="w-10 h-10 mt-2 range-picker-placeholder"
                    style={{ minWidth: 220 }}
                    onChange={handleRangePickerChange}
                    placeholder={[t("Start Date"), t("End Date")]}
                    disabledDate={disabledDate}
                  />
                )}
              </div> */}
              <div className="w-full">
                {/* <span className="">
                  <OrderAmount
                    data={kpidata}
                    LahloobData={kpiLahloobdata}
                    setShowDeliveryPartners={setShowDeliveryPartners}
                  />
                </span> */}

                <div>
                  <Tableheader
                    setActiveHeading={setActiveHeading}
                    id={id?.data}
                    user_id={user_id}
                    store_id={store_id}
                    // getKPI={getKPI}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Orderpage;
