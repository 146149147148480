import React, { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import "tailwindcss/tailwind.css";
import CustomPagination from "../CustomWidgets/CustomPagination";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Empty, Tooltip } from "antd";
import error from "../../assets/Images/error.svg";
import { Image } from "antd";
import Vectorsorting from "../../assets/Vectorsorting.svg";
import arrowright from "../../assets/arrowright.svg";
import "../../styles.css";
import { Navigate, useNavigate } from "react-router";
import ShimmerLoader from "../../loading";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const CashbackHistory = ({ tableData, pagination }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [flag, setflag] = useState(false);
  

  useEffect(() => {
    if (tableData?.length === 0 && !flag) {
      const timer = setTimeout(() => {
        setflag(true);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [tableData, flag]);

  const openOrderList = (orderNumber) => {
    navigate(`/Orderlist?orderDetail=${orderNumber}`, {
      state: { from: "cashback" }, 
    });
  };

  const data = useMemo(() => tableData || [], [tableData]);
  const columns = useMemo(
    () => [
      {
        Header: t("order_no"),
        accessor: "order_no",
        Cell: ({ value }) => (
          <>
            <button
              className=" text-[#1b4497] rounded-lg"
              onClick={() => openOrderList(value)}
            >
              {value}
            </button>
            {/* )
            } */}
          </>
        ),
      },
      { Header: t("customer_name"), accessor: "customer_name" },
        {
          Header: t("Delivery Date"),
          accessor: "delivery_date",
        }
     ,
      {
        Header: t("Cashback Used"),
        accessor: "cashback_amount",
        Cell: ({ value }) => `EGP ${value}`,
      },
      {
        Header: t("Type"),
        accessor: "balance_type",
        width: 150, // Increased width
        Cell: ({ value }) => {
          let backgroundColor,
            textColor,
            borderRadius,
            paddingTop,
            paddingBottom,
            paddingLeft,
            paddingRight;

          // Conditionally set styles based on order status
          if (value === "cashback") {
            backgroundColor = "#93c47d";
          } else if (value === "coupon") {
            backgroundColor = "#1b4497";
          } else if (value === "cancelled_order") {
            backgroundColor = "#E74C3C";
          } else {
            backgroundColor = "#E74C3C";
          }

          // Padding values for top, bottom, left, right
          textColor = "#ffffff";
          paddingTop = "8px";
          paddingBottom = "8px";
          paddingLeft = "1px";
          paddingRight = "1px";
          borderRadius = "10px";

          return (
            <div className="flex justify-center"> {/* Center alignment */}
              <h4
                style={{
                  backgroundColor,
                  color: textColor,
                  borderRadius,
                  padding: `${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft}`,
                  fontWeight: 600,
                  display: "inline-block",
                  minWidth: "90px",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {(() => {
                  switch (value) {
                    case "cashback":
                      return t("Cashback");
                    case "cancelled_order":
                      return t("Cancelled");
                    case "coupon":
                      return t("Coupon");
                    default:
                      return t("");
                  }
                })()}
              </h4>
            </div>
          );
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: { pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data: tableData || [],
      initialState: { pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  const [pageIndex, setpageIndex] = useState(1);
  useEffect(() => {
    const pageDetails = {
      limit: pageSize,
      offset: pageSize * (pageIndex - 1) < 0 ? 0 : pageSize * (pageIndex - 1),
    };
    pagination(pageDetails);
  }, [pageIndex, pageSize, previousPage, nextPage]);

  return (
    <div className="container mx-auto float-left"  style={{ maxWidth: "100%" }}>
      {tableData?.length == 0 && flag == false ? (
        <>
          <ShimmerLoader />{" "}
        </>
      ) : (
        <>
        <table
        {...getTableProps()}
        className="table-auto mx-2 ml-3 mt-7 font-normal  sm:w-[97.6%] md:w-[97.6%] lg:w-[97.6%] xl:w-[97.6%] min-h-auto shadow-xl rounded-lg overflow-hidden"
        style={{ tableLayout: "fixed" }}
      >
          <thead>
            {headerGroups.map((headerGroup, headerIndex) => {
              const { key: headerKey, ...headerGroupProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <tr
                  key={headerKey}
                  {...headerGroupProps}
                  className="bg-[#78CFEC66] text-sm font-semibold"
                >
                  {headerGroup.headers.map((column, colIndex) => {
                    const { key: columnKey, ...columnProps } =
                      column.getHeaderProps(column.getSortByToggleProps());
                    return (
                      <th
                        key={columnKey}
                        {...columnProps}
                        className="py-3 px-2 whitespace-nowrap "
                      >
                        <div className="flex items-center justify-center">
                          {column.render("Header")}
                          <span className="">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <img
                                  src={Vectorsorting}
                                  style={{ minHeight: 10, minWidth: 10 }}
                                  alt="v"
                                />
                              ) : (
                                <img
                                  className="rotate-180"
                                  src={Vectorsorting}
                                  style={{ minHeight: 10, minWidth: 10 }}
                                  alt="v"
                                />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          </table>
          <div className="scrollbar-none"
              style={{
                overflowY: "auto",
                maxHeight: "450px", // Adjust height as needed
              }}
            >
               <table
                className="font-normal mx-auto max-w-[100%] sm:w-[97.6%] md:w-[97.6%] lg:w-[97.6%] xl:w-[97.6%] overflow-hidden rounded-lg"
                style={{ tableLayout: "fixed" }}
              >
          <tbody {...getTableBodyProps()} className="bg-[#F7F9FD] text-center">
            {page.map((row) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.id}>
                  <tr
                    {...row.getRowProps()}
                    className="relative hover:bg-white transition duration-300 border-b border-gray-300 fixed-row-height "
                    onClick={() => openOrderList(row.original.order_no)}
                    style={{ cursor: "pointer" }} 
                  >
                    {row.cells.map((cell, index) => (
                      <td
                        {...cell.getCellProps()}
                        className={`p-2 text-[#272728]  h-1225  text-sm font-normal ${
                          index < row.cells.length - 1 ? "" : ""
                        }`}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                </React.Fragment>
              );
            })}
            {/* Empty state row */}
            {page.length === 0 && (
              <tr>
                <td colSpan={columns.length}>
                  <Empty description="No data available" />
                </td>
              </tr>
            )}
            <tr>
              {tableData.length >= 0 && (
                <td
                  className=""
                  colSpan={columns.length}
                >
                  <CustomPagination
                    data={tableData?.length}
                    pageIndex={pageIndex}
                    pageCount={page.length}
                    canPreviousPage={pageIndex != 1 ? true : false}
                    canNextPage={tableData?.length >= 10 ? true : false}
                    gotoPage={(page) => {
                      nextPage(page);
                    }}
                    previousPage={() => {
                      setpageIndex(pageIndex - 1);
                    }}
                    nextPage={() => {
                      setpageIndex(pageIndex + 1);
                    }}
                    pageSize={pageSize}
                    setPageSize={(pageSize) => {
                      // nextPage(0);

                      setPageSize(pageSize);
                    }}
                  />
                </td>
              )}
            </tr>
          </tbody>
        </table>
        </div>
      </>
      )}
      </div>
  );
};

export default CashbackHistory;
