import React, { useEffect, useState } from "react";
import { Modal, Input, Button, Switch, DatePicker } from "antd";
import moment from "moment"; // Import moment for date formatting
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { t } from "i18next";
import { toast } from "react-toastify";

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <Input
    ref={ref}
    value={value}
    onClick={onClick}
    placeholder={t("Expiry Date")}
    style={{
      height: "40px", // Set the desired height
      border: "1px solid #d9d9d9", // Set the border
      borderRadius: "4px", // Optional: Add border radius
      marginRight: "10px", // Space between input fields
    }}
  />
));

const ExpiryModal = ({
  visible,
  onClose,
  onSave,
  productIndex,
  initialData,
  purchaseQty,
  freeQty,
}) => {
  const [expiryData, setExpiryData] = useState([{ qty: "", expiryDate: null }]);
  const [expiryAlert, setExpiryAlert] = useState(false);
  const [alertDays, setAlertDays] = useState("");
  const [totalQty, setTotalQty] = useState(0);

  // console.log("Purchase QTY", purchaseQty, freeQty);
 


  useEffect(() => {
    if (visible) {
      // Check if initialData has any entries
      if (initialData && initialData.length > 0) {
        setExpiryData(initialData); // Use initial data if available
      } else {
        // If no initial data, set to an array with one empty entry
        setExpiryData([{ qty: "", expiryDate: null }]);
      }
      setExpiryAlert(false); // Reset expiry alert
      setAlertDays(""); // Reset alert days
      setTotalQty(0); // Reset total quantity
    }
  }, [visible, initialData]);

  // console.log("initialData--->", initialData);
  // console.log("expiryData--->", expiryData);


  const handleAddRow = () => {
    if (expiryData.length < 10) {
      setExpiryData([...expiryData, { qty: "", expiryDate: null }]);
    } else {
      toast.error("You can only add up to 10 rows."); // Optional: Alert the user
    }
  };

  // const handleChange = (index, key, value) => {
  //   const newData = [...expiryData];
  //   newData[index][key] = value;
  //   setExpiryData(newData);
  // };

  const handleChange = (index, key, value) => {
    const newData = [...expiryData];

    // Convert the value to a number if it's for quantity
    if (key === "qty") {
      const numericValue = parseInt(value, 10) || 0; // Convert to number, default to 0 if NaN
      newData[index][key] = numericValue;

      // Calculate the new total quantity
      const newTotalQty = newData.reduce(
        (acc, item) => acc + (item.qty || 0),
        0
      );
      setTotalQty(newTotalQty);
    } else {
      newData[index][key] = value;
    }

    setExpiryData(newData);
  };

  const handleDateChange = (index, date) => {
    const newData = [...expiryData];
    newData[index].expiryDate = date; // Set the selected date
    setExpiryData(newData);
  };

  // console.log("EXPIRAY DATA----->", expiryData);
  

  // const handleSave = () => {
  //   console.log("Saving data:", expiryData, expiryAlert, alertDays, productIndex);
  //   onSave(expiryData, expiryAlert, alertDays, productIndex);
  //   onClose();
  // };

  // console.log("TOTAL QTY", totalQty);

  const handleSave = () => {
    if (totalQty > purchaseQty + freeQty) {
      toast.error(
        "Total quantity cannot exceed purchase quantity plus free quantity."
      );
      return;
    }
    // console.log(
    //   "Saving data:",
    //   expiryData,
    //   expiryAlert,
    //   alertDays,
    //   productIndex
    // );
    onSave(expiryData, expiryAlert, alertDays, productIndex);
    onClose();
  };

  const handleClose = () => {
    // Remove empty rows before closing
    const filteredData = expiryData.filter(
      (item) => item.qty || item.expiryDate
    );
    setExpiryData(filteredData);
    onClose();
  };

  // Check if the button should be disabled
  const isButtonDisabled = expiryData.every(
    (item) => !item.qty || !item.expiryDate
  );

  return (
    <Modal
      title={t("Expiry")}
      visible={visible}
      onCancel={handleClose} // Use handleClose instead of onClose
      footer={null}
    >
      <div>
        {expiryData.map((item, index) => (
          <div key={index} className="flex mb-2">
            {/* {console.log("AAAAAA---->", item)} */}
            <Input
              type="number"
              placeholder={t("Qty")}
              value={item.qty}
              onChange={(e) => handleChange(index, "qty", e.target.value)}
              style={{ width: "100px", marginRight: "10px" }}
            />
            <DatePicker
              value={item.expiryDate} // Use selected prop for react-datepicker
              onChange={(date) => handleDateChange(index, date)} // Handle date change
              style={{ marginRight: "10px" }} // Add margin for spacing
              disabled={!item.qty} // Disable date picker if qty is not set
            />
          </div>
        ))}
        <Button
          onClick={handleAddRow}
          type="dashed"
          style={{ marginBottom: "10px" }}
        >
          {t("Add")} +
        </Button>
        <div>
          <span>{t("Expiry Alert")}</span>
          <Switch
            checked={expiryAlert}
            className={`ms-2 ${expiryAlert ? "bg-primaryBlue" : "bg-gray-400"}`}
            onChange={setExpiryAlert}
          />
          {expiryAlert && (
            <>
              <Input
                placeholder={t("XX Days")}
                value={alertDays}
                onChange={(e) => setAlertDays(e.target.value)}
                className="w-20 ms-2"
              />
              <span className="ms-2">{t("Days")}</span>
            </>
          )}
        </div>
        <div className="flex justify-end font-semibold mt-4">
          <Button
            onClick={handleSave}
            className="bg-primaryBlue hover:bg-primaryBlue" // Change hover color
            type="primary"
            disabled={isButtonDisabled} // Disable button if no qty and dates are there
          >
            {t("save")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ExpiryModal;
