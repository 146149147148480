import React, { useEffect, useState } from "react";
import logo from "../../assets/Logo/myginnelogo.svg";
import plus_button from "../../assets/sidebar/plus_button.svg";
import minus_button from "../../assets/sidebar/minus_button.svg";

import { headings } from "./SidebarData";
// Import usePathname
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const currentPage = pathname; // Directly get the pathname

  const is_lahloob = useSelector(
    (state) => state.login?.user?.result?.is_lahloob
  );

  const subheadings = [
    [
      { text: 'view_inventory', route: '/inventory' },
      { text: 'add_product', route: '/inventory/InventoryOnboard' },
    ],
    [
      { text: 'Add Purchase Bill', route: '/purchase/add-bill' },
      { text: 'Purchase History', route: '/purchase/list' },
      { text: 'Supplier Payment', route: '/purchase/supplyPayment' },
    ],
    [
      { text: 'Cashier' },
      { text: 'Session History', route: '/pos/sessionHistory' },
    ],
    [
      { text: 'view_orders', route: '/orders' },
    ],
    // [
    //   { text: 'Cashback History', route: '/cashback' },
    // ],
    [
      { text: 'my_profile', route: '/settings/profile' },
      ...(is_lahloob ? [] : [{ text: 'user_management', route: '/settings/usermanagement' }]), // Conditionally include user_management
    ],
  ];


  const determineActiveSubheading = () => {
    for (let i = 0; i < subheadings.length; i++) {
      const subheadingsArray = subheadings[i];
      for (let j = 0; j < subheadingsArray.length; j++) {
        if (subheadingsArray[j].route === currentPage) {
          return { headingIndex: i, subheadingIndex: j };
        }
      }
    }
    return { headingIndex: null, subheadingIndex: null };
  };

  const [activeHeading, setActiveHeading] = useState(() => {
    if (typeof window !== "undefined" && window.localStorage) {
      return parseInt(localStorage.getItem("activeHeading") || 0);
    }
    return null;
  });

  const [activeSubheading, setActiveSubheading] = useState(null);

  useEffect(() => {
    const { headingIndex, subheadingIndex } = determineActiveSubheading();
    setActiveHeading(headingIndex);
    setActiveSubheading(subheadingIndex);
  }, []);

  // useEffect(() => {
  //   if (activeHeading !== null) {
  //     localStorage.setItem("activeHeading", activeHeading);
  //   }
  // }, [activeHeading]);

  const excludedHeadings = [7]; 
  // const excludedHeadings = [6, 7, 1, 2, 3, 4, 5];
  // const headingsWithIcons = [0];
  const headingsWithIcons = [0, 1, 2, 3, 4, 5, 6];


  const handleHeadingClick = (index) => {
    // if (index == 2)
    //   window.open(`${process.env.REACT_APP_POS_REDIRECT_LINK}`, '_blank');

    if (!excludedHeadings.includes(index)) {
      setActiveHeading((prevActiveHeading) =>
        prevActiveHeading === index ? null : index
      );
    }
  };

  const handleSubheadingClick = (subIndex, route) => {
    // console.log("subIndex",subIndex);
    // console.log("route",route);

    
    if (subIndex == 0 && !route)
      window.open(`${process.env.REACT_APP_POS_REDIRECT_LINK}`, '_blank');

    setActiveSubheading(subIndex);
    // router.push(route); // Navigate to the selected subheading's route
  };

  // console.log("HEAIDNGSS", headings);
  // console.log("SUBBB HEAIDNGSS", subheadings);



  return (
    // <div
    //   className={`sidebar bg-primaryBlue z-20 shadow-md text-white w-[35vh] p-[1vh] overflow-auto sticky ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}
    //   dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
    // >
    <div className="sidebar bg-primaryBlue   z-20  shadow-md text-white w-[35vh] p-[1vh] overflow-auto sticky">
      <img
        src={logo}
        className="my-[2vh] mx-[0vh] mb-[4vh] h-[5vh] w-[16vh]"
        alt="myGINNE Logo"
      />
      {headings.map((heading, index) => (
        <div key={index} className="mb-[3vh]">
          <div
            className={`heading-box font-medium flex items-center justify-start w-[28vh] h-[6vh] ${
              activeHeading === index ? "bg-white" : "bg-[#FFFFFF1A]"
            } rounded-[1vh] p-[1vh] cursor-pointer ${
              activeHeading === index ? "text-primaryBlue" : ""
            } transition-all duration-300`}
            onClick={() => handleHeadingClick(index)}
          >
            <span
              className="flex flex-row items-center justify-center me-[2vh] h-[4vh] w-[4vh]"
              style={{
                color:
                  activeHeading === index
                    ? heading.colorActive
                    : heading.colorInactive,
              }}
            >
              {activeHeading === index ? heading.iconActive : heading.icon}
            </span>
            {/* <span
              style={{ transition: "border-color 0.3s", fontSize: "2.5vh" }}
            > */}
             <span
            className="text-sm "
            >
              {t(heading.text)}
            </span>
            {headingsWithIcons.includes(index) && (
              // <span className="ml-auto" style={{ fontSize: "3vh" }}>
              //   {activeHeading === index ? "-" : "+"}
              // </span>
              <span className="ms-auto">
                {activeHeading === index ? (
                  <img
                    className="w-5 h-5 object-contain "
                    src={minus_button}
                    alt="Minus icon"
                  />
                ) : (
                  <img
                    className="w-5 h-5 object-contain "
                    src={plus_button}
                    alt="Plus icon"
                  />
                )}
              </span>
            )}
          </div>

          {activeHeading === index && (
            <ul
              className={`bg-[#094072] list-disc w-[28vh] text-transparencyWhite text-sm rounded-b-lg rounded-bl-lg overflow-hidden max-h-[48vh] max-w-[28vh] transition-all duration-300 ease-in-out`}
            >
              {subheadings[index].map((subheading, subIndex) => (
                <div
                  key={subIndex}
                  className={`cursor-pointer py-1 mt-2 rounded-s-sm  ${
                    activeSubheading === subIndex && activeHeading === index
                      ? "border-l-4 pl-[3.4vh] border-[#78CFEC] text-white font-medium "
                      : "ps-[4vh] text-[#B5C6D5]"
                  }`}
                >
                  <Link to={subheading.route}>
                    <li
                      className={`cursor-pointer text-xs`}
                      onClick={() =>
                        handleSubheadingClick(subIndex, subheading.route)
                      }
                      style={{
                        // marginLeft: "1vh",
                        marginLeft: i18next.language === "ar" ? "0" : "1vh",
                        marginRight:   i18next.language === "ar" ? "1vh" : "0",
                        marginBottom: "2px",
                        transition: "border-color 0.3s",
                        // fontSize: "2vh",
                      }}
                    >
                      {t(subheading.text)}
                    </li>
                  </Link>
                </div>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
};

export default Sidebar;
