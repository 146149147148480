import { URLS } from "../API/API endpoints";
import axiosInstance from "../AxiosInstance";
import i18next from "i18next";

export const getVendors = async (user_id) => {
  try {
    const payload = {
      lang: i18next.language === "ar" ? "ar_001" : "en_US",
      user_id: user_id,
    };
    const response = await axiosInstance.post(URLS.GET_VENDORS, payload);

    // console.log("responsse data", response.data);

    return response.data?.result?.vendors;
  } catch (error) {
    return error;
  }
};

export const createVendor = async (payload) => {
  try {
    const response = await axiosInstance.post(URLS.CREATE_VENDOR, payload);
    return response;
  } catch (error) {
    return error;
  }
};

export const getPurchaseProducts = async (
  user_id,
  productName,
  limit = 10,
  offset = 0
) => {
  try {
    const payload = {
      user_id: user_id,
      product_name: productName,
      limit: limit,
      offset: offset,
      lang: i18next.language === "ar" ? "ar_001" : "en_US",
    };

    const response = await axiosInstance.post(URLS.SEARCH_PRODUCTS, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};


// Supplier Payment APIS 

export const getPaymentsList = async (payload) => {
    try {
      
      const response = await axiosInstance.post(URLS.GET_PAYMENT_LIST, payload);  
      return response.data;
    } catch (error) {
      return error;
    }
  };

  export const getBillPaymentHistory = async (payload) => {
    try {
      const response = await axiosInstance.post(URLS.GET_BILL_PAYMENT_HISTORY, payload);
      console.log("GET_POS_SESSION_HISTORY", response);
  
      return response.data;
    } catch (error) {
      return error;
    }
  };

  export const GetPaymentModes = async (user_id) => {
    try {
      const payload = {
        lang: i18next.language === "ar" ? "ar_001" : "en_US",
        user_id: user_id,
      };
      const response = await axiosInstance.post(
        URLS.GET_PAYMENT_TERMS,
        payload
      );
  
      // console.log("Payment Mode res",response);
      
  
      if (response?.data?.result[0]?.status_code === 200) {
        return response.data?.result[0]?.response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  };


export const RegisterPayment = async (payload) => {
    try {
      const response = await axiosInstance.post(
        URLS.REGISTER_PAYMENT,
        payload
      );
  
      console.log("BBBBBB",response);
      
  
      if (response?.data?.result?.status_code === 200) {
        return response.data?.result;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  };

  export const AddBarcodeApi = async (payload) => {
    try {
      const response = await axiosInstance.post(
        URLS.ADD_BARCODE,
        payload
      );
  
      console.log("BBBBBB",response);
      
  
      if (response?.data?.result?.status_code === 200) {
        return response.data?.result;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  };
  
  
//  Payment List APIS 

export const getPurchaseList = async (payload) => {
    try {
      
      const response = await axiosInstance.post(URLS.GET_PURCHASE_LIST, payload);  
      return response.data;
    } catch (error) {
      return error;
    }
  };


  export const updatePOImages = async (payload) => {
    try {
      
      const response = await axiosInstance.post(URLS.UPLOAD_PO_IMAGE, payload);  
      return response.data;
    } catch (error) {
      return error;
    }
  };



export const getPayTerms = async (company_id) => {
  try {
    const payload = {
      lang: i18next.language === "ar" ? "ar_001" : "en_US",
      user_id: company_id,
    };
    const response = await axiosInstance.post(URLS.GET_PAY_TERMS, payload);

    console.log("responsse data", response.data);

    return response.data?.result?.payment_terms;
  } catch (error) {
    return error;
  }
};

// In PurchaseFunctions.js
export const createPurchaseOrder = async (payload) => {
  try {
    const response = await axiosInstance.post(URLS.CREATE_PURCHASE_ORDER, payload);

    console.log("responsse data", response.data);
    return response.data;
  } catch (error) {
    console.error("Error creating purchase order:", error);
    throw error; // Rethrow the error for handling in the calling function
  }
};