import { combineReducers } from 'redux';
import {userReducer,loginReducer,sessionidentifierReducer,getcategorydataReducer, logoutReducer,LahloobEorderReducer, EorderReducer, formReducer, dashDataReducer} from "./index"
import deliveryAreasSlice from '../../components/Redux Slices/deliveryAreasSlice';


const rootReducer = combineReducers({
  user: userReducer,
  login: loginReducer,
  // sessionIdentify:sessionidentifierReducer,
  // productData:getcategorydataReducer,
  logout: logoutReducer,
  EorderReducer : EorderReducer,
  form: formReducer,
  dashData:dashDataReducer,
  LahloobEorderReducer : LahloobEorderReducer
  // deliveryAreas:deliveryAreasSlice,
});

export default rootReducer;
