import React, { useState, useEffect } from "react";
import CashbackBar from "./CashbackBar";
import Navbar from "../Navbar/Navbar";
import { useSelector } from "react-redux";
import {
  getCashbackDetails,
  getCashBackPaymentHistory,
} from "../../API  Functions/CashbackFunctions";
import LoadingSpinner from "../Loading";
import CashbackHistory from "./CashbackHistoryTable";
import { Drawer } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { HistoryOutlined } from "@ant-design/icons"; // Import the desired icon
import SelectBox from "../CustomWidgets/SelectBox";

const Cashback = () => {
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [paymentHistoryData, setPaymentHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tileData, setTileData] = useState([]);
  const [currentLimit, setCurrentLimit] = useState(10); // Default limit
  const [currentOffset, setCurrentOffset] = useState(0); // Default offset
  const [balanceType , setBalanceType] = useState("")

  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);
  const company_id = useSelector(
    (state) => state?.login?.user?.result?.company_id
  );

  const getCashbackData = (limit = currentLimit, offset = currentOffset, balancetype) => {
    setLoading(true);
    const payload = {
      user_id: user_id,
      limit: limit,
      offset: offset,
      balance_type: balancetype,
    };
    getCashbackDetails(payload).then((res) => {
      // console.log("CCCCC------>", res);

      setTileData(res?.result[0]?.response[0]);
      setTableData(res?.result[0]?.response[0]?.order_details);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (company_id) getCashbackData();
  }, [company_id]);

  const onClose = () => {
    setVisible(false);
  };

  const showDrawer = () => {
    // setDrawerData(historyData);
    setVisible(true);
    getPaymentHistory(user_id);
  };

  const getPaymentHistory = (user_id) => {
    const payload = {
      user_id: user_id,
    };
    getCashBackPaymentHistory(payload)
      .then((res) => {
        // console.log("payment------>", res);
        if (res?.result[0]?.response.length > 0) {
          setPaymentHistoryData(res?.result[0]?.response);
          setLoading(false);
        } else {
          console.error("No payment history found");
        }
      })
      .catch((error) => {
        console.error("Error fetching session history:", error);
      });
  };

  const handleSelectChange = (event) => {
    
    const selectedValue = event.target.value;
    console.log("selectedValue",selectedValue);
    setBalanceType(selectedValue); 
    getCashbackData(currentLimit, currentOffset, selectedValue);
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      <div className="float-left fixed z-10"></div>
      <div className="w-full pb-6">
        <div className="flex flex-col flex-1">
          <Navbar heading="Cashback" />
          <CashbackBar tileData={tileData} />
          <div className="flex gap-x-4 items-end justify-end me-3">
            <div className="w-[15%]">
            <SelectBox
                value={balanceType} 
                options={[
                  { value: "", label: t("All") },
                  { value: "cashback", label: t("Cashback") },
                  { value: "coupon", label: t("Coupon") },
                ]}
                onChange={handleSelectChange} 
                placeholder={t("All")}
              />
            </div>
            <button
              onClick={() => showDrawer()}
              className={`font-semibold w-48 h-10 text-sm border border-primaryBlue rounded-lg bg-white text-primaryBlue flex justify-center items-center gap-2`}
              type="submit"
            >
              <HistoryOutlined />
              <p className="">{t("View Payment History")}</p>
            </button>
          </div>
          <CashbackHistory
            tableData={tableData}
            pagination={(values) => {
              setCurrentLimit(values.limit);
              setCurrentOffset(values.offset);
              getCashbackData(values.limit, values.offset);
            }}
          />
        </div>
      </div>
      <Drawer
        title={t("Payment History")}
        placement="right"
        width={500}
        onClose={onClose}
        visible={visible}
      >
        {/* Display the session history data */}
        {loading ? (
          <LoadingSpinner />
        ) : (
          // <div>{t("Loading session data...")}</div>
          <div>
            {paymentHistoryData.length > 0 ? (
              <>
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="p-2 text-left">{t("Payment Date")}</th>
                      <th className="p-2 text-left">{t("Payment Mode")}</th>
                      <th className="p-2 text-left">{t("Amount")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentHistoryData.map((payment, index) => (
                      <tr key={index} className="border-b">
                        <td className="p-2">
                          {moment(payment.payment_date).format("YYYY-MM-DD")}
                        </td>
                        <td className="p-2">{payment.payment_mode}</td>
                        <td className="p-2">{payment.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <p>{t("No payment history available")}</p>
            )}
          </div>
        )}
      </Drawer>
    </>
  );
};

export default Cashback;
