import DashboardEorder from "../../components/Dashboard/DashboardEorder";
import Dashboardfifth from "../../components/Dashboard/DashboardFifth";
import Dashboardfourth from "../../components/Dashboard/Dashboardfourth";
import DashboardAmount from "../../components/Dashboard/dashboardAmount";
import Dashboardthird from "../../components/Dashboard/dashboardthird";
import Navbar from "../../components/Navbar/Navbar";
import Tableheader from "../../components/Orders/Tableheader";

 import Sidebar from "../../components/SideBar/sidebar";
import React from "react";

const Dashboard = () => {
  return (
    <>
      <div className="float-left fixed z-10">
        {/* <Sidebar /> */}
      </div>
      <div className="w-full h-[100rem] bg-[#F5F5F9]">
        <div className="flex flex-col flex-1 ">
          <Navbar heading="Dashboard" />
          <div className="mt-1 mx-3">
            <Dashboardthird />
          </div>
          <DashboardAmount />
         
          <div className="mt-1">
            <DashboardEorder />
          </div>
          <div className="mt-1">
            <Dashboardfourth />
          </div>
          <div className="mt-1">
            <Dashboardfifth />
          </div> 

           {/* <div>
            <Tableheader />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
