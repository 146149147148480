import React from "react";
import { Image } from "antd";
import vector from "../../assets/Images/Vector.svg";
import image from "../../assets/Images/roundimage.png";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const UserManagement = () => {
  const store_data = useSelector((state) => state.login?.user?.result);
  const { t, i18n } = useTranslation();

  // console.log("CCCCC", store_data);
  

  return (
    <>
    
      <div className=" flex gap-5 items-center">
      
        {localStorage?.getItem("profileImage") || store_data?.image_url ? (
          <img
            src={localStorage?.getItem("profileImage") || store_data?.image_url}
            height={70}
            width={70}
            alt="ui"
            className="h-[85px] ms-6 w-[85px] object-cover rounded-full"
          />
        ) : (
          <img
          src={image}
          height={50}
          width={50}
          alt="ui"
          className="h-[55px] ml-6 w-[55px] object-cover rounded-full"
        />
        )}
        {/* <img
          className="h-[85px] ml-6 w-[85px] object-cover rounded-full"
          src="https://cdn2.thecatapi.com/images/0XYvRd7oD.jpg"
          alt=""
        /> */}
        <div className="grid gap-1 font-medium">
          <div className=" text-[#272728] ">
            {/* {(store_data?.retailer_name.indexOf(" ") != -1 || store_data?.retailer_name.indexOf(" ") != 1 ||store_data?.retailer_name.indexOf(" ") != 0)
                ? store_data?.retailer_name.substring(0, store_data?.retailer_name.indexOf(" "))
                : store_data?.retailer_name}  */}
            {localStorage.getItem("username")?localStorage.getItem("username")
            :(store_data?.company_name?store_data?.company_name:(!store_data?.company_name && "User"))}
          </div>
          <div className="text-[#999999] ">{t("role")}</div>
        </div>
        {/* <button
          className="flex justify-center items-center w-44 h-10 border rounded-lg border-primaryBlue text-primaryBlue text-base absolute right-0  mr-9 font-semibold"
          type="submit"
        >
          <img className="" src={vector} />
          <p className="ml-2">Edit</p>
        </button> */}
      </div>
    </>
  );
};

export default UserManagement;
