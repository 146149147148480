import React, { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import "tailwindcss/tailwind.css";
import CustomPagination from "../CustomWidgets/CustomPagination";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Empty, Tooltip } from "antd";
import error from "../../assets/Images/error.svg";
import { Image } from "antd";
import Vectorsorting from "../../assets/Vectorsorting.svg";
import arrowright from "../../assets/arrowright.svg";
import "../../styles.css";
import { Navigate, useNavigate } from "react-router";
import ShimmerLoader from "../../loading";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const FailedCancelledtable = ({ failedcancelled, pagination }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [flag, setflag] = useState(false);
  const is_lahloob = useSelector((state) => state.login?.user?.result?.is_lahloob);


  useEffect(() => {
    if (failedcancelled?.length === 0 && !flag) {
      const timer = setTimeout(() => {
        setflag(true);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [failedcancelled, flag]);

  // console.log("data", failedcancelled);

  
  const openOrderList = (orderNumber) => {
    console.log("order_number",orderNumber);
    
    navigate(`/Orderlist?orderDetail=${orderNumber}`);
    // navigate(`/Orderlist`, { state: { orderDetail: rowData } });
  }

  const data = useMemo(() => failedcancelled || [], [failedcancelled]);
  const columns = useMemo(
    () => [
      {
        Header: t("order_no"),
        accessor: "order_details.order_no",
        Cell: ({ value }) => (
          <>
            {/* {(value.substring(value.lastIndexOf(",") + 1) == "Order Pending" ||
              value.substring(value.lastIndexOf(",") + 1) == "In Processing" ||
              value.substring(value.lastIndexOf(",") + 1) ==
                "Ready For Delivery") && ( */}
            <button
              // className="bg-[#7EC242] text-white w-28 h-10 rounded-lg"
              className=" text-[#1b4497] rounded-lg"
              onClick={() => openOrderList(value)}
              // onClick={() => openOrderList("ordernamehar" + value)}
            >
              {value}
            </button>
            {/* )
            } */}
          </>
        ),
      },
      { Header: t("customer_name"), accessor: "order_details.partner_name" },
      // {
      //   Header: t("delivery_schedule"),
      //   accessor: "order_details.delivery_schedule",
      // },
      // ...(is_lahloob ? [] : [
      //   {
      //     Header: t("delivery_schedule"),
      //     accessor: "order_details.delivery_schedule",
      //   }
      // ]),
      {
        Header: t("items"),
        // accessor: "order_line_details",
        accessor: (row) => (row.existing_items?.length || 0) + (row.new_items?.length || 0),
        cell: ({ value }) => value || 0,
      },
      {
        Header: t("order_value"),
        accessor: "order_details.total",
        Cell: ({ value }) => `EGP ${value}`,
      },
      // { Header: t("order_value"), accessor: "order_details.total" },
      // { Header: t("payment"), accessor: "payment_mode" },
      // { Header: t("delivery_boy"), accessor: "delivery_boy" },
      // { Header: t("cancelled_by"), accessor: "cancel_reason.cancel_by" },

      {
        Header: t("cancelled_reason"),
        accessor: "order_details.cancel_reason",
        cell: ({ value }) => value || 0,
        // Cell: ({ value }) => {
          // {console.log("row",value)}
          // <div
          //       className={`${
          //         value.name == "Out of stock"
          //           ? "bg-[#D1FFDE] text-[#00B833]"
          //           : "bg-[#E0F0FF] text-[#1D5C96]"
          //       } w-36 h-9 rounded-3xl py-2 inline-block`}
          //     >

          //       {value.name}
          //     </div>
        //   const getStatusColor = (value) => {
        //     // console.log(value)

        //     // if (value.name === "Failed") {
        //     //   return {
        //     //     backgroundColor: "#FFE2AA",
        //     //     textColor: "#FF701F",
        //     //     icon: <img src={error} />,
        //     //   };
        //     if (value.name === "Out of stock") {
        //       return {
        //         backgroundColor: "#FFE2AA",
        //         textColor: "#FF701F",
        //         icon: <img src={error} alt="Image" />,
        //       };
        //     } else if (value.name === "Cancelled") {
        //       return {
        //         backgroundColor: "#FFE2E2",
        //         textColor: "#FF2121",
        //         icon: <img src={error} alt="Image" />,
        //       };
        //     }
        //     // Add more conditions as needed
        //     return {};
        //   };

        //   const { backgroundColor, textColor, icon } = getStatusColor(value);

        //   return (
        //     <Tooltip
        //       placement="bottomRight"
        //       title={
        //         <div
        //           style={{
        //             height: "26px",
        //             borderRadius: "10px",
        //             backgroundColor: "#1E1E1E",
        //             color: "#FFFFFF",
        //             fontSize: "12px",
        //             display: "flex",
        //             alignItems: "center",
        //           }}
        //         >
        //           {value?.name?.substring(value?.name?.indexOf(":") + 1)}
        //         </div>
        //       }
        //     >
        //       <div
        //         style={{
        //           display: "flex",
        //           justifyContent: "center",
        //           alignItems: "center",
        //           marginLeft: "4px",
        //         }}
        //       >
        //         <div
        //           style={{
        //             width: "89px",
        //             height: "30px",
        //             borderRadius: "40px",
        //             backgroundColor,
        //             color: textColor,
        //             display: "flex",
        //             justifyContent: "center",
        //             alignItems: "center",
        //           }}
        //         >
        //           {value?.name?.substring(0, value?.name?.indexOf(":"))}
        //           <div className="ml-1 w-6">{icon}</div>
        //         </div>
        //       </div>
        //     </Tooltip>
        //   );
        // },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: { pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data: data,
      initialState: { pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  const [pageIndex, setpageIndex] = useState(1);
  useEffect(() => {
    const pageDetails = {
      limit: pageSize,
      offset: pageSize * (pageIndex - 1) < 0 ? 0 : pageSize * (pageIndex - 1),
    };
    pagination(pageDetails);
  }, [pageIndex, pageSize, previousPage, nextPage]);

  return (
    <div className="container mx-auto float-left"  style={{ maxWidth: "100%" }}>
      {failedcancelled?.length == 0 && flag == false ? (
        <>
          <ShimmerLoader />{" "}
          {/* {setTimeout(() => {
              setflag(true);
            }, 3000)} */}
        </>
      ) : (
        <table
        {...getTableProps()}
        className="table-auto mx-2 ml-3 mt-7 font-normal  sm:w-[97.6%] md:w-[97.6%] lg:w-[97.6%] xl:w-[97.6%] min-h-auto shadow-xl rounded-lg overflow-hidden"
      >
          <thead>
            {headerGroups.map((headerGroup, headerIndex) => {
              const { key: headerKey, ...headerGroupProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <tr
                  key={headerKey}
                  {...headerGroupProps}
                  className="bg-[#78CFEC66] text-sm font-semibold"
                >
                  {headerGroup.headers.map((column, colIndex) => {
                    const { key: columnKey, ...columnProps } =
                      column.getHeaderProps(column.getSortByToggleProps());
                    return (
                      <th
                        key={columnKey}
                        {...columnProps}
                        className="py-3 px-2 whitespace-nowrap "
                      >
                        <div className="flex items-center justify-center">
                          {column.render("Header")}
                          <span className="">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <img
                                  src={Vectorsorting}
                                  style={{ minHeight: 10, minWidth: 10 }}
                                />
                              ) : (
                                <img
                                  className="rotate-180"
                                  src={Vectorsorting}
                                  style={{ minHeight: 10, minWidth: 10 }}
                                />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()} className="bg-[#F7F9FD] text-center">
            {page.map((row) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.id}>
                  <tr
                    {...row.getRowProps()}
                    className="relative hover:bg-white transition duration-300 border-b border-gray-300 fixed-row-height "
                  >
                    {row.cells.map((cell, index) => (
                      <td
                        {...cell.getCellProps()}
                        className={`p-2 text-[#272728]  h-1225  text-xs font-normal ${
                          index < row.cells.length - 1 ? "" : ""
                        }`}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                </React.Fragment>
              );
            })}
            {/* Empty state row */}
            {page.length === 0 && (
              <tr>
                <td colSpan={columns.length}>
                  <Empty description="No data available" />
                </td>
              </tr>
            )}
            <tr>
              {failedcancelled.length >= 0 && (
                <td
                  className=""
                  colSpan={columns.length}
                >
                  <CustomPagination
                    data={failedcancelled?.length}
                    pageIndex={pageIndex}
                    pageCount={page.length}
                    canPreviousPage={pageIndex != 1 ? true : false}
                    canNextPage={failedcancelled?.length >= 10 ? true : false}
                    gotoPage={(page) => {
                      nextPage(page);
                    }}
                    previousPage={() => {
                      setpageIndex(pageIndex - 1);
                    }}
                    nextPage={() => {
                      setpageIndex(pageIndex + 1);
                    }}
                    pageSize={pageSize}
                    setPageSize={(pageSize) => {
                      // nextPage(0);

                      setPageSize(pageSize);
                    }}
                  />
                </td>
              )}
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default FailedCancelledtable;
