import i18next from "i18next";
import { URLS } from "../API/API endpoints";
import axiosInstance from "../AxiosInstance";

export const getOrderNotifications = async (user_id, store_id) => {
  try {
    const payload = {
      store_id: store_id,
    };
    const response = await axiosInstance.post(URLS.ORDER_NOTIFICATION, payload);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const getKPIs = async (user_id) => {
  try {
    const payload = {
      user_id: user_id,
    };

    const response = await axiosInstance.post(
      URLS.GET_EORDER_DASHBOARD,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};


export const getEorderDashboard = async (user_id, startDate, endDate, is_lahloob) => {
  try {
    const payload = {
      user_id: user_id,
      start_date: startDate,
      end_date: endDate
    };

    const url = is_lahloob ? URLS.GET_LAHLOOB_EORDER_DASHBOARD : URLS.GET_EORDER_DASHBOARD;


    const response = await axiosInstance.post(
     url,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getEorderDashboardLahloob = async (company_id, startDate, endDate) => {
  try {
    const payload = {
      store_id: company_id,
      start_date: startDate,
      end_date: endDate
    };

    const response = await axiosInstance.post(
      URLS.GET_EORDER_DASHBOARD_LAHLOOB,
      payload
    );

    // console.log("DDDDDDDD",response);
    
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getOrderdata = async (user_id, store_id, id, limit, offset) => {
  const input = {
    user_id: user_id,
    store_id: store_id,
    limit: limit,
    offset: offset,
    stage_id: id ? id : 0,
  };

  try {
    const response = await axiosInstance.post(URLS.GET_ALL_ORDERS, input);
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

export const orderSearchFilter = async (
  user_id,
  order_no,
  status,
  limit = 10,
  offset = 0
) => {

  const statusMapping = {
    0: "",
    1: "Order Pending",
    2: "In Processing",
    3: "Ready For Delivery",
    4: "Out For Delivery",
    5: "Order Delivered",
    6: "Order Cancelled",
    // 6: "Completed", // Uncomment if needed
    7: "Order Cancelled",
  };

  // Convert the status to its corresponding label if it exists in the mapping
  const statusLabel = statusMapping[status] || status; // Fallback to the original status if not found
  const input = {
    lang: i18next.language === "ar" ? "ar_001" : "en_US",
    user_id: user_id,
    order_no: order_no,
    status:  statusLabel,
    limit: limit,
    offset: offset,
  };

  try {
    const response = await axiosInstance.post(URLS.ORDER_SEARCH_FILTER, input);
    // console.log("TABLE RESS", response?.data?.result[0]);
    
    return response?.data?.result[0];
  } catch (error) {
    console.log(error);
  }
};

