import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import {
//   closePosSession,
//   openSessionInfo,
// } from "../../Redux/Action/loginAction";
import { useTranslation } from "react-i18next";
import drop_arrow_black from "../assets/drop_arrow_black.svg";
import {
  closePosSession,
  getSessionData,
} from "../API  Functions/CloseSessionFunctions";
import { toast } from "react-toastify";
import axiosInstance from "../AxiosInstance";
import { URLS } from "../API/API endpoints";
import axios from "axios";

const LoadingSpinner = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-black bg-opacity-50">
      <div className="spinner">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
  container: {
    width: "500px",
    padding: "30px",
    borderRadius: "18px",
    margin: "0 auto",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: "25px",
    fontWeight: "bold",
    textAlign: "left",
    marginBottom: "10px",
  },
  cancelIcon: {
    cursor: "pointer",
  },
  description: {
    textAlign: "left",
    fontSize: "16px",
    color: "#272728",
    marginTop: "10px",
  },
  input: {
    width: "100%",
    padding: "10px",
    borderRadius: "12px",
    border: "1px solid #CCCCCC",
    marginBottom: "20px",
    boxSizing: "border-box",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    width: "180px",
    height: "45px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    marginRight: "10px",
  },
  confirmButton: {
    backgroundColor: "primaryBlue",
    color: "#FFFFFF",
  },
  cancelButton: {
    backgroundColor: "#CCCCCC",
    color: "#000000",
  },
};

const CloseSessionModal = ({
  closeModal,
  handleClose,
  sessionId,
  accessToken,
}) => {
  const { t, i18n } = useTranslation();
  const [sessionDetail, setSessionDetail] = useState();
  const [diffValue, setDiffValue] = useState();
  const [change, setChange] = useState();
  const [countedCash, setcountedCash] = useState(0);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.login?.user?.result);
  const [showCashInModal, setShowCashInModal] = useState(false); // State to control cash in modal visibility
  const [showSettleOptions, setShowSettleOptions] = useState(false);
  const [reason, setReason] = useState("");
  const [processing, setProcessing] = useState(false); // Add this state

  const [showSettle, setShowSettle] = useState(false);

  const [cashOut, setCashOut] = useState(change);
  const countedCashRef = useRef(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    const payload = {
      session_id: sessionId,
    };
    const response = await getSessionData(payload);
    // console.log("CLOSE SESSION DETAILS --->", response?.result?.status);

    if (response?.result?.status === 200) {
      // console.log("response", response?.result?.response[0]);
      setSessionDetail(response?.result?.response[0]);
      setChange(response?.result?.response[0]?.cash_difference);
      setDiffValue(response?.result?.response[0]?.cash_difference);
      setLoading(false);
    }
  };

  const handleCloseSession = async () => {
    setLoading(true);
    setIsButtonDisabled(true);

    try {
      if (showSettleOptions) {
        const payload1 ={
          session_id: sessionId,
          type: "cash_out",
          amount: cashOut,
          reason: reason,
        }
        const cashOutResponse = await axios.post(URLS.CASH_IN_OUT, payload1,{
          headers: {
            "access-token": accessToken, 
            "Content-Type": "application/json",
          }}
        );

        if (cashOutResponse?.data?.result?.status_code === 200) {
          const payload = {
            session_id: sessionId,
            diff_amount: 0,
            // counted_amount: countedCash,
            counted_amount:
              change === 0 ? sessionDetail?.cash_difference : countedCash,
            is_accept: checked,
          };
          const closeSessionResponse = await closePosSession(accessToken,payload);

          if (closeSessionResponse?.result?.status === 200) {
            // handleClose();
            toast.success("Session Closed Successfully");
            closetheModal();
                }
        } else {
          console.error("Cash out operation failed.");
        }
      } else {
        const payload = {
          session_id: sessionId,
          diff_amount: 0,
          counted_amount: countedCash,
          is_accept: checked,
        };
        const closeSessionResponse = await closePosSession(accessToken,payload);

        if (closeSessionResponse?.result?.status === 200) {
          // handleClose();
          // console.log("closeSessionResponse ---->",closeSessionResponse);
          toast.success("Session Closed Successfully");
          closetheModal();
        }
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
      setIsButtonDisabled(false);
    }
  };

  // const handleCloseSession = () =>{
  //   toast.success("CLOSING SESSION")
  // }

  const handleDiffChange = (value) => {
    let change = parseFloat((diffValue + Number(value))?.toFixed(2));
    setcountedCash(value);
    setChange(change);
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      if (!countedCash || (change !== 0 && !showSettleOptions)) {
        e.preventDefault();
      } else if (!processing) {
        setProcessing(true);
        // handleCloseSession().finally(() => setProcessing(false));
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      // Normalize the key input for consistent handling
      const normalizedKey =
        e.key === "Escape"
          ? "Escape"
          : e.key === "ArrowUp"
          ? "ArrowUp"
          : e.shiftKey
          ? "Shift"
          : e.key;

      if (normalizedKey === "Escape") {
        e.preventDefault();
        closeModal();
        localStorage.setItem("eKeyPressed", false);
        // localStorage.setItem("closeSession", false)
        // dispatch(setCloseSession(false))
      } else if (normalizedKey === "Shift") {
        e.preventDefault();
        setShowSettleOptions((prev) => !prev); // Toggle the state
      } else if (normalizedKey === "ArrowUp") {
        e.preventDefault();
        setShowSettleOptions((prev) => prev); // Ensure state consistency
        if (countedCashRef.current) {
          countedCashRef.current.focus();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (showSettleOptions) {
      setCashOut(change);
    }
  }, [showSettleOptions, change]);

  const closetheModal = () => {
    closeModal();
  };

  // console.log("SSSSSSSSSSS", sessionDetail?.cash_difference);

  return (
    <div style={styles.overlay} className="backdrop-blur">
      <div style={styles.container}>
        <div className="flex gap-5 px-12 pb-1 w-full text-xl font-semibold text-left bg-white text-zinc-800 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex-auto">{t("Closing Summary")}</div>
        </div>
        <div className="flex flex-col px-12 mt-4 w-full max-md:px-5 max-md:max-w-full">
          <div className="flex gap-5 justify-between mt-2.5 text-sm capitalize max-md:flex-wrap max-md:max-w-full">
            <div className="text-zinc-800">{t("Total Sales")}</div>
            <div className="font-semibold text-black">
              EGP {sessionDetail?.total_sales}
            </div>
          </div>

          <div className="flex gap-5 justify-between mt-2.5 text-sm capitalize max-md:flex-wrap max-md:max-w-full">
            <div className="text-zinc-800">{t("Opening Cash")}</div>
            <div className="font-semibold text-black">
              EGP {sessionDetail?.opening_cash}
            </div>
          </div>

          <div className="flex gap-5 justify-between mt-2.5 text-sm capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full">
            <div>{t("No. Of Orders")}</div>
            <div className="font-semibold">{sessionDetail?.total_orders}</div>
          </div>

          <div className="flex gap-5 justify-between mt-2.5 text-sm capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full">
            <div>{t("No. Of Refunded Orders")}</div>
            <div className="font-semibold">{sessionDetail?.refunded_order}</div>
          </div>

          <div className="flex gap-5 justify-between mt-2.5 text-sm capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full">
            <div>{t("Refunded Amount")}</div>
            <div className="font-semibold">
              EGP {sessionDetail?.refunded_amt}
            </div>
          </div>

          <div className="flex gap-5 justify-between mt-2.5 text-sm capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full">
            <div>{t("Cash Collection")}</div>
            <div className="font-semibold">
              EGP {sessionDetail?.collected_cash}
            </div>
          </div>

          <div className="flex gap-5 justify-between mt-2.5 text-sm capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full">
            <div>{t("Bank Collection")}</div>
            <div className="font-semibold">
              EGP {sessionDetail?.bank_collection}
            </div>
          </div>

          <div className="flex gap-5 justify-between mt-2.5 text-sm capitalize max-md:flex-wrap max-md:max-w-full">
            <div className="text-zinc-800">{t("Cash In")}</div>
            <div className="font-semibold text-black">
              EGP {sessionDetail?.cash_in_amt}
            </div>
          </div>

          <div className="flex gap-5 justify-between mt-2.5 text-sm capitalize max-md:flex-wrap max-md:max-w-full">
            <div className="text-zinc-800">{t("Cash Out")}</div>
            <div className="font-semibold text-black">
              EGP{" "}
              {i18n.language === "ar" && sessionDetail?.cash_out_amt < 0
                ? `${-sessionDetail?.cash_out_amt}-`
                : sessionDetail?.cash_out_amt}
            </div>
          </div>

          <div className="flex gap-5 justify-between mt-2.5 text-sm capitalize max-md:flex-wrap max-md:max-w-full">
            <div className="text-zinc-800">{t("Total Cash Available")}</div>
            <div className="font-semibold text-black">
              EGP {sessionDetail?.total_cash_available}
            </div>
          </div>

          <div className="flex gap-5 justify-between mt-4 font-semibold text-sm capitalize text-zinc-800 max-md:flex-wrap max-md:max-w-full">
            <div>{t("Cash Difference")}</div>
            <div className="font-semibold">
              EGP {sessionDetail?.cash_difference?.toFixed(2)}
            </div>
          </div>
          <div className="flex gap-5 mt-2 max-md:flex-wrap max-md:max-w-full">
            <div className="flex flex-col flex-1 grow shrink-0 items-start py-2  bg-white rounded-lg border border-solid basis-0 border-neutral-400 border-opacity-50 text-zinc-800 ">
              <div className="text-sm ms-4">{t("Difference")}</div>
              <div
                className="text-2xl ms-4 mt-2 font-semibold"
                style={{ color: change === 0 ? "green" : "red" }}
              >
                {countedCash === "-"
                  ? -sessionDetail?.cash_difference
                  : isNaN(change)
                  ? 0 // <--- Add this check
                  : change === 0
                  ? 0
                  : change < 0
                  ? -change?.toFixed(2)
                  : typeof change === "number" && change % 1 !== 0
                  ? i18n.language === "ar"
                    ? `${Math.abs(change)?.toFixed(2)}-`
                    : -change?.toFixed(2)
                  : i18n.language === "ar"
                  ? `${Math.abs(change)?.toFixed(2)}-`
                  : -change}
              </div>
            </div>
            <div className="flex flex-col flex-1 grow shrink-0 items-start py-2  bg-white rounded-lg border border-solid basis-0 border-neutral-400 border-opacity-50 text-zinc-800 ">
              <div className="text-red-600">
                <span className="text-[#7EC242] ms-4 text-sm">
                  {t("Counted Cash")}
                </span>{" "}
              </div>
              <input
                type="text"
                ref={countedCashRef}
                autoFocus={true}
                className="bg-white rounded-lg ms-2 border-stone-300 h-[50px] focus:outline-none px-2 font-bold text-zinc-800 w-full text-2xl"
                value={countedCash}
                style={{ direction: "ltr" }}
                onChange={(e) => {
                  let newValue = e.target.value;

                  newValue = newValue.replace(/[،ز]/g, ".");
                  if (newValue.match(/^-?\d*\.?\d{0,2}$/)) {
                    handleDiffChange(newValue);
                  }
                }}
                onKeyPress={handleEnterKeyPress}
                maxLength={10}
              />
            </div>
          </div>

          {change < 0 && (
            <>
              <div
                className="flex flex-row text-sm font-semibold  mt-5 cursor-pointer"
                onClick={() => setShowSettleOptions(!showSettleOptions)}
              >
                {t("Settle amount by cash out")}
                <span className="cursor-pointer">
                  <img
                    className="mt-1 ms-2 w-4 h-4"
                    src={drop_arrow_black}
                    alt="arrow"
                  />
                </span>
              </div>

              {showSettleOptions && (
                <div className="flex gap-5 mt-4">
                  {/* Settle Amount Input */}
                  <div className="flex flex-col flex-1 grow shrink-0 items-start bg-white rounded-lg border border-solid basis-0 border-neutral-400 border-opacity-50 text-zinc-800">
                    <div className="text-xs mt-2 ms-4">{t("Cash Out")}</div>
                    <div
                      className="text-xl ms-4 font-semibold"
                      style={{ color: cashOut === 0 ? "green" : "red" }}
                    >
                      {(-cashOut)?.toFixed(2)}
                    </div>
                  </div>

                  {/* Reason Input */}
                  <div className="flex flex-col flex-1 grow shrink-0 items-start  bg-white rounded-lg border border-solid basis-0 border-neutral-400 border-opacity-50 text-zinc-800">
                    <div className="text-xs mt-1 ms-4">{t("Reason")}</div>
                    <input
                      type="text"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      autoFocus
                      className="bg-white rounded-lg border-stone-300 mt-2 h-[50px] focus:outline-none px-2 font-normal text-zinc-800 w-full text-sm"
                      onKeyPress={handleEnterKeyPress}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="flex justify-center mt-4 gap-4">
          <button
            className="w-52 h-10 border-primaryBlue border text-primaryBlue  font-semibold rounded-lg "
            onClick={closetheModal}
          >
            {t("Cancel")}
          </button>
          <button
            disabled={isButtonDisabled || (change !== 0 && !showSettleOptions)}
            style={{
              opacity: change === 0 || showSettleOptions ? 1 : 0.5,
            }}
            onClick={handleCloseSession}
            className="w-52 h-10 bg-primaryBlue text-white font-semibold rounded-lg"
          >
            {loading && <LoadingSpinner />}
            {t("Close Session")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CloseSessionModal;
