import React, { useEffect, useState, lazy, Suspense } from "react";
import { useFormik } from "formik";
import { InputBox } from "../CustomWidgets/InputBox";
import SelectBox from "../CustomWidgets/SelectBox";
import { shopLocationSchema } from "../validationSchema";
import { useDispatch, useSelector } from "react-redux";
import { fetchDeliveryAreas } from "../Actions/deliveryAreasActions";
import "../../styles.css";
import UnservicableCity from "../../Popups/ShoplocationPopup";
import { useTranslation } from "react-i18next";
import { GetGeoFencing } from "./GetGeoFencing";
import { URLS } from "../../API/API endpoints";
import axios from "axios";

// import MyMap from "../locationPermission/googleMap";

const MapContainer = lazy(() => import("../locationPermission/googleMap"));

function ShopLocation({ onDataSubmit, data, mobile, savedata }) {
  const { t, i18n } = useTranslation();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [address, setAddress] = useState({});
  const [locationPermission, setLocationPermission] = useState(false);
  const [addressDetails, setAddressDetails] = useState({});
  const [matched, setMatched] = useState(false);
  const [notserve, setnotserve] = useState(false);
  const [popup, setpopup] = useState(false);
  const [deliveryAreas, setdeliveryAreas] = useState([]);
  const [Locality, setlocality] = useState("");
  const [Area, setarea] = useState("");
  const [City, setcity] = useState("");

  const [selectedDelievryArea, setSelectedDeliveryArea] = useState({});
  const [selectedPolygon, setSelectedPolygon] = useState({});
  const [deliverableLocation, setDrliverableLocation] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  // const { data: deliveryAreas, levelDetails } = useSelector(
  //   (state) => state.deliveryAreas
  // );

  const formik = useFormik({
    initialValues: {
      email: savedata?.email ?? "",
      mobile:
        mobile ??
        (typeof window !== "undefined"
          ? localStorage?.getItem("UserPhone")
          : ""),
      password: savedata?.password ?? "",
      ownerName: savedata?.ownerName ?? "",
      companyName: savedata?.companyName ?? "",
      country_code: "+20",
      user_type: "Retailer",
      page: "Address",
      address1: savedata?.address1 ?? "",
      address2: savedata?.address2 ?? "",
      street: savedata?.street ?? "",
      city: selectedPolygon?.level ?? "",
      state: savedata?.state ?? "",
      zip_code: savedata?.zip_code ?? "",
      country: address?.country ?? "",
      delivery_area: selectedPolygon?.delivery_area ?? "",
      zone_id: selectedPolygon?.delivery_area_id ?? "",
      area: selectedPolygon?.service_area ?? "",
      lat: savedata?.lat ?? "",
      long: savedata?.long ?? "",
      isagree: true,
    },
    validationSchema: shopLocationSchema,
    onSubmit: (values) => {
      const payload = {
        business_type: "retailer",
        email: values?.email?.toLowerCase(),
        mobile: values?.mobile,
        term_condition: values?.isagree,
        password: values?.password,
        owner_name: values?.ownerName,
        business_name: values?.companyName,
        state: values?.state,
        city: selectedPolygon?.level,
        street: values?.street,
        street2: `${values?.address1}${
          values?.address2 ? ", " + values?.address2 : ""
        }`,
        delivery_area: selectedPolygon?.delivery_area,
        zone_id: selectedPolygon?.delivery_area_id,
        area: selectedPolygon?.service_area,
        country: address?.country,
        zip: Number(values?.zip_code),
        latitude: values?.lat,
        longitude: values?.long,
      };

      onDataSubmit("shopLocationData", payload);
    },
  });

  console.log("selectedPolygon----------->", selectedPolygon);

  useEffect(() => {
    if (!locationPermission) {
      console.log("location permission called");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newPos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setSelectedLocation(newPos);
          handleLocationChange(newPos);
        },
        (error) => {
          console.error("Error retrieving location", error);
        }
      );
    }
  }, [locationPermission]);

  const handleLocationChange = (location) => {
    if (location) {
      setSelectedLocation(location);
      fetchAddressDetails(location.lat, location.lng);

      // setTimeout(async () => {
      //   const deliveryarea = await dispatch(
      //     fetchDeliveryAreas({
      //       latitude: location.lat,
      //       longitude: location.lng,
      //       city: City,
      //       area: Area,
      //     })
      //   );

      //   console.log("deliveryarea", deliveryarea?.payload?.deliveryAreas);
      //   setdeliveryAreas(deliveryarea?.payload?.fencing_data);
      // }, 10);
    }
  };

  const fetchAddressDetails = (latitude, longitude) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_G00GLE_API_KEY}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "OK") {
          let userLocation = {
            premise: "",
            route: "",
            plus_code: "",
            country: "",
            city: "",
            state: "",
            locality: "",
            postal_code: "",
            street: "",
            street2: "",
            latitude: latitude,
            longitude: longitude,
          };
          formik.setFieldValue("long", longitude);
          formik.setFieldValue("lat", latitude);

          data?.results.forEach((address) => {
            const { address_components } = address;
            address_components?.forEach((component) => {
              const { types, long_name } = component;
              if (types.includes("route")) {
                formik.setFieldValue("street", long_name);
              }
              if (
                types.includes("sublocality") ||
                types.includes("sublocality_level_1")
              ) {
                setlocality(long_name);
              }
              if (types.includes("administrative_area_level_2")) {
                setcity(long_name);
              }
              if (types.includes("administrative_area_level_1")) {
                formik.setFieldValue("state", long_name);
              }
              if (types.includes("country")) {
                // userLocation.country = long_name;
              }
              if (types.includes("postal_code")) {
                formik.setFieldValue("zip_code", long_name);
              }
              if (types.includes("locality")) {
                setarea(long_name);
              }

              if (types.includes("street_number")) {
                userLocation.street = long_name;
              }
              if (types.includes("premise")) {
                userLocation.premise = long_name;
              }
              if (types.includes("route")) {
                userLocation.route = long_name;
              }
              if (types.includes("neighborhood")) {
                userLocation.plus_code = long_name;
              }
              if (
                types.includes("sublocality") ||
                types.includes("sublocality_level_1")
              ) {
                userLocation.street2 = long_name;
                //  console.log("sublocality->", long_name);
              }
              if (types.includes("administrative_area_level_2")) {
                userLocation.city = userLocation.city || long_name;
              }
              if (types.includes("administrative_area_level_1")) {
                userLocation.state = long_name;
              }
              if (types.includes("country")) {
                userLocation.country = long_name;
              }
              if (types.includes("postal_code")) {
                userLocation.postal_code = long_name;
              }
              if (types.includes("locality")) {
                userLocation.locality = long_name;
              }
            });
          });
          setAddress(userLocation);
          GetPolygons(userLocation?.city, latitude, longitude);
        } else {
          console.error("Error fetching address details:", data.status);
        }
      })
      .catch((error) => {
        console.error("Error fetching address details:", error);
      });
  };

  const GetPolygons = async (level, lat, lng) => {
    try {
      setLoading(true);
      const headers = {
        lang: i18n.language === "ar" ? "ar_001" : "en_US",
      };
      const response = await axios.post(URLS.SHOP_LOCATION_API_URL, {
        level: level,
        service_area: "",
        delivery_area: "",
      });
      let location = {
        latitude: lat,
        longitude: lng,
      };
      const data = response?.data;
      console.log("GetPolygons==>", response?.data);
      if (data?.result?.status === 200) {
        // setPolygons(data?.result?.fencing_data);
        const geoValues = await GetGeoFencing(
          location,
          data?.result?.fencing_data
        );
        console.log("geoValues==>", geoValues);

        if (geoValues?.isInPolygon) {
          setDrliverableLocation(true);
          setSelectedPolygon(geoValues?.deliveryArea);
          setLoading(false);

          console.log("LYINNGGNGGGG INN POLYGONNNNN--->", geoValues);
        } else {
          setDrliverableLocation(false);
          setLoading(false);

          // getCityAreaAndLocality(address?.city, '', '');
          console.log(
            "NOOOOOTTTTTTT LYINNGGNGGGG POLYGONNNNN--->",
            geoValues?.deliveryArea
          );
        }
      } else {
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   const matchedlocality = deliveryAreas?.find(area => {
  //     return (
  //       area.delivery_area.toLowerCase() === address?.street2.toLowerCase()
  //     );
  //   });
  //   const matchedlevel = deliveryAreas?.find(city => {
  //     return city.level.toLowerCase() === address?.city.toLowerCase();
  //   });
  //   console.log('matchedlocality', matchedlocality);
  //   console.log('matchedlevel', matchedlevel);

  //   if (!matchedlocality) {

  //     if (!matchedlevel) {
  //       setDrliverableLocation(false);
  //     } else {
  //       setSelectedDeliveryArea(matchedlevel);

  //       setDrliverableLocation(true);
  //     }
  //   } else {
  //     getCityAreaAndLocality(
  //       matchedlocality.level,
  //       matchedlocality?.service_area,
  //       matchedlocality?.delivery_area,
  //     );
  //     setSelectedDeliveryArea(matchedlocality);
  //     setDrliverableLocation(true);
  //     // dispatch(deliverablelocation(true));
  //   }
  // }, [deliveryAreas]);
  // console.log('deliverable', deliverableLocation);

  // const getCityAreaAndLocality = (city, area, locality) => {
  //   if (locality) {
  //     const matchedlocality = deliveryAreas?.find(area => {
  //       return area.delivery_area.toLowerCase() === locality.toLowerCase();
  //     });
  //     setSelectedDeliveryArea(matchedlocality);
  //   } else {
  //     let payload = {
  //       service_area: area,
  //       level_details: city,
  //     };
  //     getDeliveryArea(payload);
  //   }
  // };

  // const getDeliveryArea = async payload => {
  //   try {
  //     const headers = {
  //       lang: i18n.language === 'ar' ? 'ar_001' : 'en_US',
  //     };
  //     const res = await axios.post(
  //       URLS.GET_AREAS,
  //       payload,{
  //       headers},
  //     );
  //     const data = res?.data
  //     if (data?.result?.status === 200) {
  //       setdeliveryAreas(data?.result?.response);
  //       console.log("deliveryAreas",data?.result?.response);
  //     } else {
  //       setdeliveryAreas([]);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     setdeliveryAreas([]);
  //   }
  // };

  return (
    <div className="custom-scrollbar">
      <div>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex mt-7 gap-6 ">
            {deliverableLocation ? (
              <>
                <div className="w-1/2">
                  <h1 className="text-2xl text-black font-bold">
                    {t("set_location")}
                  </h1>
                  <div className="flex gap-8">
                    <span className="w-full">
                      <InputBox
                        label={t("address1")}
                        name="address1"
                        maxLength={30}
                        placeholder={t("enter_shopno")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.address1}
                        error={formik.errors.address1}
                        touched={formik.touched.address1}
                      />
                    </span>
                  </div>
                  <InputBox
                    label={t("address2")}
                    name="address2"
                    maxLength={30}
                    placeholder={t("landmark")}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address2}
                    error={formik.errors.address2}
                    touched={formik.touched.address2}
                  />
                  <div className="flex flex-row justify-between gap-x-8">
                    <span className="w-full">
                      <InputBox
                        label={t("street")}
                        name="street"
                        maxLength={30}
                        placeholder={t("enter_street")}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.street}
                        error={formik.errors.street}
                        touched={formik.touched.street}
                      />
                    </span>
                    {/* <SelectBox
                  defaultValue="Select"
                  label={t("locality")}
                  name="locality"
                  onChange={(e) => {
                    setlocality(e.target.value);
                    formik.handleChange(e);
                  }}
                  options={[
                    ...(deliveryAreas?.deliveryAreas?.fencing_data || [])
                      .map((area) => ({
                        value: area.delivery_area,
                        label: area.delivery_area,
                      })),
                  ]}
                  onBlur={formik.handleBlur}
                  value={formik.values.locality}
                  error={formik.errors.locality}
                  touched={formik.touched.locality}
                />
              </div>
              <div className="flex gap-8">
                <SelectBox
                  defaultValue="Select"
                  label={t("area")}
                  name="area"
                  onChange={(e) => {
                    setarea(e.target.value);
                    formik.handleChange(e);
                  }}
                  options={[
                    ...(deliveryAreas?.deliveryAreas?.response || [])
                      .map((area) => ({
                        value: area.service_area,
                        label: area.service_area,
                      })),
                  ]}
                  onBlur={formik.handleBlur}
                  value={formik.values.area}
                  error={formik.errors.area}
                  touched={formik.touched.area}
                />
                <SelectBox
                  defaultValue="Select"
                  label={t("city")}
                  name="city"
                  onChange={(e) => {
                    setcity(e.target.value);
                    formik.handleChange(e);
                  }}
                  options={[
                    ...(deliveryAreas?.deliveryAreas?.response || [])
                      .map((area) => ({
                        value: area.level,
                        label: area.level,
                      })),
                  ]}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                  error={formik.errors.city}
                  touched={formik.touched.city}
                /> */}
                  </div>
                </div>
              </>
            ) : (
              <>
                <p className=" font-semibold text-lg mt-10 me-20">
                  We are not serving in this area.
                </p>
              </>
            )}

            <div className="w-1/2 h-[24rem] rounded-lg overflow-hidden">
              <Suspense fallback={<div>{t("loadingmap")}</div>}>
                <MapContainer
                  onLocationChange={handleLocationChange}
                  lat={selectedLocation?.lat || 0}
                  lng={selectedLocation?.lng || 0}
                />
              </Suspense>
            </div>
          </div>
          <div className="flex float-right mt-8  mb-2">
            <button
              type="submit"
              className={`w-[180px] h-[45px] ${
                !notserve ? "bg-primaryBlue" : "bg-primaryBluedisabled"
              }  text-white rounded-lg font-semibold`}
              disabled={notserve}
            >
              {t("next")}
            </button>
          </div>
        </form>
        {popup && (
          <UnservicableCity
            onClose={() => {
              setpopup(false);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default ShopLocation;

// import React, { useEffect, useState, lazy, Suspense } from "react";
// import { useFormik } from "formik";
// import { InputBox } from "../CustomWidgets/InputBox";
// import SelectBox from "../CustomWidgets/SelectBox";
// import { shopLocationSchema } from "../validationSchema";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchDeliveryAreas } from "../Actions/deliveryAreasActions";
// import "../../styles.css";
// import UnservicableCity from "../../Popups/ShoplocationPopup";
// import { useTranslation } from "react-i18next";

// // import MyMap from "../locationPermission/googleMap";

// const MapContainer = lazy(() => import("../locationPermission/googleMap"));

// function ShopLocation({ onDataSubmit, data, mobile, savedata }) {
//   const { t, i18n } = useTranslation();
//   const [selectedLocation, setSelectedLocation] = useState(null);
//   const [locationPermission, setLocationPermission] = useState(false); // State to track location permission
//   const [addressDetails, setAddressDetails] = useState({}); // State to manage address details
//   const [matched, setMatched] = useState(false); // State to track if the data is matched
//   const [notserve, setnotserve] = useState(false);
//   const [popup, setpopup] = useState(false);
//   const [deliveryAreas, setdeliveryAreas] = useState([]);
//   const [Locality, setlocality] = useState("");
//   const [Area, setarea] = useState("");
//   const [City, setcity] = useState("");

//   const dispatch = useDispatch();
//   // const { data: deliveryAreas, levelDetails } = useSelector(
//   //   (state) => state.deliveryAreas
//   // );

//   const formik = useFormik({
//     initialValues: {
//       mobile:
//         typeof window !== "undefined"
//           ? localStorage?.getItem("UserPhone")
//           : mobile,
//       country_code: "+20",
//       user_type: "Retailer",
//       page: "Address",
//       address1: savedata?.address1 ?? "",
//       address2: savedata?.address2 ?? "",
//       street: savedata?.street ?? "",
//       area: savedata?.area ?? "",
//       city: savedata?.city ?? "",
//       state: savedata?.state ?? "",
//       zip_code: savedata?.zip_code ?? "",
//       country_id: savedata?.country_id ?? 1,
//       lat: savedata?.lat ?? "",
//       long: savedata?.long ?? "",
//       isagree: true,
//     },
//     validationSchema: shopLocationSchema,
//     onSubmit: (values) => {
//       onDataSubmit("shopLocationData", values);
//     },
//   });

//   useEffect(() => {
//     if (!locationPermission) {
//       // Fetch delivery areas only if location permission is granted
//       console.log("location permisiion called");
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const newPos = {
//             lat: position.coords.latitude,
//             lng: position.coords.longitude,
//           };
//           setSelectedLocation(newPos);
//           handleLocationChange(newPos); // Fetch delivery areas based on the current location
//         },
//         (error) => {
//           console.error("Error retrieving location", error);
//         }
//       );
//     }
//   }, [locationPermission]); // Fetch delivery areas only when location permission changes

//   const handleLocationChange = (location) => {
//     if (location) {
//       setSelectedLocation(location);
//       // Fetch address details using Google Maps API
//       fetchAddressDetails(location.lat, location.lng);

//       // Fetch delivery areas based on the current location
//       setTimeout(async () => {
//         // console.log("city",area,city)
//         const deliveryarea = await dispatch(
//           fetchDeliveryAreas({
//             latitude: location.lat,
//             longitude: location.lng,
//             city: City,
//             area: Area,
//           })
//         );

//         console.log("deliveryarea", deliveryarea?.payload?.deliveryAreas);
//         setdeliveryAreas(deliveryarea?.payload?.fencing_data);
//       }, 10);

//       // Fetch street information using Google Maps API
//       // fetchStreetDetails(location.lat, location.lng);
//     }
//   };

//   const fetchAddressDetails = (latitude, longitude) => {
//     const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_G00GLE_API_KEY}`;

//     fetch(url)
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.status === "OK") {
//           formik.setFieldValue("long", longitude);
//           formik.setFieldValue("lat", latitude);
//           // Extract address components from the response

//           data?.results.forEach((address) => {
//             const { address_components } = address;
//             address_components?.forEach((component) => {
//               const { types, long_name } = component;
//               if (types.includes("route")) {
//                 formik.setFieldValue("street", long_name);
//               }
//               if (
//                 types.includes("sublocality") ||
//                 types.includes("sublocality_level_1")
//               ) {
//                 //  console.log("LOcality-->>", long_name);
//                 // formik.setFieldValue("locality", long_name);
//                 setlocality(long_name);
//               }
//               if (types.includes("administrative_area_level_2")) {
//                 // console.log("city found-->>", long_name);
//                 // formik.setFieldValue("city", long_name);
//                 setcity(long_name);
//               }
//               if (types.includes("administrative_area_level_1")) {
//                 // console.log("state-->>", long_name);
//                 formik.setFieldValue("state", long_name);
//               }
//               if (types.includes("country")) {
//                 //userLocation.country = long_name;
//               }
//               if (types.includes("postal_code")) {
//                 formik.setFieldValue("zip_code", long_name);
//               }
//               if (types.includes("locality")) {
//                 // formik.setFieldValue("area", long_name);
//                 setarea(long_name);
//               }
//             });
//           });
//         } else {
//           console.error("Error fetching address details:", data.status);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching address details:", error);
//       });
//   };

//   useEffect(() => {
//     setTimeout(() => {
//       if (deliveryAreas && deliveryAreas?.deliveryAreas?.response) {
//         const matchedLocality = deliveryAreas?.deliveryAreas?.response.find(
//           (area) => {
//             console.log(Locality, "useeffect matching", area);
//             // console.log(area.delivery_area,"match",formik.values,typeof(formik.values),"hello")
//             return (
//               area.delivery_area.toLowerCase() === Locality?.toLowerCase()
//               // formik.values.locality?.toLowerCase()
//             );
//           }
//         );
//         const matchedArea = deliveryAreas?.deliveryAreas?.response.find(
//           (area) => {
//             // console.log(area.service_area, "matching area", Area);
//             return (
//               area.service_area.toLowerCase() === Area?.toLowerCase()
//               // formik.values?.area?.toLowerCase()
//             );
//           }
//         );
//         const matchedLevel = deliveryAreas?.deliveryAreas?.response.find(
//           (area) => {
//             return (
//               area.level.toLowerCase() === City?.toLowerCase()
//               // formik.values?.city?.toLowerCase()
//             );
//           }
//         );
//         if (!matchedLevel) {
//           setMatched(true);
//         } else {
//           setMatched(false);
//         }
//         if (matchedLocality) {
//           setnotserve(false);
//           setpopup(false);
//           console.log("matched locality >>>>>>>>>>>>>>>>>>", matchedLocality);

//           // console.log("delivery areas check",deliveryAreas,typeof(deliveryAreas))
//           formik.setFieldValue("locality", matchedLocality.delivery_area);
//           formik.setFieldValue("area", matchedLocality.service_area);
//           formik.setFieldValue("city", matchedLocality.level);
//         } else if (matchedArea) {
//           setnotserve(false);
//           setpopup(false);
//           console.log("matched Area >>>>>>>>>>>>>>>>>>", matchedArea);

//           // console.log("delivery areas check",deliveryAreas.deliveryAreas.response,typeof(deliveryAreas.deliveryAreas.response))
//           formik.setFieldValue("street", "");
//           setlocality(
//             (deliveryAreas?.deliveryAreas?.response || [])?.filter(
//               (type, index, self) =>
//                 self.findIndex((t) => t.level === Area) === index
//             )?.[0]?.delivery_area
//           );
//           formik.setFieldValue(
//             "locality",
//             (deliveryAreas?.deliveryAreas?.response || [])?.filter(
//               (type, index, self) =>
//                 self.findIndex((t) => t.level === Area) === index
//             )?.[0]?.delivery_area
//           );

//           formik.setFieldValue("area", matchedArea?.service_area);
//           formik.setFieldValue("city", matchedArea.level);
//         } else if (matchedLevel) {
//           console.log("lastone called");
//           formik.setFieldValue("street", "");
//           formik.setFieldValue(
//             "area",
//             (deliveryAreas?.deliveryAreas?.response || [])?.filter(
//               (type, index, self) =>
//                 self.findIndex((t) => t.level === City) === index
//             )?.[0]?.service_area
//           );
//           setarea(
//             (deliveryAreas?.deliveryAreas?.response || [])?.filter(
//               (type, index, self) =>
//                 self.findIndex((t) => t.level === City) === index
//             )?.[0]?.service_area
//           );
//         } else {
//           formik.setFieldValue("street", "");
//           formik.setFieldValue("locality", "");
//           formik.setFieldValue("area", "");
//           formik.setFieldValue(
//             "city",
//             (deliveryAreas?.deliveryAreas?.response || [])?.[0]?.level
//           );
//           setcity((deliveryAreas?.deliveryAreas?.response || [])?.[0]?.level);
//         }
//       } else {
//         formik.setFieldValue("locality", "");
//         setMatched(false);
//         // setnotserve(true);
//         // setpopup(true);
//       }
//     }, 1000);
//   }, [deliveryAreas, Locality, Area, City]);

//   const handleStreetChange = (street) => {
//     formik.setFieldValue("street", street);
//   };

//   console.log("LLLLLLL------------->",deliveryAreas);

//   return (
//     <div className="custom-scrollbar">
//       <div>
//         <form onSubmit={formik.handleSubmit}>
//           <div className="flex mt-7 gap-6 ">
//             <div className="w-1/2">
//               <h1 className="text-2xl text-black font-bold">
//                 {t("set_location")}
//               </h1>
//               <div className="flex gap-8">
//                 <span className="w-full">
//                   <InputBox
//                     label={t("address1")}
//                     name="address1"
//                     maxLength={30}
//                     placeholder={t("enter_shopno")}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                     value={formik.values.address1}
//                     error={formik.errors.address1}
//                     touched={formik.touched.address1}
//                   />
//                 </span>
//               </div>
//               <InputBox
//                 label={t("address2")}
//                 name="address2"
//                 maxLength={30}
//                 placeholder={t("landmark")}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 value={formik.values.address2}
//                 error={formik.errors.address2}
//                 touched={formik.touched.address2}
//               />
//               <div className="flex flex-row justify-between gap-x-8">
//                 <span className="w-full">
//                   <InputBox
//                     label={t("street")}
//                     name="street"
//                     maxLength={30}
//                     placeholder={t("enter_street")}
//                     onChange={(e) => {
//                       formik.handleChange(e);
//                       // handleLocationChange(selectedLocation); // Call handleLocationChange on street change
//                     }}
//                     onBlur={formik.handleBlur}
//                     value={formik.values.street}
//                     error={formik.errors.street}
//                     touched={formik.touched.street}
//                   />
//                 </span>
//                 {/* // { value: "", label: "Select" }, */}
//                 <SelectBox
//                   defaultValue="Select"
//                   label={t("locality")}
//                   name="locality"
//                   onChange={(e) => {
//                     setlocality(e.target.value);
//                     formik.handleChange(e.target.value);
//                   }}
//                   options={[
//                     ...(deliveryAreas?.deliveryAreas?.fencing_data || [])
//                       ?.filter((type, index, self) => {
//                         const isFirstLevel =
//                           self.findIndex((t) => t.service_area === Area) ===
//                           index;
//                         const isFirstServiceArea =
//                           self.findIndex(
//                             (t) => t.delivery_area === type.delivery_area
//                           ) === index;
//                         return isFirstLevel || isFirstServiceArea;
//                       })
//                       ?.map((area) => ({
//                         value: area.deliver_area_name,
//                         label: area.deliver_area_name,
//                       })),
//                   ]}
//                   onBlur={formik.handleBlur}
//                   value={formik.values.locality}
//                   error={formik.errors.locality}
//                   touched={formik.touched.locality}
//                   className="absolute bottom-0 w-full text-black sm:w-auto"
//                 />
//               </div>
//               <div className="flex gap-8">
//                 <SelectBox
//                   defaultValue="Select"
//                   label={t("area")}
//                   name="area"
//                   onChange={
//                     (e) => {
//                       setlocality("");
//                       setarea(e.target.value);
//                       formik.handleChange(e.target.value);
//                     }
//                   }
//                   options={[
//                     ...(deliveryAreas?.deliveryAreas?.response || [])
//                       ?.filter((type, index, self) => {
//                         const isFirstLevel =
//                           self.findIndex((t) => t.level === City) === index;
//                         const isFirstServiceArea =
//                           self.findIndex(
//                             (t) => t.service_area === type.service_area
//                           ) === index;
//                         return isFirstLevel || isFirstServiceArea;
//                       })
//                       .map((area) => ({
//                         value: area.service_area,
//                         label: area.service_area,
//                       })),
//                   ]}
//                   onBlur={formik.handleBlur}
//                   value={formik.values.area}
//                   error={formik.errors.area}
//                   touched={formik.touched.area}
//                 />
//                 {/* <div> */}
//                 <SelectBox
//                   defaultValue="Select"
//                   label={t("city")}
//                   name="city"
//                   onChange={(e) => {
//                     setarea("");
//                     setlocality("");
//                     setcity(e.target.value);
//                     formik.handleChange(e.target.value);
//                   }}
//                   options={[
//                     ...(deliveryAreas?.deliveryAreas?.response || [])
//                       ?.filter(
//                         (type, index, self) =>
//                           self.findIndex((t) => t.level === type.level) ===
//                           index
//                       )
//                       ?.map((area) => ({
//                         value: area.level,
//                         label: area.level,
//                       })),
//                   ]}
//                   onBlur={formik.handleBlur}
//                   value={formik.values.city}
//                   error={formik.errors.city}
//                   touched={formik.touched.city}
//                 />
//               </div>
//             </div>
//             <div className="w-1/2  h-[24rem]  rounded-lg overflow-hidden">
//               <Suspense fallback={<div>{t("loadingmap")}</div>}>
//                 <MapContainer
//                   onLocationChange={handleLocationChange}
//                   lat={selectedLocation?.lat || 0}
//                   lng={selectedLocation?.lng || 0}
//                 />
//               </Suspense>
//             </div>
//           </div>
//           <div className="flex float-right mt-8  mb-2">
//             <button
//               type="submit"
//               className={`w-[180px] h-[45px] ${
//                 !notserve ? "bg-primaryBlue" : "bg-primaryBluedisabled"
//               }  text-white rounded-lg font-semibold`}
//               disabled={notserve}
//             >
//               {t("next")}
//             </button>
//           </div>
//         </form>
//         {popup && (
//           <UnservicableCity
//             onClose={() => {
//               setpopup(false);
//             }}
//           />
//         )}
//       </div>
//     </div>
//   );
// }

// export default ShopLocation;
