import React from "react";
import {Image}  from "antd";
import one from "../../assets/Dashboard/TotalProfit.svg";
import two from "../../assets/Order/two.svg";
import three from "../../assets/Order/three.svg";
import fourth from "../../assets/Order/fourth.svg";
import { Button } from "antd";
import { Doughnut } from "react-chartjs-2";

const Dashboardfifth = () => {
  const data = {
    labels: ["Label 1", "Label 2", "Label 3"],
    datasets: [
      {
        data: [30, 50, 20],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };
  const inventoryData = [
    { id: 1, productName: 'Product 1', totalSold: 10, price: 100, quantity: 50 },
    { id: 2, productName: 'Product 2', totalSold: 20, price: 150, quantity: 30 },
    { id: 3, productName: 'Product 3', totalSold: 15, price: 120, quantity: 40 },
  ];

  return (
    <div className="flex flex-wrap   ml-3 mr-3">
      <div className="w-1/3 p-4">
      <div  className="rounded-xl min-h-[18rem] mb-4  bg-[#ffffff] ">
      <div className="flex  justify-center items-left ml--10 shadow-sm relative">  
        <Image src={one} layout="fixed" width={30} height={30} className="relative mt-5 left--10" />  
         <h1 className="ml-6  mt-5">Total Sales</h1> 
        </div>
        <div className="flex justify-center items-center">
          {/* Add Chart.js Doughnut chart */}
          {/* <Doughnut data={data} /> */}
        </div>
      </div>
      </div>

      <div className="w-2/3 p-4  ">
      <div  className="rounded-xl min-h-[18rem] mb-4  bg-[#ffffff] ">
       <div  class="flex justify-between items-center mt-1">
          <span class="text-left ml-5">
            <h1 class="text-xl">Blocked Inventory</h1>
          </span>
          <span class="text-right mr-5">
            <h1 class="text-xl">View all</h1>
          </span>
        </div>
        <table className="w-full table-auto">
        <thead>
          <tr >
            <th className="text-grey pl-4 pr-10 py-2">Product</th>
            <th className="text-grey pl-10 pr-5 py-2">Total Sold</th>
            <th className="text-grey px-4 py-2">Price</th>
            <th className="text-grey px-4 py-2">Quantity</th>
            <th className="text-grey px-4 py-2">Action</th>
          </tr>
        </thead>
        <tbody>
          {/* Render inventory items dynamically */}
          {inventoryData.map((item) => (
            <tr key={item.id} className="border-b border-gray-200">
              <td className="pl-4 pr-10 py-2">{item.productName}</td>
              <td className="pl-10 pr-5 py-2">{item.totalSold}</td>
              <td className="px-4 py-2">${item.price}</td>
              <td className="px-4 py-2">{item.quantity}</td>
              <td className="px-4 py-2">
                <button className="bg-red-500 text-red px-3 py-1 rounded-lg">BUY</button>
                <button className="bg-red-500 text-white px-3 py-1 rounded-lg ml-2">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
        </div>
      </div>
    </div>
  );
};

export default Dashboardfifth;
