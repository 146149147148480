// reducers/paymentReducer.js

import { SET_DASH_DATA, CLEAR_DASH_DATA } from "../Action/DashData";

const initialState = {
  selectedDashData: null,
};

const dashDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DASH_DATA:
      return {
        ...state,
        selectedDashData: action.payload,
      };

    case CLEAR_DASH_DATA:
        return {
            ...state,
            selectedDashData: null
        };
    default:
      return state;
  
  }
};

export default dashDataReducer;