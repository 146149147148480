import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const OrdercustomerDetails = ({
  OrderData,
  // customer,
  // deliverydate,
  // address_details,
  // order_stage,
  Deliveryboy = "",
}) => {
  const is_lahloob = useSelector(
    (state) => state.login?.user?.result?.is_lahloob
  );

  const { t, i18n } = useTranslation();
  let backgroundColor,
    textColor,
    borderRadius,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight;
  // Conditionally set styles based on order status
  if (OrderData?.order_details?.order_status === "Order Pending") {
    backgroundColor = "#999999";
  } else if (OrderData?.order_details?.order_status === "In Processing") {
    backgroundColor = "#f1c232  ";
  } else if (OrderData?.order_details?.order_status === "Ready For Delivery") {
    backgroundColor = "#93c47d";
  } else if (OrderData?.order_details?.order_status === "Out For Delivery") {
    backgroundColor = "#f1c232";
  } else if (OrderData?.order_details?.order_status === "Order Delivered") {
    backgroundColor = "#1b4497";
  } else {
    backgroundColor = "#E74C3C";
  }
  textColor = "#ffffff";
  paddingTop = "4px";
  paddingBottom = "4px";
  paddingLeft = "10px";
  paddingRight = "10px";
  borderRadius = "5px";

  return (
    <>
      <div className="me-3">
        <div className="w-full md:w-[350px]  mt-3 rounded-lg overflow-hidden ">
          <div className="bg-[#C9ECF7] font-semibold flex flex-row justify-between items-center text-base px-5 py-2">
            {t("order_info")}{" "}
            <h4
              style={{
                backgroundColor,
                color: textColor,
                textAlign: "center",
                borderRadius,
                padding: `${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft}`,
                fontSize: 14,
                fontWeight: 400,
                display: "inline-block",
                minWidth: "65px",
                alignItems: "center",
              }}
            >
              {(() => {
                switch (OrderData?.order_details?.order_status) {
                  case "Order Pending":
                    return t("Pending");
                  case "In Processing":
                    return t("Processing");
                  case "Ready For Delivery":
                    return t("Ready");
                  case "Out For Delivery":
                    return t("Out");
                  case "Order Delivered":
                    return t("Delivered");

                  default:
                    return t("Cancelled");
                }
              })()}
            </h4>
          </div>
          <div className="bg-white ">
            <div className="border-b border-gray-300 px-5 py-2">
              <div className="font-semibold text-sm">
                {t("customer_information")}
              </div>
              <div style={{ fontSize: "14px", color: "#303030" }}>
                {OrderData?.order_details?.partner_name}
              </div>
              <div style={{ fontSize: "14px", color: "#303030" }}>
                {" "}
                {OrderData?.order_details?.partner_mobile}
              </div>
            </div>
            <div className="border-b border-gray-300 px-5 py-2 font">
              <div className="font-semibold text-sm">
                {t("delivery_address")}
              </div>
              <span style={{ fontSize: "14px", color: "#303030" }}>
                {(OrderData?.deliver_add?.address_type &&
                  OrderData?.deliver_add?.address_type?.includes("Villa")) ||
                (OrderData?.deliver_add?.address_type &&
                  OrderData?.deliver_add?.address_type?.includes("Home"))
                  ? OrderData?.deliver_add?.house_group &&
                    `${t("Villa No.")} ${OrderData?.deliver_add?.house_group}, `
                  : OrderData?.deliver_add?.house_group &&
                    `${t("Group No.")} ${
                      OrderData?.deliver_add?.house_group
                    }, `}
                {OrderData?.deliver_add?.office_name &&
                  `${t("Office Name")} ${
                    OrderData?.deliver_add?.office_name
                  }, `}
                {OrderData?.deliver_add?.building_group &&
                  `${t("Building")} ${
                    OrderData?.deliver_add?.building_group
                  }, `}
                {(OrderData?.deliver_add?.address_type &&
                  OrderData?.deliver_add?.address_type?.includes("Villa")) ||
                (OrderData?.deliver_add?.address_type &&
                  OrderData?.deliver_add?.address_type?.includes("Home"))
                  ? OrderData?.deliver_add?.apartment_no &&
                    `${t("Group No.")} ${
                      OrderData?.deliver_add?.apartment_no
                    }, `
                  : OrderData?.deliver_add?.apartment_no &&
                    `${t("Apartment No.")} ${
                      OrderData?.deliver_add?.apartment_no
                    }, `}
                {OrderData?.deliver_add?.floor &&
                  `${t("Floor")} ${OrderData?.deliver_add?.floor}, `}
                {OrderData?.deliver_add?.street &&
                  `${OrderData?.deliver_add?.street}, `}
                {OrderData?.deliver_add?.street2 &&
                  `${OrderData?.deliver_add?.street2}, `}
                {OrderData?.deliver_add?.locality &&
                  `${OrderData?.deliver_add?.locality}, `}
                {OrderData?.deliver_add?.area &&
                  `${OrderData?.deliver_add?.area}, `}
                {OrderData?.deliver_add?.city},{" "}
                {OrderData?.deliver_add?.country} {OrderData?.deliver_add?.zip}
                {/* {OrderData?.deliver_add?.house_group &&
                OrderData?.deliver_add?.house_group + ', '}
              {OrderData?.deliver_add?.office_name &&
                OrderData?.deliver_add?.office_name + ', '}
              {OrderData?.deliver_add?.building_group &&
                OrderData?.deliver_add?.building_group + ', '}
              {OrderData?.deliver_add?.apartment_no &&
                OrderData?.deliver_add?.apartment_no + ', '}
              {OrderData?.deliver_add?.floor &&
                'Floor ' + OrderData?.deliver_add?.floor + ', '}
              {OrderData?.deliver_add?.street && OrderData?.deliver_add?.street + ', '}
              {OrderData?.deliver_add?.locality &&
                OrderData?.deliver_add?.locality + ', '}
              {OrderData?.deliver_add?.area && OrderData?.deliver_add?.area} */}
                {/* {OrderData?.deliver_add?.state != "" && OrderData?.deliver_add?.state + ", "}
    {OrderData?.deliver_add?.zip} */}
              </span>
              {/* <div>{address_details?.country}</div> */}
            </div>
            {/* {!is_lahloob && (
              <div className="border-b border-gray-300  px-5 py-2">
                <div className="font-semibold text-sm">
                  {t("delivery_schedule")}
                </div>
                <div style={{ fontSize: "14px", color: "#303030" }}>
                  {OrderData?.order_details?.delivery_schedule}
                </div>
              </div>
            )} */}
            <div className="border-b border-gray-300  px-5 py-2">
              <div className="font-semibold text-sm">{t("order_stage")}</div>
              <div style={{ fontSize: "14px", color: "#303030" }}>
                {t(OrderData?.order_details?.order_status)}
              </div>
            </div>
            {/* {Deliveryboy!=null && */}
            {OrderData?.order_details?.delivery_boy_id != false && (
              <div className="border-b border-gray-300  px-5 py-2">
                <div className="font-semibold text-sm">{t("delivery_boy")}</div>
                <div style={{ fontSize: "14px", color: "#303030" }}>
                  {OrderData?.order_details?.delivery_boy_id != false
                    ? OrderData?.order_details?.delivery_boy_name
                    : "Not Selected"}
                </div>
              </div>
            )}
            {OrderData?.order_details?.cancel_reason && (
              <div className="border-b border-gray-300 px-5 py-2">
                <div className="font-semibold text-sm">
                  {t("cancelled_reason")}
                </div>
                <div style={{ fontSize: "14px", color: "#303030" }}>
                  {OrderData?.order_details?.cancel_reason}
                </div>
              </div>
            )}
            {/* {OrderData?.order_details?.delivery_boy_id != false && (
              <div className="border-b border-gray-300  px-5 py-2">
                <div className="font-semibold text-sm">{t("Cancel Reason")}</div>
                <div style={{ fontSize: "14px", color: "#303030" }}>
                  {OrderData?.order_details?.delivery_boy_id != false
                    ? OrderData?.order_details?.cancel_reason
                    : "Not Selected"}
                </div>
              </div>
            )} */}
            {/* } */}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrdercustomerDetails;
